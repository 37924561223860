import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { BaseState, BaseStateStatus } from '../models/macro/BaseState';
import { Manual } from '../models/ManualModel';

export interface ManualState extends BaseState {
	status: BaseStateStatus;
	entities: Manual[];
}

const initialState: ManualState = {
	status: 'success',
	entities: [
		{
			id:'',
			name: "Electric Fence",
			model: "PetSafe PIG00-11115",
			dateInstalled: "",
			link: "https://ericville-assets.s3.us-east-2.amazonaws.com/1681775620760-manual-petsafe-collar.pdf",
			notes: ""
		},
		{
			id:'',
			name: "Basement Microwave",
			model: "",
			dateInstalled: "07/20/2008",
			link: "",
			notes: ""
		},
		{
			id:'',
			name: "Basement Food Warmer",
			model: "KitchenAid KEWS105, 145, or 175",
			dateInstalled: "07/20/2008",
			link: "https://www.manualslib.com/download/505638/Kitchenaid-Kews105.html",
			notes: ""
		},
		{
			id:'',
			name: "Built-In Oven",
			model: "GE Built-In Electric Convection Oven (JT950)",
			dateInstalled: "",
			link: "https://products-salsify.geappliances.com/image/upload/s--5YuPmNzS--/28eb84fd62826c0a1c841e09db3ebdd6dec42b14.pdf",
			notes: ""
		},
		{
			id:'',
			name: "Basement Wine Chiller",
			model: "GE Monogram ZDW24",
			dateInstalled: "",
			link: "https://ericville-assets.s3.us-east-2.amazonaws.com/1681775937791-961700004.PDF",
			notes: ""
		},
		{
			id:'',
			name: "Kitchen Fridge",
			model: "LFX25974ST.ASTCNA0",
			dateInstalled: "",
			link: "https://gscs-b2c.lge.com/downloadFile?fileId=Yhw4w60bamKCaq8Ig79iyg",
			notes: ""
		},
		{
			id:'',
			name: "Gas Cooktip",
			model: "Bosch NGM5656UC",
			dateInstalled: "",
			link: "https://media3.bosch-home.com/Documents/8001269810_B.pdf",
			notes: "Installed sometime after another one that was replaced in 2014"
		},
		{
			id:'',
			name: "Kitchen Drink Fridge",
			model: "1175BEV",
			dateInstalled: "",
			link: "http://kitchen.manualsonline.com/manuals/mfg/uline/1175bev.html",
			notes: ""
		},
		{
			id:'',
			name: "Washer",
			model: "Electrolux ELFW7637BW",
			dateInstalled: "",
			link: "https://na2.electroluxmedia.com/Original/Electrolux/Electrolux%20Assets/Document/Complete%20Owners%20Guide/English/A20448602en.pdf",
			notes: "",
			cost: "$873"
		},
		{
			id:'',
			name: "Dryer",
			model: "Electrolux ELFG7637BW",
			dateInstalled: "",
			link: "https://na2.electroluxmedia.com/Original/Electrolux/Electrolux%20Assets/Document/Complete%20Owners%20Guide/English/A20448904en.pdf",
			notes: "",
			cost: "$873"
		},
		{
			id:'',
			name: "Dishwasher",
			model: "KitchenAid KDTM604KPS",
			dateInstalled: "",
			link: "https://www.kitchenaid.com/content/dam/global/documents/202110/owners-manual-w11323304-reve.pdf",
			notes: "",
			cost: "$691"
		},
		{
			id:'',
			name: "HVAC System",
			model: "",
			dateInstalled: "",
			link: "",
			notes: ""
		},
		{
			id:'',
			name: "Security System",
			model: "Ademco VISTA-10SE",
			dateInstalled: "",
			link: "https://ericville-assets.s3.us-east-2.amazonaws.com/1681775740441-manual_ademco_vista_10_se.pdf",
			notes: ""
		},
	]
};

export const manualsSlice = createSlice({
  name: 'manuals',
  initialState,
  reducers: {}
});

export default manualsSlice.reducer;

// Selectors

export const selectManuals = (state: RootState) => state.manuals.entities;
export const selectManualsStatus = (state: RootState) => state.manuals.status;