import React from 'react';

import { TextField, BaseTextFieldProps } from '@mui/material';

interface Props {
	name?: string;
	label?: string;
	value: string | number | undefined;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	sx?: object;
}

export const KNotes: React.FC<Props> = (
	{
		name='notes',
		label='Notes',
		value,
		onChange,
		sx={}
	}
) => {

	// Scrub null values
	value = (value===null) ? '' : value;

	let InputOptions: BaseTextFieldProps = {
		rows: 3,
		multiline: true,
		variant: "filled",
		fullWidth: true
	};

	return (
		<TextField
			{...InputOptions}
			name={name}
			label={label}
			value={value}
			onChange={onChange}
			margin="normal"
			sx={sx}
		/>
	);
}