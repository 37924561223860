import React from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DateRange from '@mui/icons-material/DateRange';
import LocalDining from '@mui/icons-material/LocalDining';
import Kitchen from '@mui/icons-material/Kitchen';
import Construction from '@mui/icons-material/Construction';
import FormatPaint from '@mui/icons-material/FormatPaint';
// import Loop from '@mui/icons-material/Loop';
import MenuBook from '@mui/icons-material/MenuBook';
// import Balance from '@mui/icons-material/Balance';
// import Thermostat from '@mui/icons-material/Thermostat';
// import Support from '@mui/icons-material/Support';
import ContactsIcon from '@mui/icons-material/Contacts';
// import LocalBar from '@mui/icons-material/LocalBar';
import RepeatOnIcon from '@mui/icons-material/RepeatOn';
import Home from '@mui/icons-material/Home';

export type NavigationItem = {
	type: 'navitem' | 'divider' | 'header';
	name?: string;
	to?: string;
	icon?: React.ReactElement;
	underconstruction?: boolean;
}

export const NavChoices: NavigationItem[] = [
	{
		type: "navitem",
		name: "Dashboard",
		to: '/',
		icon: (<Home />)
	},
	{
		type: "divider"
	},
	{
		type: "header",
		name: "Food"
	},
	{
		type: "navitem",
		name: "Meals",
		to: '/food/meals',
		icon: (<DateRange />)
	},
	{
		type: "navitem",
		name: "Restaurants",
		to: '/food/restaurants',
		icon: (<LocalDining />)
	},
	{
		type: "navitem",
		name: "Recipes",
		to: '/food/recipes',
		icon: (<Kitchen />)
	},
	// {
	// 	type: "navitem",
	// 	name: "Drink Menu",
	// 	to: '/food/drinks',
	// 	icon: (<LocalBar />),
	// 	underconstruction: true
	// },
	{
		type: "divider"
	},
	{
		type: "header",
		name: "Upkeep"
	},
	{
		type: "navitem",
		name: "Subscriptions",
		to: '/subscriptions',
		icon: (<RepeatOnIcon />)
	},
	{
		type: "navitem",
		name: "Projects",
		to: '/upkeep/projects',
		icon: (<Construction />)
	},
	{
		type: "divider"
	},
	{
		type: "navitem",
		name: "Address Book",
		to: '/help',
		icon: (<ContactsIcon />)
	},
	// {
	// 	type: "navitem",
	// 	name: "Maintenance",
	// 	to: '/upkeep/maintenance',
	// 	icon: (<Loop />)
	// },
	// {
	// 	type: "navitem",
	// 	name: "Climate",
	// 	to: '/upkeep/climate',
	// 	icon: (<Thermostat />),
	// 	underconstruction: true
	// },
	{
		type: "navitem",
		name: "Manuals",
		to: '/upkeep/manuals',
		icon: (<MenuBook />)
	},
	{
		type: "navitem",
		name: "Swatches",
		to: '/upkeep/swatches',
		icon: (<FormatPaint />)
	},
	{
		type: "divider"
	},
	{
		type: "navitem",
		name: "Guests",
		to: '/guestbook',
		icon: (<FavoriteIcon />)
	},

];