import React from 'react';
import { LinearProgress } from '@mui/material';
import { AppStatusContent } from '../AppStatusContent/AppStatusContent';
import { Box } from '@mui/material';

interface Props {
	context: string;
}

export const Loader: React.FC<Props> = ({ context }) => {
	switch(context) {
		case 'app':
			return <LoaderApp />
		case 'page':
			return <LoaderPage />
		case 'widget':
		default:
			return <></>;
	}
};

const LoaderApp: React.FC = () => {
	return (
		<AppStatusContent>
			<LinearProgress sx={{width: '30%'}}/>
		</AppStatusContent>
	);
}

const LoaderPage: React.FC = () => {
	return (
		<Box sx={{
			alignItems: 'center',
			justifyContent: 'center',
			height: '300px',
			width: '100%',
			display: 'flex'
			}}>
			<LinearProgress sx={{width: '30%'}} />
		</Box>
	);
}