import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Meal } from '../models/MealModel';
import { KenilworthBaseApi } from './KenilworthBaseApi';

import { store } from '../app/store';

export const mealsApi = createApi({
	reducerPath: 'mealsApi',
	baseQuery: async (request, api, extraOptions) => {
		const { auth } = store.getState();
		const customBaseQuery = fetchBaseQuery({
			  baseUrl: process.env.REACT_APP_ERICVILLE_API_BASEURL+'/food/meals',
		  prepareHeaders: (headers) => {
			if (auth.jwt) {
			  headers.set('Authorization', `Bearer ${auth.jwt}`);
			}
			return headers;
		  },
		});
	
		return customBaseQuery(request, api, extraOptions);
	},
	endpoints: (builder) => ({
		getByReferenceDate: builder.query({
			query: (referenceDate: string) => `referenceDate/${referenceDate}`,
			transformResponse: (response: any) => response.items,
		}),
		...KenilworthBaseApi<Meal>(builder)
	})
});

/**
 * 
 * Interfaces
 * 
 */

export const {
	useGetAllQuery,
	useGetByReferenceDateQuery,
	useAddMutation,
	useUpdateMutation,
	useDeleteMutation,
	usePrefetch
} = mealsApi;