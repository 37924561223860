import { useCallback } from 'react';
import moment from 'moment';

export const addPlaceholder = (slots, draftMeal, setMealSlots) => {
    
    // Check if the date and meal type are provided
    if(draftMeal.mealType && draftMeal.date) {

        // Iterate through each daySlotKey
        Object.keys(slots).forEach((daySlotsKey) => {
            
            // If the Draft Meal's key matches the current key
            if(daySlotsKey === moment(draftMeal.date).utc().startOf('day').toISOString()) {
                const placeholder = {
                    id: draftMeal.id,
                    date: draftMeal.date,
                    mealType: draftMeal.mealType,
                    isPlaceholder: true
                }
                slots[daySlotsKey].push(placeholder);
            }
        });
        setMealSlots(slots);

    } else {
        throw('Either date or meal type are missing.');
    }

};

export const fillMealSlots = (slots, meals, setMealSlots) => {

    if(meals.length > 0) {

        // Iterate through each daySlotKey
        Object.keys(slots).forEach((daySlotsKey) => {
            
            // Get meals for the daySlotKey
            const mealsForMealSlots = meals.filter((meal) => (daySlotsKey === moment(meal.date).utc().startOf('day').toISOString()));
            
            // If there are meals, add them to the slot
            if (mealsForMealSlots.length > 0) {
                for (const currentMeal of mealsForMealSlots) {
                    slots[daySlotsKey].push(currentMeal);
                }
            }

        });

    }

    setMealSlots(slots);

};