import { Box, Card, CardContent, SxProps } from '@mui/material';
import React, { ReactNode } from 'react';
import { paletteColors } from '../../theme/theme';

interface Props {
    title: string;
    children: ReactNode;
    sx?: SxProps;
};

export const HomeWidget: React.FC<Props> = ({children, title, sx={}}) => {
    return (
        <Card sx={{...sx, flex:1, fontSize: '0.8rem'}}>
            <Box sx={{
                px: 2,
                py: 1,
                fontWeight: '500',
                bgcolor: paletteColors.blue.xxlight,
                textShadow: '1px 1px 0 #fff',
            }}>{title}</Box>
            <CardContent sx={{flexGrow: 1}}>{children}</CardContent>
        </Card>
    )
}