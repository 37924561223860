import React from 'react';
import { inputChangeHandler } from '../../utilities/handleForm';
import { FilterBar } from '../../components/FilterBar/FilterBar';
import { KSearch } from '../../components/KSearch/KSearch';

export const initialFilters = {
	search: ''
}

interface Props {
	filters: typeof initialFilters;
	setFilters: (x: typeof initialFilters)=>void;
}

export const RecipeFilters: React.FC<Props> = ({ filters, setFilters }) => {
	
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => inputChangeHandler(e, {
		data: filters,
		setData: setFilters
	})

	return (
		<FilterBar>
			<KSearch
				autoFocus
				name="search"
				value={filters.search}
				onChange={handleChange} />
		</FilterBar>
	);
}