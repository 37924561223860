import React from 'react';
import { KListItem } from '../../components/KListItem/KListItem';
import { Recipe } from '../../models/RecipeModel';
import { KListItemType } from '../../components/KListItem/KListItemType';
import { KServiceIcon } from '../../components/KServiceIcon/KServiceIcon';
import { IconRecipe } from '../../components/FeatureIcons/FeatureIcons';

interface Props {
	data: Recipe;
	primaryAction?: (data: Recipe) => void;
	type?: KListItemType;
}

export const RecipeItem: React.FC<Props> = ({data, primaryAction, type='list'}) => {
	
	if(!data) return (<>Error</>);

	const handleEdit = () => primaryAction ? primaryAction(data) : (null);

	const secondary = () => {
		return data.notes as string;
	}


	if(type==='card') {
		return (
			<KListItem
				type='card'
				iconSymbol={IconRecipe.icon}
				iconColor={IconRecipe.color}
				primary={data.name}
				secondary={secondary()}
				primaryAction={handleEdit}
			/>
		);	
	}

	return (
		<KListItem
			primary={data.name}
			secondary={secondary()}
			iconSymbol={IconRecipe.icon}
			iconColor={IconRecipe.color}
			primaryAction={handleEdit}
			actionItems={[
				<KServiceIcon service='pinterest' url={data.link as string} />
			]}
		/>
	);
}
