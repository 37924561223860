import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const barColors = ["blue", "pink"]; // Adjust the number of colors to match the number of keys in the data

export const KChartStackedBar = ({ data, width, height, hideY = true, ratio = false }) => {
  if(!data) return <>Loading...</>;

  const svgRef = useRef();

  useEffect(() => {
    // Process the data to fit d3.stack format
    const processedData = Object.entries(data).map(([date, counts]) => ({
      date,
      ...counts
    }));

    // Assuming all data objects have the same keys, so we'll use the first one to get them
    const stackKeys = Object.keys(processedData[0]).filter(key => key !== 'date');
    const stack = d3.stack().keys(stackKeys);
    const stackedValues = stack(processedData);

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    // X Scale
    const xScale = d3.scaleBand()
      .domain(processedData.map(d => d.date))
      .range([0, width])
      .padding(0.1);

    // Y Scale
    const yMax = d3.max(stackedValues, layer => d3.max(layer, sequence => sequence[1]));
    const yScale = d3.scaleLinear()
      .domain([0, yMax])
      .range([height, 0]);

    // Color Scale
    const color = d3.scaleOrdinal()
      .domain(stackKeys)
      .range(barColors);

    // X Axis
    svg.append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale).tickFormat(d => d.substring(0, 10)));

    // Y Axis
    if (!hideY) {
      svg.append("g").call(d3.axisLeft(yScale));
    }

    // Stacked bars
    const layer = svg.selectAll(".layer")
      .data(stackedValues, d => d.key)
      .enter().append("g")
      .attr("class", "layer")
      .style("fill", (d, i) => color(d.key));

    layer.selectAll("rect")
      .data(d => d)
      .enter().append("rect")
      .attr("x", d => xScale(d.data.date))
      .attr("y", d => yScale(d[1]))
      .attr("height", d => yScale(d[0]) - yScale(d[1]))
      .attr("width", xScale.bandwidth());

  }, [data, width, height, hideY, ratio]);

  return (
    <svg ref={svgRef} width={width} height={height} />
  );
};
