import React from 'react';
import { PriorityMapping } from '../../models/micro/Priority';
import { KListItem } from '../../components/KListItem/KListItem';
import { printEnumLabel } from '../../utilities/printEnumLabel';
import { Project } from '../../models/ProjectModel';
import { KServiceIcon } from '../../components/KServiceIcon/KServiceIcon';
import { IconProject } from '../../components/FeatureIcons/FeatureIcons';

interface Props {
	data: Project;
	primaryAction: (data: Project) => void;
};

export const ProjectItem: React.FC<Props> = ({ data, primaryAction }) => {

	if(!data) return (<>Error</>);

	const handleEdit = () => primaryAction(data);

	const secondary = () => {
		let tCostEstimate = '$0';
		if(data.costEstimate !== null) tCostEstimate = '$'+data.costEstimate?.toLocaleString('en-US');
		let tPriority = printEnumLabel(PriorityMapping, data.priority);

		return [
			'Priority: '+tPriority,
			'Cost Estimate: '+tCostEstimate,
		].join(' – ');
	}

	return (
		<KListItem
			primary={data.name}
			iconSymbol={IconProject.icon}
			iconColor={IconProject.color}
			secondary={secondary()}
			primaryAction={handleEdit}
			actionItems={[
				<KServiceIcon service="pinterest" url={data.linkPinterest as string} />
			]}
		/>
	)
};