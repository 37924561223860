import React, { useState, ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContext } from './DialogContext';

/**
 * 
 * Dialog Provider
 * 
 */

interface DialogProviderProps {
	children: ReactNode;
}

export const DialogProvider: React.FC<DialogProviderProps> = ({ children }) => {
	const [dialog, setDialog] = useState<{ component: React.FC<any>; props?: Record<string, any> } | null>(null);
  
	const openDialog = (component: React.FC<any>, props: Record<string, any> = {}) => {
	  setDialog({ component, props });
	};
  
	const closeDialog = () => {
		setDialog(null);
	};

	return (
		<DialogContext.Provider value={{ openDialog, closeDialog }}>
			{children}
			{dialog && (
				<Dialog
					disableRestoreFocus
					maxWidth='sm'
					sx={{
						'& .MuiPaper-root': {width: '100%'}
					}}
					open={true}
					onClose={closeDialog}
				>
					<dialog.component {...dialog.props} closeDialog={closeDialog} />
				</Dialog>
			)}
		</DialogContext.Provider>
	);
};

// -----
// Usage example in a component
// -----
// const SomeComponent = () => {
// 	const { openDialog } = useDialog();
// 	return <button onClick={() => openDialog(YourDialogComponent, { prop1: 'someValue' })}>Open Dialog</button>;
// };

// -----
// Your dialog component
// -----
// const YourDialogComponent = ({ closeDialog, prop1 }) => {
// 	return (
// 		<div>
// 			<h2>Dialog Title</h2>
// 			<p>Some content using prop: {prop1}</p>
// 			<button onClick={closeDialog}>Close</button>
// 		</div>
// 	);
// };
