import { Box } from '@mui/material'
import React from 'react'

export const EmptyList: React.FC = () => (
	<Box
		data-testid='empty-list-message'
		sx={{
			height: '300px',
			justifyContent: 'center',
			alignItems: 'center',
			display: 'flex',
		}}
	>
		Womp womp, no items.
	</Box>
)