import React from 'react';
import { ListItemButton } from '@mui/material';
import { paletteColors } from '../../theme/theme';

import { KListItemThumbnail } from '../KListItemThumbnail/KListItemThumbnail';
import { IconRecipe, IconRestaurant } from '../FeatureIcons/FeatureIcons';

type hasIdAndName = {
	id: string,
	name: string
};

interface Props {
    data: (any & hasIdAndName),
    selected: boolean,
    onSelect: (id: string)=>any
}

export const KSearchPicklistItem: React.FC<Props> = ({ data, selected, onSelect }) => {
	
    const handleClick = () => {
		onSelect(data);
	}

    return (
            <ListItemButton
                onClick={handleClick}
                sx={{
                    display: 'flex',
                    gap: 1,
                    bgcolor: (selected ? paletteColors.red.main : 'transparent'),
                    color: (selected ? paletteColors.white : 'inherit'),
                    '&:hover, &:focus': {
                        bgcolor: (selected ? paletteColors.red.main : paletteColors.red.main+'33'),
                    }
                }}
            >
                {data.type==='recipe' && <KListItemThumbnail thumbnail={IconRecipe.icon} color={IconRecipe.color} />}
                {data.type==='restaurant' &&  <KListItemThumbnail thumbnail={IconRestaurant.icon} color={IconRestaurant.color} />}
                
                {data.name}
            </ListItemButton>
    );
}