import React from 'react';
import { Button, DialogActions, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
    handleSubmit: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
    handleDelete?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    handleClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const DialogKActions: React.FC<Props> = ({handleClose, handleDelete, handleSubmit}) => {
    return (
		<DialogActions
			sx={{
				px: 3,
				py: 2,
				boxShadow: '0 0 15px #0003',
				display: 'flex',
				gap: 3,
				flexFlow: 'row-reverse'
			}}
		>
			<Button aria-label="save-action" variant="contained" onClick={handleSubmit} type="submit">Save</Button>
			<Button aria-label="cancel-action" variant="text" onClick={handleClose}>Cancel</Button>
			<div style={{flex: '1 0 0'}} />
			{handleDelete && <IconButton aria-label="delete-action" onClick={handleDelete}><DeleteIcon /></IconButton>}
		</DialogActions>
    )
}