import React, { ReactElement, useState } from 'react';
import { KListItem } from '../../components/KListItem/KListItem';

import RepeatIcon from '@mui/icons-material/Repeat';
import RepeatOneIcon from '@mui/icons-material/RepeatOne';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import { Subscription } from '../../models/SubscriptionModel';
import { Alert, Chip, Snackbar } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import PasswordIcon from '@mui/icons-material/Password';
import { KServiceIcon } from '../../components/KServiceIcon/KServiceIcon';
import { paletteColors } from '../../theme/theme';
import { FixTypeLater } from 'react-redux';

interface Props {
	data: Subscription;
	primaryAction?: (data: Subscription) => void;
}

export const SubscriptionItem: React.FC<Props> = ({data, primaryAction}) => {

	if(!data) return (<>Error</>);

	const handleEdit = () => primaryAction ? primaryAction(data) : (null);
	const [confirmCopy, setConfirmCopy] = useState(false);
	const handleSnackBarClose = () => setConfirmCopy(false);

	const secondary = () => {
		return '$'+data.billingAmount+' '+data.billingCycle as string;
	}

	const generateLoginInfo = () => {
		const keyInfo: ReactElement[] = [];
		const s = data.username?.toLocaleLowerCase(); // Shorthand for repeating indexOf's

		const ChipProps = {
			label: data.username,
			clickable: true
		}

		if(data.username && data.password) {
			const handleChipCopy = (e: FixTypeLater) => {
				navigator.clipboard.writeText(e.target.innerText)
					.then(() => { setConfirmCopy(true) })
					.catch(err => {
						console.error("Failed to copy text: ", err);
					});
			};

			keyInfo.push(<Chip icon={<FaceIcon />} label={data.username} onClick={handleChipCopy} />);
			keyInfo.push(<Chip icon={<PasswordIcon />} label={data.password} onClick={handleChipCopy} />);
		}
		else if(s?.indexOf('amazon') !== -1)
			keyInfo.push(<Chip icon={<KServiceIcon service='amazon' />} {...ChipProps} component="a" href="http://login.amazon.com" target="_blank" />);
		else if(s?.indexOf('apple') !== -1)
			keyInfo.push(<Chip icon={<KServiceIcon service='apple' />} {...ChipProps} component="a" href="http://login.apple.com" target="_blank" />);
		else if(s?.indexOf('chase') !== -1)
			keyInfo.push(<Chip icon={<KServiceIcon service='chase' />} {...ChipProps} component="a" href="http://login.chase.com" target="_blank" />);
		else if(s?.indexOf('doordash') !== -1)
			keyInfo.push(<Chip icon={<KServiceIcon service='doordash' />} {...ChipProps} component="a" href="http://login.doordash.com" target="_blank" />);
		else if(s?.indexOf('facebook') !== -1)
			keyInfo.push(<Chip icon={<KServiceIcon service='facebook' />} {...ChipProps} component="a" href="http://login.facebook.com" target="_blank" />);
		else if(s?.indexOf('google') !== -1)
			keyInfo.push(<Chip icon={<KServiceIcon service='google' />} {...ChipProps} component="a" href="http://login.google.com" target="_blank" />);
		else if(s?.indexOf('grubhub') !== -1)
			keyInfo.push(<Chip icon={<KServiceIcon service='grubhub' />} {...ChipProps} component="a" href="http://login.grubhub.com" target="_blank" />);
		else if(s?.indexOf('paypal') !== -1)
			keyInfo.push(<Chip icon={<KServiceIcon service='paypal' />} {...ChipProps} component="a" href="http://login.paypal.com" target="_blank" />);
		else if(s?.indexOf('pinterest') !== -1)
			keyInfo.push(<Chip icon={<KServiceIcon service='pinterest' />} {...ChipProps} component="a" href="http://login.pinterest.com" target="_blank" />);
		else if(s?.indexOf('ubereats') !== -1)
			keyInfo.push(<Chip icon={<KServiceIcon service='ubereats' />} {...ChipProps} component="a" href="http://login.ubereats.com" target="_blank" />);
		else if(s?.indexOf('x') !== -1)
			keyInfo.push(<Chip icon={<KServiceIcon service='x' />} {...ChipProps} component="a" href="http://login.x.com" target="_blank" />);
		else if(s?.indexOf('yelp') !== -1)
			keyInfo.push(<Chip icon={<KServiceIcon service='yelp' />} {...ChipProps} component="a" href="http://login.yelp.com" target="_blank" />);
		else if(s?.indexOf('youtube') !== -1)
			keyInfo.push(<Chip icon={<KServiceIcon service='youtube' />} {...ChipProps} component="a" href="http://login.youtube.com" target="_blank" />);
		else
			return;
		// keyInfo.push(<Chip icon={<KServiceIcon service='website' />} {...ChipProps} component="a" href={data.loginLink} />);

		return keyInfo;
	}

	let customIconSymbol = <RepeatIcon />;
	let customIconColor = paletteColors.green.light;
	if(data.status === 'ACTIVE_EXPIRING') {
		customIconSymbol = <RepeatOneIcon />;
	}
	else if(data.status === 'INACTIVE') {
		customIconSymbol = <DisabledByDefaultIcon />;
		customIconColor = paletteColors.blue.xxlight;
	}
	else if(data.status === 'UNKNOWN') {
		customIconSymbol = <QuestionMarkIcon />
		customIconColor = paletteColors.blue.light;
	}


	return (
		<>
		<KListItem
			iconSymbol={customIconSymbol}
			iconColor={customIconColor}
			primary={data.name}
			secondary={secondary()}
			primaryAction={handleEdit}
			actionItems={generateLoginInfo()}
		/>
		<Snackbar open={confirmCopy} autoHideDuration={6000} onClose={handleSnackBarClose}>
			<Alert onClose={handleSnackBarClose} severity="success" sx={{ width: '100%' }}>
				Copied successfully
			</Alert>
		</Snackbar>
		</>
	);
}