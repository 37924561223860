import React from 'react';
import { DialogTitle, SxProps } from '@mui/material';

interface Props {
    children: React.ReactNode;
    sx?: SxProps
}

export const DialogKTitle: React.FC<Props> = ({children, sx}) => {
    return (
		<DialogTitle sx={{
      px:3,
      py:2,
      mb: 2,
      boxShadow:'0 0 15px #0002',
      ...sx
    }}>
			{children}
		</DialogTitle>
    )
}