import React from 'react';

import { Recipe, newRecipe } from '../../models/RecipeModel';
import { RecipeForm } from '../recipes/RecipeForm';

import { Restaurant, newRestaurant } from '../../models/RestaurantModel';
import { RestaurantForm } from '../restaurants/RestaurantForm';

import { KSearchPicklist } from '../../components/KSearchPicklist/KSearchPicklist';
import { Button, } from '@mui/material';
import { useDialog } from '../../components/DialogProvider/DialogContext';

interface Props {
	handleMealSelectionChange: (
		selectionData: Restaurant | Recipe | undefined,
		selectionType: 'RESTAURANT' | 'RECIPE' | undefined
	)=>void;
	mealSelectionData?: Restaurant | Recipe; // Used for "Edit"; Undefined if "New"
}

export const MealPicklist: React.FC<Props> = ({ handleMealSelectionChange, mealSelectionData }) => {

	const searchEndpoint: string = process.env.REACT_APP_ERICVILLE_API_BASEURL || '';

	/**
	 * 
	 * Picklist State & Handlers
	 * 
	 */

	const handleSelectedMealChange = (selectedItem: Recipe | Restaurant) => {
		console.log(selectedItem)
		// Brute forced - FIX THIS
		handleMealSelectionChange(selectedItem, (selectedItem.type==='recipe'?'RECIPE':'RESTAURANT'));
	}	

	/**
	 * 
	 * Form State & Handlers
	 * 
	 */

	const { openDialog } = useDialog();
	
	// In the future, override the 'name' field with the search box value.
	const handleAddRecipe = () => openDialog(RecipeForm, { initialData: {...newRecipe, name: ''} });
	const handleAddRestaurant = () => openDialog(RestaurantForm, { initialData: {...newRestaurant, name: ''} });

	/**
	 * 
	 * Render
	 * 
	 */

	return (
		<KSearchPicklist
			endpoint={searchEndpoint}
			selected={mealSelectionData}
			setSelected={handleSelectedMealChange}
			actionNoResults={
				<>
				<Button variant='contained' onClick={handleAddRecipe}>+ Recipe</Button>
				<Button variant='contained' onClick={handleAddRestaurant}>+ Restaurant</Button>
				</>
			}
		/>
	);
}