import React from 'react';
import { FormControlLabel, SelectChangeEvent, Switch } from '@mui/material';
import { RestaurantCategoryMappings } from '../../models/micro/RestaurantCategory';
import { inputChangeHandler } from '../../utilities/handleForm';
import { FilterBar } from '../../components/FilterBar/FilterBar';
import { KSelect } from '../../components/KSelect/KSelect';
import { KSearch } from '../../components/KSearch/KSearch';

export const initialFilters = {
	search: '',
	category: 'ANY',
	excludeChains: false
}

interface Props {
	filters: typeof initialFilters;
	setFilters: (x: typeof initialFilters)=>void;
}

export const RestaurantFilters: React.FC<Props> = ({ filters, setFilters }) => {
	
	const handleChange = (e: SelectChangeEvent<string|string[]>) => inputChangeHandler(e, {
		data: filters,
		setData: setFilters
	})

	return (
		<FilterBar>
			<KSearch
				name="search"
				autoFocus
				value={filters.search}
				onChange={handleChange} />

			<KSelect
				name="category"
				label="Category"
				fullWidth={false}
				value={filters.category}
				options={RestaurantCategoryMappings}
				onChange={handleChange} />

			<FormControlLabel
				value={filters.excludeChains}
				control={<Switch color="primary" name="excludeChains" onChange={handleChange} />}
				label="Exclude&nbsp;Chains"
				labelPlacement="start"
			/>
		</FilterBar>
	);
}