import { createContext } from 'react';

interface contextTypes {
	mobileOpen: boolean;
	setMobileOpen: (x: boolean) => void;
};

const defaultContext: contextTypes = {
	mobileOpen: false,
	setMobileOpen: () => {}
}

export const LaunchNavigationContext = createContext<contextTypes>(defaultContext);