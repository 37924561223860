import { MongoId } from "./macro/MongoId";

type User = {
    id: MongoId;
    idAuth0: string,    // Maps to user_id
    firstName: string,      // Maps to given_name
    lastName: string,       // Maps to family_name
    email?: string,          // Maps to email
    phone?: string,
    locale?: string,         // Maps to locale
    avatar?: string,        // Maps to picture
    nickname?: string,
    timezone?: string
}

export type UserModel = User;

export const newUser: UserModel = {
    id: '',
    idAuth0: '',
    firstName: '',
    lastName: ''
}

export const user_Complete_Test: UserModel = {
    "id": "u1",
    "idAuth0": "google-oauth2|0000001",
    "firstName": "Thing",
    "lastName": "One",
    "nickname": "thing.one",
    "locale": "en",
    "timezone": "",
    "avatar": "https://lh3.googleusercontent.com/a/ACg8ocKOb7LTbVsIsThryB7nTQacBzdM8rVY8IujlLMHsABLx0o=s96-c",
    "email": "eric.witke@gmail.com",
    "phone": "7738121814"
}

export const user_Incomplete_Test = {
    "id": "u2",
    "idAuth0": "google-oauth2|0000002",
    "firstName": "Thing",
    "lastName": "Two",
    "nickname": undefined,
    "locale": undefined,
    "timezone": undefined,
    "avatar": undefined,
    "email": undefined,
    "phone": undefined
}

// export const Auth0UserExample = {
//     "created_at": "2022-10-23T03:29:29.131Z",
//     "email": "eric.wittke@gmail.com",
//     "email_verified": true,
//     "family_name": "Wittke",
//     "given_name": "Eric",
//     "identities": [
//         {
//             "provider": "google-oauth2",
//             "user_id": "103090821191508782167",
//             "connection": "google-oauth2",
//             "isSocial": true
//         }
//     ],
//     "locale": "en",
//     "name": "Eric Wittke",
//     "nickname": "eric.wittke",
//     "picture": "https://lh3.googleusercontent.com/a/ACg8ocKOb7LTbVsIsThryB7nTQacBzdM8rVY8IujlLMHsABLx0o=s96-c",
//     "updated_at": "2023-09-11T01:41:42.848Z",
//     "user_id": "google-oauth2|103090821191508782167",
//     "last_ip": "208.59.106.35",
//     "last_login": "2023-09-11T01:41:42.847Z",
//     "logins_count": 42,
//     "blocked_for": [],
//     "guardian_authenticators": []
// }