import { Box, Button, Chip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { paletteColors } from '../../theme/theme';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { KPI, KPIBadge } from '../KPIBadge/KPIBadge';

interface Props {
    title: string;
    children: any;

    collapsible?: boolean;
    initiallyCollapsed?: boolean;

    showItemCount?: boolean;
    stats?: KPI[];
}

export const KListSection: React.FC<Props> = ({children, title, stats, collapsible=true, initiallyCollapsed=true, showItemCount=true}) => {

    const [isCollapsed, setIsCollapsed] = useState(initiallyCollapsed);

    const handleCollapse = () => collapsible ? setIsCollapsed(isCollapsed ? false : true) : undefined;

    const totalItems = React.Children.count(children)

    return (
        <Box sx={{
            mt:0,
            borderTop: '1px solid #fff'
        }}>
            <Button
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 0.5,
                    alignItems: 'center',
                    textTransform: 'none',
                    width: '100%',
                    textAlign: 'left',
                    minHeight: '60px',
                    cursor: collapsible ? 'pointer' : 'defualt',
                    background: isCollapsed ? paletteColors.blue.xxlight : paletteColors.blue.light+'66',
                    borderRadius: 0,
                    padding: '0.5rem 0.5rem 0.5rem 1rem',
                    transition: 'all 100ms ease-in',
                    '&:hover': {
                        bgcolor: paletteColors.blue.light+'66'
                    }
                }}
                onClick={handleCollapse}
            >
                {collapsible && (isCollapsed ? <ExpandLessIcon sx={{opacity:0.3}} /> : <ExpandMoreIcon sx={{opacity:0.3}} />)}
                <Typography variant='h3' sx={{flexGrow: 1}}>
                    {title}
                    {showItemCount && totalItems > 0 && <Chip label={totalItems} sx={{ml:1,mt:-0.5}} />}
                </Typography>
                {stats && (stats?.length > 0) &&
                    stats?.map((stat: KPI, index: number) =>
                        <KPIBadge key={index} value={stat.value} label={stat.label} />
                    )
                }
            </Button>
            {!isCollapsed && <Box sx={{
                px:2,
                pt:2,
                pb:3,
                '& > *:hover': {
                    borderRadius: '6px'
                }
            }}>{children}</Box>}
        </Box>

    )
}