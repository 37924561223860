import moment from "moment";

export const userTimezone = -5;

export const now = moment().utcOffset(userTimezone);

export type MealPlannerSettings = {
	WEEK_STARTS_ON: string,
	TOTAL_WEEKS: number,
	SELECTED_DAY: string,
	SELECTED_WEEK_KEY: string,
	VIEW_MODE: 'MOBILE' | 'DESKTOP'
};

export const defaultSettings: MealPlannerSettings = {
		
    // Personal Settings

    WEEK_STARTS_ON: 'Monday',

    // Density of week picker

    TOTAL_WEEKS: 30,

    // Defines the reference point for getting the week's meals
    // Also used for mobile views
    // Defaults to today (TO DO: Add timezone)

    SELECTED_DAY: now.format('YYYY-MM-DD'),

    // Keys used for accessing data in mealSlots

    SELECTED_WEEK_KEY: now.format('YYYY')+'.'+now.format('WW'),

    // View Mode

    VIEW_MODE: 'DESKTOP'
}