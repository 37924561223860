import React from 'react';
import { DialogContent } from '@mui/material';

interface Props {
    children: React.ReactNode;
}

export const DialogKContent: React.FC<Props> = ({children}) => {
    return (
      <DialogContent>
          {children}
      </DialogContent>
    )
}