import { useState, useEffect } from 'react';

const useResponsiveWidth = (ref) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      setWidth(ref.current ? ref.current.offsetWidth : 0);
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [ref]);

  return width;
};

export default useResponsiveWidth;
