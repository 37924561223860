import React from 'react';
import { Avatar, Box, CssBaseline } from '@mui/material';
import {paletteColors} from '../../theme/theme';

interface Props {
	children?: JSX.Element[] | JSX.Element;
}

export const AppStatusContent: React.FunctionComponent<Props> = ({children}) => {
	return(
		<>
			<CssBaseline />
			<Box sx={{m: '0', p: '0', alignItems: 'center', justifyContent: 'center', bgcolor: paletteColors.blue.dark, height: '100vh', width: '100%', display: 'flex', flexDirection: 'column'}}>
				<Avatar sx={{width:90, height:90, borderRadius: 0, mb: '2rem'}} src='/icon_kenilworth180.png' />
				{children}
			</Box>
		</>
	);
};