import React, { useState } from 'react';
import { useAddMutation } from '../../slices/guestBookApi';
import { newEntry } from '../../models/GuestBookModel';
import { Card, CardHeader, CardContent, CardActions, Button, TextField } from '@mui/material';

export const GuestBookForm: React.FC = () => {

	/**
	 * 
	 * API Controller
	 * 
	 */

	const [addGuestBookEntry] = useAddMutation();

	/**
	 * 
	 * Form Controls
	 * 
	 */

	const [entryForm, setEntryForm] = useState(newEntry);
	const resetEntryForm = () => setEntryForm(newEntry);

	const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		addGuestBookEntry(entryForm);
		resetEntryForm();
	};
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEntryForm({
			...entryForm,
			[e.target.id]: e.target.value
		});
	};

	/**
	 * 
	 * Render Component
	 * 
	 */

	return (
		<Card
			elevation={0}
			sx={{
				'& > * > :not(xs)': { mb: 2 },
				p: '1.2rem',
				backgroundColor: '#ececcd',
				// borderLeft: '3px solid '+paletteColors.blue.main
				borderRadius: 0
		  	}}>
			<CardHeader title="Drop a sticky note before you leave!" />
			<CardContent>
				<TextField
					fullWidth
					variant="outlined"
					label="Name"
					id="name"
					value={entryForm.name}
					onChange={handleChange}
					multiline={false}
					autoFocus={true}
				/>
				<TextField
					fullWidth
					variant="outlined"
					label="Sticky Note"
					placeholder='Something funny, wise, fun, or whatever you want to say. Did the food suck? Did Eric scare you away with his dad jokes? Did Apollo almost push you down the stairs?'
					id="note"
					value={entryForm.note}
					onChange={handleChange}
					rows={4}
					multiline={true}
					autoFocus={false}
				/>
			</CardContent>
			<CardActions>
				<Button
					type="submit"
					onClick={handleSubmit}
					variant="contained"
				>Sign</Button>
			</CardActions>
		</Card>
	);
}