import React, {useEffect, useState} from 'react';

import { useAddMutation, useUpdateMutation, useDeleteMutation } from '../../slices/subscriptionsApi';
import { deletionHandler, inputChangeHandler, submitHandler } from '../../utilities/handleForm';

import { Box, SelectChangeEvent } from '@mui/material';

import { Subscription } from '../../models/SubscriptionModel';
import { SubscriptionCategoryMappings } from '../../models/micro/SubscriptionCategory';
import { SubscriptionStatusMappings } from '../../models/micro/SubscriptionStatus';
import { SubscriptionBillingCycleMappings } from '../../models/micro/SubscriptionBillingCycle';

import { getFormVerb } from '../../utilities/getFormVerb';
import { KForm } from '../../components/KForm/KForm';
import { KFormSection } from '../../components/KFormSection/KFormSection';
import { KTextField } from '../../components/KTextField/KTextField';
import { KSelect } from '../../components/KSelect/KSelect';
import { KNotes } from '../../components/KNotes/KNotes';

import { DialogKActions } from '../../components/DialogKActions/DialogKActions';
import { DialogKTitle } from '../../components/DialogKTitle/DialogKTitle';
import { DialogKContent } from '../../components/DialogKContent/DialogKContent';

interface Props {
	closeDialog: ()=>void;
	initialData: Subscription;
}

export const SubscriptionForm: React.FC<Props> = ({ closeDialog, initialData }) => {

	/**
	 * 
	 * API Controller
	 * 
	 */

	const [addSubscription] = useAddMutation();
	const [updateSubscription] = useUpdateMutation();
	const [deleteSubscription] = useDeleteMutation();

	/**
	 * 
	 * Form Controls
	 * 
	 */

	const [formLocalData, setFormLocalData] = useState<Subscription>(initialData); 

	useEffect(() => {
		setFormLocalData(initialData);
	}, [initialData])

	const verb = getFormVerb(formLocalData);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string | string[]>) => inputChangeHandler(e, {
		data: formLocalData,
		setData: setFormLocalData
	});

	const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => submitHandler(e, {
		data: formLocalData,
		add: addSubscription,
		update: updateSubscription,
		close: handleClose
	})

	const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => deletionHandler(e, {
		data: formLocalData,
		delete: deleteSubscription,
		close: handleClose
	})

	const handleClose = () => closeDialog();

	/**
	 * 
	 * Render Component
	 * 
	 */

	return (
		<>
		<DialogKTitle>
			{verb+" Restaurant"}
		</DialogKTitle>
		
		<DialogKContent>

			<KForm>

				<KTextField
					type="name"
					name="name"
					value={formLocalData.name || ''}
					label="Subscription Name"
					onChange={handleChange} />

				<Box sx={{display: 'flex', gap: 1}}>
					<KSelect
						name="category"
						label="Category"
						value={formLocalData.category || ''}
						options={SubscriptionCategoryMappings}
						onChange={handleChange} />
					<KSelect
						name="status"
						label="Status"
						value={formLocalData.status || ''}
						options={SubscriptionStatusMappings}
						onChange={handleChange} />
				</Box>

				<KFormSection title='Billing Info' horizontal>
					<KSelect
						name="billingCycle"
						label="Billing Cycle"
						value={formLocalData.billingCycle || ''}
						options={SubscriptionBillingCycleMappings}
						onChange={handleChange} />
					<KTextField
						type="dollars"
						name="billingAmount"
						label="Billing Amount"
						value={formLocalData.billingAmount || ''}
						onChange={handleChange} />
					<KTextField
						name="billingDate"
						label="Billing Date"
						value={formLocalData.billingDate || ''}
						onChange={handleChange} />
				</KFormSection>
				
				<KFormSection title='Login Info'>
					<KTextField
						type="text"
						name="username"
						value={formLocalData.username || ''}
						label="Username"
						onChange={handleChange} />
					<KTextField
						type="text"
						name="password"
						value={formLocalData.password || ''}
						label="Password"
						onChange={handleChange} />
					<KTextField
						type="link"
						name="Login Link"
						value={formLocalData.loginLink || ''}
						label="Login Link"
						onChange={handleChange} />
				</KFormSection>

				<KNotes
					value={formLocalData.notes || ''}
					onChange={handleChange} />

			</KForm>
		</DialogKContent>

		<DialogKActions
			handleSubmit={handleSubmit}
			handleDelete={handleDelete}
			handleClose={handleClose}
		/>

		</>
	);
}