import { BaseModel } from "./macro/BaseModel";
import type { Link } from "./micro/Link";
import { SubscriptionBillingCycle } from "./micro/SubscriptionBillingCycle";
import { SubscriptionCategory } from "./micro/SubscriptionCategory";
import { SubscriptionStatus } from "./micro/SubscriptionStatus";
import { UserGroupOwnership } from "./micro/UserGroupOwnership";

type SubscriptionDetails = {
    category: SubscriptionCategory
    status: SubscriptionStatus
    billingCycle: SubscriptionBillingCycle
    billingAmount: number
    billingDate?: string
    username?: string
    password?: string
    loginLink?: Link
};

export type Subscription = BaseModel & UserGroupOwnership & SubscriptionDetails;

/**
 * 
 * NEW BLANK OBJECT
 * 
 */

export const newSubscription: Subscription = {
	id: '',
	name: '',
    notes: '',
    userGroupOwnershipId: '',
	category: 'UNCATEGORIZED',
    status: 'UNKNOWN',
    billingCycle: 'MONTHLY',
    billingAmount: 0,
    billingDate: '',
    username: '',
    password: '',
    loginLink: '',
};

// export const testSubscription: Subscription[] = [
//     {
//         "id": "64fe0c31f97eac42a0e6ef08",
//         "name": "Docker",
//         "notes": "",
//         "category": "VOCATIONAL",
//         "status": "ACTIVE_REPEATING",
//         "userGroupOwnershipId": "6507d6953ff94f6948c24790",
//         "dateModified": null,
//         "dateCreated": null,
//         "createdBy": null,
//         "modifiedBy": null,
//         "billingCycle": "YEARLY",
//         "billingDate": "September 6",
//         "billingAmount": 60,
//         "username": "",
//         "password": "",
//         "loginLink": "TBD"
//     },
//     {
//         "id": "64fe0c44f97eac42a0e6ef09",
//         "name": "YouTube Premium",
//         "notes": "",
//         "category": "ENTERTAINMENT",
//         "status": "INACTIVE",
//         "userGroupOwnershipId": "6507d6953ff94f6948c24790",
//         "dateModified": null,
//         "dateCreated": null,
//         "createdBy": null,
//         "modifiedBy": null,
//         "billingCycle": "MONTHLY",
//         "billingDate": "",
//         "billingAmount": 14,
//         "username": "Eric's YouTube account",
//         "password": "",
//         "loginLink": "TBD"
//     }
// ]