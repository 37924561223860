import React from 'react';
import { List } from '@mui/material';
import { PageContent } from '../../components/PageContent/PageContent';
import { useAppSelector } from '../../app/hooks';
import { selectManuals } from '../../slices/manualsSlice';
import { ManualItem } from './ManualItem';
import { Manual } from '../../models/ManualModel';

export const Manuals: React.FC = () => {

	const manuals: Manual[] = useAppSelector(selectManuals);

	return (
		<PageContent title='Manuals'>
			<List>
				{manuals.map((manual, index) => (
					<ManualItem data={manual} key={index} />
				))}
			</List>
		</PageContent>
	);
}