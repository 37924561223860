import React from 'react';
import { KListItem } from '../../components/KListItem/KListItem';
import Article from '@mui/icons-material/Article';
import { Manual } from '../../models/ManualModel';
import { KServiceIcon } from '../../components/KServiceIcon/KServiceIcon';

interface Props {
	data: Manual;
}

export const ManualItem: React.FC<Props> = ({ data }) => {
	
	const secondary = () => {
		return [
			data.model,
			data.dateInstalled,
			data.notes
		].join(' – ');
	}

	return (
		<KListItem
			iconSymbol={<Article />}
			iconColor='#4f1fca'
			primary={data.name}
			secondary={secondary()}
			actionItems={[
				<KServiceIcon service='website' url={data.link} />
			]}
		/>
	)
}