interface SuperBaseItem {
	id: string;
}

export const KenilworthBaseApi = <T extends SuperBaseItem>(builder: any) => ({

	getAll: builder.query({
		query: () => ({
			url: '/'
		}),
		transformResponse: (response: any) => response.items,
	}),

	add: builder.mutation({
		query: (newItem: T) => ({
			url: '/',
			method: 'POST',
			body: newItem,
		}),
	}),

	update: builder.mutation({
		query: (item: T) => ({
			url: `/id/${item.id}`,
			method: 'PATCH',
			body: item,
		}),
	}),
	
	delete: builder.mutation({
		query: (item: T) => ({
			url: `/id/${item.id}`,
			method: 'DELETE',
		}),
	}),
});