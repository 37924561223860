import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { MaintenanceJob } from '../models/MaintenanceModel';

export interface MaintenanceState {
	status: string;
	entities: MaintenanceJob[];
	filters?: object;
}

const initialState: MaintenanceState = {
	status: 'success',
	entities: [
		{
			name: "Coat Driveway",
			frequency: "Every 3 years",
			lastCompleted: ""
		},
		{
			name: "Refill Sump-Pump Battery",
			frequency: "Every 2 months",
			lastCompleted: ""
		},
		{
			name: "Replace Water Filters",
			frequency: "Every 6 months",
			lastCompleted: ""
		},
		{
			name: "Replace Air Filters",
			frequency: "Every 6 months",
			lastCompleted: ""
		},
		{
			name: "Mow Lawn",
			frequency: "Every week in spring and summer",
			lastCompleted: ""
		},
		{
			name: "New Roof",
			frequency: "Every 10 years",
			lastCompleted: ""
		},
	]
};

export const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {}
});

export default maintenanceSlice.reducer;

// Selectors

export const selectMaintenance = (state: RootState) => state.maintenance.entities;
export const selectMaintenanceStatus = (state: RootState) => state.maintenance.status;