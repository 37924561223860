// import { User as Auth0User } from "@auth0/auth0-react"
import { UserModel, user_Complete_Test, user_Incomplete_Test } from "./UserModel";
import { BaseModel } from "./macro/BaseModel";
import { UserId } from "./macro/UserId";

type UserGroup = {
    memberIds: UserId[];
    memberData?: UserModel[];
    features: string[];
}

export type UserGroupModel = UserGroup & BaseModel;

export const newUserGroup = {
    id: '',
    name: '',
    memberIds: [],
    memberData: [],
    features: []
}

export const userGroup_Incomplete_Test: UserGroupModel = {
    "id": "6507d6953ff94f6948c24790",
    "name": "Test Incomplete User Group",
    "notes": undefined,
    "memberIds": [],
    "memberData": undefined,
    "features": []
}

export const userGroup_Complete_Test: UserGroupModel = {
    "id": "6507d6953ff94f6948c24790",
    "name": "Test Complete User Group",
    "notes": "The Wittke Family",
    "memberIds": [
        user_Complete_Test.id,
        user_Incomplete_Test.id
    ],
    "memberData": [
        user_Complete_Test,
        user_Incomplete_Test
    ],
    "features": [
        "home_food",
        "home_guestbook",
        "home_subscriptions",
        "home_adfree",
        "home_projects",
        "account_tester"
    ]
}