import React from 'react';
import { IconButton, Avatar } from '@mui/material';

import amazonIcon from '../../assets/icons/services/amazon.png';
import appleIcon from '../../assets/icons/services/apple.png';
import chaseIcon from '../../assets/icons/services/chase.png';
import doordashIcon from '../../assets/icons/services/doordash.png';
import facebookIcon from '../../assets/icons/services/facebook.png';
import googleIcon from '../../assets/icons/services/google.png';
import grubhubIcon from '../../assets/icons/services/grubhub.png';
import paypalIcon from '../../assets/icons/services/paypal.png';
import pinterestIcon from '../../assets/icons/services/pinterest.png';
import ubereatsIcon from '../../assets/icons/services/ubereats.png';
import websiteIcon from '../../assets/icons/services/website.png';
import xIcon from '../../assets/icons/services/xapp.png'; // Keeping the name 'xIcon' here
import yelpIcon from '../../assets/icons/services/yelp.png';
import youtubeIcon from '../../assets/icons/services/youtube.png';

export type ServiceProviders = 'amazon' |
				'apple' |
				'chase' |
				'doordash' |
				'facebook' |
				'google' |
				'grubhub' |
				'paypal' |
				'pinterest' |
				'ubereats' |
				'website' |
				'x' |
				'yelp' |
				'youtube';

const serviceIcons: { [key in ServiceProviders]: string } = {
	amazon: amazonIcon,
	apple: appleIcon,
	chase: chaseIcon,
	doordash: doordashIcon,
	facebook: facebookIcon,
	google: googleIcon,
	grubhub: grubhubIcon,
	paypal: paypalIcon,
	pinterest: pinterestIcon,
	ubereats: ubereatsIcon,
	website: websiteIcon,
	x: xIcon,
	yelp: yelpIcon,
	youtube: youtubeIcon
};
				
interface Props {
    service: ServiceProviders;
	url?: string;
}

export const KServiceIcon: React.FC<Props> = ({ service, url='STATIC' }) => {

	const iconStyle = {
		width: '24px',
		height: '24px',
		ml: '4px',
		opacity: (url) ? '100%' : '10%'
	}

	const handleClick = (e:React.MouseEvent) => {
		e.stopPropagation();
		window.location.href=url;
	};

	if(url && url !== 'STATIC'){
		return (
			<IconButton onClick={handleClick} disabled={!url}>
				<Avatar sx={iconStyle} alt={service} src={serviceIcons[service]} />
			</IconButton>
		);
	}
	else {
		return (
			<IconButton disabled>
				<Avatar sx={iconStyle} alt={service} src={serviceIcons[service]} />
			</IconButton>
		);
	}
}