import React from 'react';

import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { KeyValuePair } from '../../models/micro/KeyValuePair';

interface Props {
	name: string;
	label: string;
	value: any;
	onChange: (event: SelectChangeEvent<string | string[]>) => void;
	options: KeyValuePair[];
	includeAny?: boolean;
	fullWidth?: boolean;
	sx?: object;
}

export const KSelect: React.FC<Props> = (
	{
		name,
		label,
		value,
		onChange,
		options,
		includeAny,
		sx={}
	}
) => {

	// React inputs don't like null values, so scrub them.
	value = (value===null) ? '' : value;

	let o: KeyValuePair[] = options;
	if(includeAny && o[0].key !== "ANY") o.unshift({key:"ANY", value:"Any"});

	return (
		<Select
			labelId={name+"-label"}
			label=''
			name={name}
			variant="outlined"
			sx={sx}
			fullWidth={true}
			renderValue={(selected) => {

				// Selected is provided as the key string in a KeyValuePair. The first comparison doens't always work because ... why? I have no idea...

				if (selected === 'ANY' || selected === "" || selected === undefined || (Array.isArray(selected) && selected.length === 0)) {
					return <span>Select {label}</span>;
				}
				else {
					return o.find((item) => item.key === selected)?.value
				}
			}}
			value={value}
			onChange={onChange}
		>

			<MenuItem disabled value="">
				<span>Select {label}</span>
			</MenuItem>

			{o.map((item, index) => 
				<MenuItem value={item.key} key={index}>{item.value}</MenuItem>
			)}
		</Select>
	);

}