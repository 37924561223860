import React from 'react';
import { List, ListItem, SxProps, Typography } from '@mui/material';
import { PageContent } from '../../components/PageContent/PageContent';
import { Box } from '@mui/material';

const drinkMenuStyle: SxProps = {
	'& > *': {},
	'& > * > h3': {},
	'& > * > p': {}
};

type Ingredient = {
	type: string;
	amount: string;
}

type Drink = {
	name: string;
	ingredients: Ingredient[];
}

const drinks: Drink[] = [
	{
		name: 'Kenilworth Manhattan',
		ingredients: [
			{ type: 'Carpano Antiqua Vermouth', amount: '2oz' },
			{ type: 'Bulleit Bourbon', amount: '1oz' },
			{ type: 'Bitters (type?)', amount: '3 dashes' },
			{ type: 'Maraschino Cherry', amount: '2 cherries' }
		]
	},
	{
		name: 'Sazerac',
		ingredients: []
	},
	{
		name: 'Two Buck Chuck',
		ingredients: [
			{ type: 'Wine', amount: '6oz' }
		]
	}
];

export const DrinkMenu: React.FC = () => {
	return (
		<PageContent title='Drink Menu'>
			<List sx={{drinkMenuStyle}}>
				{drinks.map((drink, index) => (
					<ListItem key={index}>
						<Typography variant='h3'>{drink.name}</Typography>
						<Box>
							{drink.ingredients.map((ingredient, index2) => (
								<Typography variant='body1' key={index2}>{ingredient.type} ({ingredient.amount})</Typography>
							))}
						</Box>
					</ListItem>
				))}
			</List>
		</PageContent>
	);
}