import React from 'react';
import { UserGroupModel } from '../../models/UserGroupModel';
import { UserModel } from '../../models/UserModel';
import { Avatar, Box, Card, Chip } from '@mui/material';

interface Props {
    homeProfile: UserGroupModel,
    userProfile: UserModel,
    simple?: boolean
}

export const HouseholdMembers: React.FC<Props> = ({ homeProfile, userProfile, simple=false }) => {

    if(simple) return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        {homeProfile?.memberData && homeProfile?.memberData.map((homeMember:UserModel, index: number)=>(
            <Avatar key={index} alt={homeMember.firstName} src={homeMember.avatar} sx={{width: 36, height: 36, border: '1px solid #fff5', color: '#fff', boxShadow: '0 0 10px #0004'}} />
        ))}
        </Box>
    )

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, mt:3 }}>
            {homeProfile?.memberData && homeProfile?.memberData.map((homeMember:UserModel, index: number)=>(
                <Card
                    sx={{
                        p:3,
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        maxWidth: '150px',
                        bgcolor: '#eee',
                        border: (homeMember.id === userProfile.id) ? '1px solid green' : 0
                    }}
                    key={index}
                    elevation={1}
                >
                    <Avatar alt={homeMember.firstName} src={homeMember.avatar} sx={{width: 50, height: 50, mb:2}} />
                    {homeMember.firstName}
                    {(homeMember.id === userProfile.id) && <Chip label="You" />}
                </Card>
            ))}
        </Box>
    )
}