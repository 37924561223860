import { useContext, createContext } from "react";

interface DialogContextProps {
	openDialog: (component: React.FC<any>, props?: Record<string, any>) => void;
	closeDialog: () => void
	refetch?: () => void
}

export const DialogContext = createContext<DialogContextProps | undefined>(undefined);

export const useDialog = () => {
	/* 
	 * Here, TypeScript doesn't properly infer that the context will always be defined
	 * at the point where it is returned. To help TypeScript understand this, you can use
	 * a non-null assertion operator (!) to assert that the value is non-null.
	 */

	const context = useContext(DialogContext)!;
	return context;
};