import {
	// PreloadedState, // Removed this because it wasn't being used and type definition was too odd
	configureStore
} from '@reduxjs/toolkit';

import { guestBookApi } from '../slices/guestBookApi';
import { restaurantsApi } from '../slices/restaurantsApi';
import { recipesApi } from '../slices/recipesApi';
import { mealsApi } from '../slices/mealsApi';
import { projectsApi } from '../slices/projectsApi';
import { providersApi } from '../slices/providersApi';
import { subscriptionsApi } from '../slices/subscriptionsApi';
import { userApi } from '../slices/userSlice';
import { homeApi } from '../slices/homeSlice';

import manualsReducer from '../slices/manualsSlice';
import maintenanceReducer from '../slices/maintenanceSlice';
import swatchesReducer from '../slices/swatchesSlice';
import authReducer from '../slices/authSlice';

/**
 * 
 * Generate Store
 * 
 */

const unCombinedReducers = {
		auth: authReducer,
		[userApi.reducerPath]: userApi.reducer,
		[homeApi.reducerPath]: homeApi.reducer,
		[guestBookApi.reducerPath]: guestBookApi.reducer,
		[mealsApi.reducerPath]: mealsApi.reducer,
		[restaurantsApi.reducerPath]: restaurantsApi.reducer,
		[recipesApi.reducerPath]: recipesApi.reducer,
		[projectsApi.reducerPath]: projectsApi.reducer,
		[providersApi.reducerPath]: providersApi.reducer,
		[subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
		manuals: manualsReducer,
		maintenance: maintenanceReducer,
		swatches: swatchesReducer,
	};

/**
 * 
 * Export for Testing
 * 
 */

export const setupStore = () => {
	const store = configureStore({
		reducer: unCombinedReducers,
		// preloadedState,
		middleware: (gDM) =>
			gDM().concat(
				userApi.middleware,
				homeApi.middleware,
				guestBookApi.middleware,
				restaurantsApi.middleware,
				recipesApi.middleware,
				mealsApi.middleware,
				projectsApi.middleware,
				providersApi.middleware,
				subscriptionsApi.middleware
			)
	});

	return store;
};

/**
 * 
 * Export Store
 * 
 */

export const store = setupStore();

/**
 * 
 * Export Store Types
 * 
 */

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;