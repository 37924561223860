import React, { useEffect, useRef, useState } from 'react';
import { PageContent } from '../../components/PageContent/PageContent';
import { HomeWidget } from './HomeWidget';
import { Box } from '@mui/system';
import { KChart } from '../../components/KChart/KChart';
import useResponsiveWidth from '../../utilities/useResponsiveWidth';
import { useKProfile } from '../../app/KProfileProvider';
import { HouseholdMembers } from '../../components/HouseholdMembers/HouseholdMembers';
import homePhoto from '../../assets/placeholders/homephoto.jpg';
import { paletteColors } from '../../theme/theme';
import { useGetAllQuery as useGetAllProjectsQuery } from '../../slices/projectsApi';
import { Project } from '../../models/ProjectModel';
import { Button, Link, Skeleton, SxProps, Theme, useMediaQuery } from '@mui/material';
import { KIcon } from '../../components/KIcon/KIcon';
import { KChartStackedBar } from '../../components/KChartStackedBar/KChartStackedBar';
import axios from 'axios';

const DashboardSection: React.FC<{title: string}> = ({ title }) => {
	return (
		<Box sx={{ mt: 2, background: `linear-gradient(to bottom, ${paletteColors.blue.xxdark}, ${paletteColors.blue.xdark})`, color: paletteColors.blue.light, p:1.25, fontSize: '24px', borderRadius: '4px' }}>{title}</Box>
	)
}

export const Home: React.FC = () => {

	const availableWidthRef_3column = useRef(null);
	const availableWidth_3column = useResponsiveWidth(availableWidthRef_3column);

	const isSmallScreen = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm')
	);

	const [totalMeals, setTotalMeals] = useState<any>(null);
	const [totalLeftovers, setTotalLeftovers] = useState<any>(null);
	const [recipesVsRestaurants, setRecipesVsRestaurants] = useState<any>(null);
	useEffect(() => {
		const fetchTotalMeals = async () => {
			try {
			  // Await the axios call to resolve
			  const response = await axios.get('https://db.ericville.app/food/meals/analytics/total-meals/?startDate=2023-07-01&endDate=2023-10-31');
			  
			  // Try parsing the response data to JSON
			  const data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
			  
			    // Convert hash to array format that D3 can understand
				const formattedData = Object.entries(data).map(([key, value]) => {
					return { dateRange: key, value };
				});

			  // Set the data to the state
			  setTotalMeals(formattedData);
			} catch (error) {
			  // If there's an error, log it and set totalMeals to null or handle it as needed
			  console.error('Error fetching data:', error);
			  setTotalMeals(null);
			}
		};

		const fetchRecipesVsRestaurants = async () => {
			try {
			  // Await the axios call to resolve
			  const response = await axios.get('https://db.ericville.app/food/meals/analytics/recipe-vs-restaurant/?startDate=2023-07-01&endDate=2023-10-31');
			  
			  // Try parsing the response data to JSON
			  const data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;

			  // Set the data to the state
			  setRecipesVsRestaurants(data);
			} catch (error) {
			  // If there's an error, log it and set totalMeals to null or handle it as needed
			  console.error('Error fetching data:', error);
			  setRecipesVsRestaurants(null);
			}
		};

		const fetchTotalLeftovers = async () => {
			try {
			  // Await the axios call to resolve
			  const response = await axios.get('https://db.ericville.app/food/meals/analytics/leftovers/?startDate=2023-07-01&endDate=2023-10-31');
			  
			  // Try parsing the response data to JSON
			  const data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
			  
			    // Convert hash to array format that D3 can understand
				const formattedData = Object.entries(data).map(([key, value]) => {
					return { dateRange: key, value };
				});

			  // Set the data to the state
			  setTotalLeftovers(formattedData);
			} catch (error) {
			  // If there's an error, log it and set totalMeals to null or handle it as needed
			  console.error('Error fetching data:', error);
			  setTotalLeftovers(null);
			}
		};

		fetchTotalMeals();
		fetchRecipesVsRestaurants();
		fetchTotalLeftovers();
	},[]);
	console.log(totalMeals);
	
	// Active Projects
	// TO DO: Move Filtering to API Repo
	const { data: projects = [], loading: projectsLoading } = useGetAllProjectsQuery<{ data:Project[], loading:boolean }>('');
	const inProgressProjects: Project[] = (projects as Project[]).filter((item: Project) => item.status==='IN_PROGRESS');

	const { userProfile, homeProfile } = useKProfile();

	return (
		<PageContent
			title={homeProfile && homeProfile.name || 'Yay! Glad You\'re Here'}
			isLoading={false}
			plain={!isSmallScreen}
			sx={{
				'&:after': {
					content: "''",
					backgroundImage: `url(${homePhoto})`,
					filter: 'blur(6px) sepia(0.30)',
					backgroundSize: 'cover',
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					zIndex: -1,
				},
				position: 'relative',
				width: '100%',
				maxWidth: {
					xs: 'unset'
				},
				pb: 5,
				mb: 0,
				'& *': {
					
				}
			}}
		>
			
			{!isSmallScreen && <Box sx={{
				gap: 2,
				display: 'flex',
				flexDirection: 'column',
				margin: '0 auto',
				maxWidth: {
					sm: '900px'
				}
			}}>
				{homeProfile &&
				<Box sx={{
					display: 'flex',
					mt: 2,
					cursor: 'default',
					boxShadow: 'none',
					background: 'transparent',
					'& .MuiCardContent-root': {
						display: 'flex',
						justifyContent: 'right',
						// alignItems: 'end'
					}
				}}>
					<Box sx={{
						fontSize: '2rem',
						fontFamily: 'Georgia',
						textTransform: 'none',
						color: 'white',
						fontWeight: 'normal',
						opacity: 0.5,
						flexGrow: 1,
						textShadow: '0 0 15px #000c'
					}}>{homeProfile.name}</Box>
					<HouseholdMembers simple userProfile={userProfile} homeProfile={homeProfile} />
				</Box>
				}

				<DashboardSection title="Food" />
				<Box sx={{
					display: 'flex',
					gap: 2,
					'& > *': {
						flexGrow: 1,
						flexShrink: 1
					}
				}}>
					<HomeWidget title='Meal Tracking Activity'>
						<Box ref={availableWidthRef_3column}>
							<KChart type="line" data={totalMeals} width={availableWidth_3column} height={200} />
						</Box>
					</HomeWidget>
					<HomeWidget title='Recipes vs Resturants'>
						<Box>
							<KChartStackedBar data={recipesVsRestaurants} width={availableWidth_3column} height={200} />
						</Box>
					</HomeWidget>
					<HomeWidget title='Leftovers'>
						<KChart type="bar" data={totalLeftovers} width={availableWidth_3column} height={200} />
					</HomeWidget>
					{/* <HomeWidget title='Top Restaurants and Recipes'>
						<PlaceholderEmptyListWidget />
					</HomeWidget> */}
				</Box>

				<DashboardSection title="Dolla Dolla Bills" />
				<Box sx={{ display: 'flex', gap: 2 }}>

					<HomeWidget title='Bills This Month'>
						{/* Where billingRepeat = Monthly
						OR Where billingDate = Yearly && billingDate is in this month
						OR Where billingDate = other && billingDate is in this month */}
						<PlaceholderEmptyListWidget />
					</HomeWidget>

					<HomeWidget title='Active & Upcoming Projects'>
						<Box>
							{projectsLoading && <PlaceholderEmptyListWidget />}
							{inProgressProjects.map((item, index)=>
								<ListItem primary={item.name} key={index} />
							)}
						</Box>
					</HomeWidget>

					<HomeWidget title='Spending Goals this Month'>
						<ListItem primary="Starbucks Spend" secondary="< $20" />
						<ListItem primary="Savings" secondary="> $2000" />
						<ListItem primary="Menards" secondary="$0" />
					</HomeWidget>

					<HomeWidget title='Bookmarks'>
						<Box>
							<ListItem primary="House projects" href="https://docs.google.com/spreadsheets/d/10iOTTS0vkcK_aDRRLdVgeAZgM-7xKvIhIw-8B88wt8M/edit?usp=drive_link" />
							<ListItem primary="Monthly Budget" href="https://docs.google.com/spreadsheets/d/1jFgTpKAvSNp_oF8M_DbdaG7hOAf_8p6oqJS5FNEwj5k/edit?usp=drive_link" />
							<ListItem primary="Travel Rewards" href="https://docs.google.com/spreadsheets/d/1r-YVdiGMKzzIpDmCZmLHqSAlI5bTBiWazkD01OlBdUY/edit#gid=0" />
						</Box>
					</HomeWidget>
				</Box>
			</Box>}

			{isSmallScreen && (
			<Box sx={{
				display: 'block',
				flexDirection: 'column',
				height: '100vh',
				padding: 2,
				backgroundColor: paletteColors.blue.xxdark,
				'& > *': {
					flexGrow: 1,
					mb: 2
				}
			}}>
				<HomeWidget title='Meal Tracking'>
					<Box sx={{
						display: 'flex',
						gap: 2,
						mx: 1,
						'& > *': {
							display: 'flex',
							flexGrow: 1,
							textTransform: 'none',
							p: 3
						}
					}}>
					<Button variant="contained">Log Today's Lunch</Button>
					<Button variant="contained">Log Today's Dinner</Button>
					</Box>
				</HomeWidget>
				<HomeWidget title='Bookmarks'>
					<Box>
						<ListItem primary="Monthly Budget" href="https://docs.google.com/spreadsheets/d/1jFgTpKAvSNp_oF8M_DbdaG7hOAf_8p6oqJS5FNEwj5k/edit?usp=drive_link" />
						<ListItem primary="House projects" href="https://docs.google.com/spreadsheets/d/10iOTTS0vkcK_aDRRLdVgeAZgM-7xKvIhIw-8B88wt8M/edit?usp=drive_link" />
					</Box>
				</HomeWidget>
			</Box>
			)}
		</PageContent>
	);
}

const PlaceholderEmptyListWidget: React.FC = () => {
	return (
		<>
		<ListItem
			primary={<Skeleton variant="text" width="100px" sx={{ fontSize: '1rem' }} />}
			secondary={<Skeleton variant="text" width="20px" sx={{ fontSize: '1rem' }} />}
		/>
		<ListItem
			primary={<Skeleton variant="text" width="100px" sx={{ fontSize: '1rem' }} />}
			secondary={<Skeleton variant="text" width="20px" sx={{ fontSize: '1rem' }} />}
		/>
		<ListItem
			primary={<Skeleton variant="text" width="100px" sx={{ fontSize: '1rem' }} />}
			secondary={<Skeleton variant="text" width="20px" sx={{ fontSize: '1rem' }} />}
		/>
		<ListItem
			primary={<Skeleton variant="text" width="100px" sx={{ fontSize: '1rem' }} />}
			secondary={<Skeleton variant="text" width="20px" sx={{ fontSize: '1rem' }} />}
		/>
		<ListItem
			primary={<Skeleton variant="text" width="100px" sx={{ fontSize: '1rem' }} />}
			secondary={<Skeleton variant="text" width="20px" sx={{ fontSize: '1rem' }} />}
		/>
		</>
	)
}

const ListItem: React.FC<{primary:string|React.ReactNode, secondary?:string|React.ReactNode, href?:string, target?:string}> = ({primary, secondary, href=undefined, target="_blank"}) => {
	const containerStyle: SxProps = {
		display: 'flex',
		mb:1,
		py:1,
		px:1,
		'&:nth-of-type(even)': { background:'#f3f3f3', borderRadius:'3px' },
		'&:last-of-type':{borderBottom:0}
	};

	let icon = undefined;

	if(href && href.indexOf('/spreadsheets/') !== -1) icon = <KIcon name="googleSheet" />;
	else if(href && href.indexOf('/document/') !== -1) icon = <KIcon name="googleDoc" />;

	if(href) return (
		<Link sx={containerStyle} href={href} target={target}>
			<Box sx={{flexGrow:1, display:'flex', gap: 1, color: 'blue', textDecoration: 'underline'}}>{icon && icon}{primary}</Box>
			{secondary && <Box sx={{opacity:0.5}}>{secondary}</Box>}
		</Link>
	)

	return (
		<Box sx={containerStyle}>
			<Box sx={{flexGrow:1}}>{primary}</Box>
			{secondary && <Box sx={{opacity:0.5}}>{secondary}</Box>}
		</Box>
	)
}