import React from 'react';

import { Switch, FormControlLabel } from '@mui/material';

interface Props {
	label?: string;
	name: string;
	checked: boolean;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export const KToggle: React.FC<Props> = (
	{
		name,
		label='No Label',
		checked,
		onChange
	}
) => {

	return (
		<FormControlLabel
			control={
				<Switch checked={checked} name={name} onChange={onChange} />
			}
			label={label}
			labelPlacement="start"
			sx={{whiteSpace: 'nowrap'}}
		/>
	);
}