import type { BaseModel } from './macro/BaseModel';
import type { Link } from './micro/Link';
import { RestaurantCategory } from './micro/RestaurantCategory';
import { UserGroupOwnership } from './micro/UserGroupOwnership';
import { Business } from './micro/Business';

type RestaurantDetails = {
	type: 'recipe' | 'restaurant',
	category?: RestaurantCategory,
	isChain: boolean,
	linkWeb?: Link,
	linkUberEats?: Link,
	linkYelp?: Link,
	linkDoorDash?: Link,
	linkGrubHub?: Link
};

export type Restaurant = BaseModel & Business & UserGroupOwnership & RestaurantDetails;

/**
 * 
 * NEW BLANK OBJECT
 * 
 */

export const newRestaurant: Restaurant = {
	id: '',
	type: 'restaurant',
	name: '',
	isChain: false,
	userGroupOwnershipId: ''
};

// export const testRestaurants: Restaurant[] = [
// 	{
// 		"id": "653ed9b24c64484c964899c5",
// 		"name": "Crumbl Cookies",
// 		"notes": null,
// 		"type": "restaurant",
// 		"userGroupOwnershipId": "",
// 		"isChain": false,
// 		"category": null,
// 		"address": null,
// 		"phone": null,
// 		"hours": null,
// 		"linkYelp": null,
// 		"linkUberEats": null,
// 		"linkDoorDash": null,
// 		"linkWeb": null,
// 		"linkGrubHub": null
// 	},
// 	{
// 		"id": "653ed9564c64484c964899be",
// 		"name": "Standard Market",
// 		"notes": null,
// 		"type": "restaurant",
// 		"userGroupOwnershipId": "",
// 		"isChain": false,
// 		"category": "AMERICAN",
// 		"address": null,
// 		"phone": null,
// 		"hours": null,
// 		"linkYelp": null,
// 		"linkUberEats": null,
// 		"linkDoorDash": null,
// 		"linkWeb": null,
// 		"linkGrubHub": null
// 	}
// ]