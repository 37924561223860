import React, { ReactElement } from 'react';

import { Box, ListItemText, Stack, IconButton, ListItemButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CategoryIcon from '@mui/icons-material/Category';

import { paletteColors } from '../../theme/theme';
import { KListItemType } from './KListItemType';
import { KListItemThumbnail } from '../KListItemThumbnail/KListItemThumbnail';

interface Props {
	// Text Customization
	primary: string;
	secondary?: string;

	// Display
	iconColor?: string;
	iconSymbol?: any;
	type?: KListItemType;

	// Actions
	actionItems?: ReactElement[];
	primaryAction?: ()=>any;
};

export const KListItem: React.FC<Props & (React.ComponentPropsWithRef<typeof Box> |  & React.ComponentPropsWithRef<typeof ListItemButton>)> = ({
	primary,
	secondary,
	iconColor = paletteColors.blue.light,
	iconSymbol,
	actionItems=[],
	type='list',
	primaryAction,
	...props
}) => {

	/**
	 * 
	 * Type: Card
	 * 
	 */

	if(type === 'card') {
		return (
			<Box
				data-testid='card-view'
				sx={{
					display: 'flex',
					cursor: 'pointer',
					p: '0.5rem',
					m: 0,
					flexGrow: 1,
					bgcolor: 'transparent',
					border: 'none',
					color: 'white',
					fontFamily: 'inherit',
					alignContent: 'left'
				}}
				{...props}
			>
					{primary}
			</Box>
		);
	}

	/**
	 * 
	 * Type: List
	 * 
	 */

	else return (
		<ListItemButton
			data-testid='list-view'
			onClick={primaryAction}
			sx={{
				cursor: 'pointer',
				alignItems: 'center',
				justifyContent: 'start',
				padding: '0.5rem 1rem',
				gap: 1,
				backgroundColor: 'transparent',
				borderTop: '1px solid #eee',
				'&:first-of-type': {
					borderTop: '1px solid #fff'
				},
				'&:focus, &:focus-within': {
					backgroundColor: 'transparent'
				},
				'&:hover': {
					backgroundColor: paletteColors.blue.xxlight,
					'& + *': {
						borderTop: '1px solid #fff'
					},
					'& .MuiListItemText-primary': {
						textDecoration: 'underline',
						color: paletteColors.blue.xxdark
					},
					'& .MuiAvatar-root': {
						boxShadow: '0 0 10px '+paletteColors.blue.main+'44'
					}
				}
			}}
		>
			<KListItemThumbnail thumbnail={iconSymbol || <CategoryIcon />} color={iconColor} />

			<ListItemText
				primary={primary}
				secondary={secondary}
				primaryTypographyProps={{
					fontWeight: '500',
					fontSize: '1.1rem',
					color: paletteColors.blue.main
				}}
				secondaryTypographyProps={{}}
				sx={{
					flexBasis: '100%',
					flexGrow: 1,
					justifyContent: 'center',
					display: 'flex',
					flexDirection: 'column',
					gap: 0.5,
					'& > p:empty': {
						display: 'none'
					}
				}}
			/>
		
			<Stack sx={{
				display: {
					xs: 'none',
					sm: 'none',
					md: 'flex',
					lg: 'flex'
				},
				flexFlow: 'row',
				gap: 0.5
			}}>
				{actionItems.map((item: any, index: number) => (
					<Box key={index}>{item}</Box>
				))}
			</Stack>

			{/* Edit Action */}

			{primaryAction &&
				<Box sx={{ 
					display: {
						xs: 'none',
						md: 'block'
					}
				}}>
					<IconButton aria-label={`Edit ${primary}`} onClick={primaryAction}>
						<EditIcon />
					</IconButton>
				</Box>
			}
		</ListItemButton>
	);
}