import { Box } from '@mui/material';
import React from 'react';

export const KIcon: React.FC<{name: string, sx?: any}> = ({ name, sx={} }) => {
    
    let svgCode: React.ReactNode = undefined;

    switch(name) {
        case "googleDoc":
            svgCode = (<path fill="#5284EC" d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-5 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"/>);
            break;
        case "googleSheet":
            svgCode = (<path fill="#479B5F" d="M3 0C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H21C22.6569 24 24 22.6569 24 21V3C24 1.34315 22.6569 0 21 0H3ZM7.5 3H10.5V7.5H21V10.5H10.5V21H7.5V10.5H3V7.5H7.5V3Z"/>);
            break;
        default:
            svgCode = (<path d=""/>);
            break;
    }

    // Does it need a height and width attribute?

    return (
        <Box sx={{
            ...sx,
            '& > svg': {
                fill: 'var(--e23-palette-text-icon)'
            },
            aspectRatio: '1 / 1',
            width: '1.2rem',
            opacity: 0.8,
            fontSize: '0.7em',
            position: 'relative',
            top: '3px',
        }}>
            <svg viewBox="0 0 24 24">
                {svgCode}
            </svg>
        </Box>
    );
}
