import type { BaseModel } from "./macro/BaseModel";
import { Business } from "./micro/Business";
import type { Link } from "./micro/Link";
import { UserGroupOwnership } from "./micro/UserGroupOwnership";

type ProviderDetails = {
	contact: string,
	linkGoogle: Link,
	linkYelp: Link,
	linkWeb: Link
};

export type Provider = BaseModel & UserGroupOwnership & Business & ProviderDetails;

/**
 * 
 * NEW BLANK OBJECT
 * 
 */

export const newProvider: Provider = {
	id: '',
	name: '',
	userGroupOwnershipId: '',
	contact: '',
	linkGoogle: '',
	linkYelp: '',
	linkWeb: ''
}

// export const testProviders: Provider[] = [
// 	{
// 		"id": "1",
// 		"name": "Dr Heller",
// 		"notes": "Front 105\nOffice 1506",
// 		"userGroupOwnershipId": "g1",
// 		"dateModified": null,
// 		"dateCreated": "2023-04-20T15:07:26.13",
// 		"createdBy": null,
// 		"modifiedBy": null,
// 		"contact": "",
// 		"linkGoogle": "",
// 		"linkYelp": "",
// 		"linkWeb": "",
// 		"address": null,
// 		"phone": "",
// 		"hours": ""
// 	},
// 	{
// 		"id": "2",
// 		"name": "AAA",
// 		"notes": "sbbecket@acg.aaa.com",
// 		"userGroupOwnershipId": "g2",
// 		"dateModified": null,
// 		"dateCreated": "2023-04-19T15:47:00.665",
// 		"createdBy": null,
// 		"modifiedBy": null,
// 		"contact": "Sue Becket (Member Representative)",
// 		"linkGoogle": "",
// 		"linkYelp": "",
// 		"linkWeb": "",
// 		"address": null,
// 		"phone": "630-588-7060 ext. 209",
// 		"hours": ""
// 	}
// ]