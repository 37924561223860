// import { paletteColors } from '../../theme/theme';

/**
 * 
 * Style Settings
 * 
 */

// const CELL_HEIGHT = '300px';
const BORDER_RADIUS: string = '4px';

/**
 * 
 * Base Styles
 * 
 */



/**
 * 
 * Day Label
 * 
 */

export const dayLabel = {
	fontSize: '1.0em',
	color: '#999'
};

export const dayLabelEm = {
	...dayLabel,
	fontSize: '1.2em',
	fontWeight: 600,
	color: '#000'
};

// export const trHeader = {
// 	...trBase,
// 	minHeight: 'none'
// };

// export const tdHeader = {
// 	...tdBase,
// 	backgroundColor: '#333',
// 	color: '#fff',
// 	padding: '1rem'
// };

/**
 * 
 * Placeholder
 * 
 */

// export const tdPlaceholderContainer = {
// 	...tdBase,
// 	backgroundColor: 'transparent'
// };

export const buttonPlaceholder = { 
	width:'100%',
	height: '100%',
	fontSize: '3rem',
	backgroundColor: '#eee',
	color: '#fff',
	borderRadius: BORDER_RADIUS,
	mt: 1,
	'&:hover': {
		backgroundColor: '#ddd'
	}
};

/**
 * 
 * Meal
 * 
 */

export const mealTable = {
	display: 'grid',
	padding: '0.5rem 2rem',
	gap: '0.5rem',
	gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', //'auto',
	gridTemplateRows: '80px auto auto',
	gridAutoFlow: 'column',
	gridAutoColumns: 'minmax(0, 1fr)',
	width: '100%',
	height: '90%',
	// '& > *:not(:nth-of-type(2)):not(:nth-of-type(3)) > *:first-of-type > *:first-of-type': {
	// 	visibility: 'hidden'
	// },
};

export const mealSlotHeader = {
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	alignContents: 'center',
	color: '#aaa',
	fontWeight: 'bold',
	p: 1,
	'& button': {
		color: '#aaa'
	}
};

// export const tdMealsContainer = {
// 	...tdBase,
// 	display: 'flex',
// 	flexDirection: 'column',

// 	'& > *': {
// 		display: 'flex',
// 		flexDirection: 'column',
// 		backgroundColor: paletteColors.blue.dark,
// 		color: paletteColors.cream,
// 		borderRadius: BORDER_RADIUS,
// 		padding: '0.5rem 1rem 1rem 1rem',
// 		minHeight: '120px',
// 		'&:hover': {
// 			backgroundColor: paletteColors.blue.xdark,
// 			cursor: 'pointer'
// 		}
// 	},

// 	'& > *:first-of-type': {
// 		'&:not(:last-child)': {
// 		  borderBottomLeftRadius: 0,
// 		  borderBottomRightRadius: 0,
// 		},
// 	  },
	
// 	  '& > *:last-child': {
// 		'&:not(:first-of-type)': {
// 		  borderTopLeftRadius: 0,
// 		  borderTopRightRadius: 0,
// 		  borderTop: '2px dotted '+paletteColors.blue.light+'66'
// 		},
// 	  },
	
// 	  '& > *:not(:first-of-type):not(:last-child)': {
// 		borderRadius: 0,
// 		borderTop: '2px dotted '+paletteColors.blue.light+'66'
// 	  },

// 	'& > * > *': {
// 		padding: 0,
// 		width: '100%'
// 	}
// };