import React, { ReactNode } from 'react';

import { TextField, InputAdornment, BaseTextFieldProps } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

interface Props {
	name?: string;
	label?: string;
	value: string | number | undefined;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	sx?: object;
	variant?: 'filled' | 'outlined' | 'standard';
	autoFocus?: boolean;
}

export const KSearch: React.FC<Props> = (
	{
		name='Search',
		label='Search',
		value,
		onChange,
		sx={},
		variant='outlined',
		autoFocus=false
	}
) => {

	// React inputs don't like null values, so scrub them.
	value = (value===null) ? '' : value;

	let startAdornmentValue: ReactNode = (<InputAdornment position="start"><SearchIcon /></InputAdornment>);
	let endAdornmentValue: ReactNode = (<InputAdornment position="end"></InputAdornment>);
	let InputOptions: BaseTextFieldProps = {
		rows: 1,
		multiline: false,
		variant: variant,
		fullWidth: true
	};

	return (
		<TextField
			{...InputOptions}
			autoComplete="none"
			autoFocus={autoFocus}
			name={name}
			label={label}
			value={value}
			onChange={onChange}
			margin="normal"
			sx={sx}
			InputProps={{
				startAdornment: startAdornmentValue,
				endAdornment: endAdornmentValue
			}}
		/>
	);
}