import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { requestJWT } from '../utilities/requestJWT';

export const thunkRequestJWT = createAsyncThunk('auth/requestJWT', async (auth0UserId: string) => {
	const jwt = await requestJWT(auth0UserId);
	return jwt;
  });

export type AuthState = {
	jwt: string | null;
  }
  
const initialState: AuthState = {
	jwt: localStorage.getItem('jwt'),
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuthState: (state, action: PayloadAction<AuthState>) => {
			state.jwt = action.payload.jwt;
	
			localStorage.setItem('jwt', action.payload.jwt || '');
		},
		removeAuthState: (state) => {
			state.jwt = null;
			localStorage.removeItem('jwt');
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(thunkRequestJWT.fulfilled, (state, action) => {
				state.jwt = action.payload;
				localStorage.setItem('jwt', action.payload);
			})
			.addCase(thunkRequestJWT.rejected, (_state, action) => {
				console.error('Failed to fetch JWT', action.error);
			});
	},
});

export const { setAuthState, removeAuthState } = authSlice.actions;

export default authSlice.reducer;