import React from 'react';

import { TextField, BaseTextFieldProps, Box } from '@mui/material';
import { KServiceIcon, ServiceProviders } from '../KServiceIcon/KServiceIcon';

interface Props {
	name?: string;
	label: string;
	service: ServiceProviders;
	value: string | number | undefined;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	sx?: object;
}

export const KLinkField: React.FC<Props> = (
	{
		name='',
		label,
		service,
		value,
		onChange,
		sx={}
	}
) => {

	// React inputs don't like null values, so scrub them.
	value = (value===null) ? '' : value;

	let InputOptions: BaseTextFieldProps = {
		rows: 1,
		multiline: false,
		variant: 'outlined',
		fullWidth: true
	};

	let serviceIcon = undefined;

	switch(service) {
		case "yelp":
			serviceIcon = <KServiceIcon service='yelp' />;
			break;
		case "ubereats":
			serviceIcon = <KServiceIcon service='ubereats' />;
			break;
		case "grubhub":
			serviceIcon = <KServiceIcon service='grubhub' />;
			break;
		case "pinterest":
			serviceIcon = <KServiceIcon service='pinterest' />;
			break;
		case "google":
			serviceIcon = <KServiceIcon service='google' />;
			break;
		case "doordash":
			serviceIcon = <KServiceIcon service='doordash' />;
			break;
		case "website":
		default:
			serviceIcon = <KServiceIcon service='website' />;
			break;
	}

	return (
		<Box sx={{display:'flex', alignItems: 'center', gap:2}}>
			{serviceIcon}
			<TextField
				{...InputOptions}
				name={name}
				label={label}
				value={value}
				onChange={onChange}
				margin='dense'
				sx={sx}
			/>
		</Box>
	);
}