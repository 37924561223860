import React from 'react';
import { Paper, Typography, Button, Theme } from '@mui/material';
import { paletteColors } from '../../theme/theme';
import { styled } from '@mui/material/styles';
import { GuestBookEntry } from '../../models/GuestBookModel';
import { useDeleteMutation } from '../../slices/guestBookApi';
import moment from 'moment';

interface Props {
	data: GuestBookEntry;
}

const Item = styled(Paper)(({ theme }: {theme: Theme}) => ({
	backgroundColor: 'transparent',
	...theme.typography.body1,
	padding: theme.spacing(4),
	marginTop: theme.spacing(4),
	color: paletteColors.black
}));

export const GuestBookItem: React.FC<Props> = ({ data }) => {
	
	if(!data) return (<>Error</>);
	
	const [deleteGuestBookEntry] = useDeleteMutation();
	const handleRemove = () => deleteGuestBookEntry(data);

	function getRandomRotate() {
		const min = -6;
		const max = 6;
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	return (
		<Item
			elevation={2}
			sx={{
				transform: `rotate(${getRandomRotate()}deg)`,
				aspectRatio: '3/2',
				borderRadius: 0,
				backgroundColor: '#ececcd',
				p: 5
				// borderLeft: '2px solid '+paletteColors.blue.light
			}}>
			<Typography variant="body1" sx={{fontSize:'1.3rem'}}>{data.note}</Typography>
			<Typography variant="h5" sx={{mt:'1rem'}}>{data.name}</Typography>
			<Typography variant="body2" sx={{mt:'1rem',color:paletteColors.blue.main,fontStyle:'italic'}}>{moment(data.dateLogged).format("MMM Do YYYY")}</Typography>

			<Button onClick={handleRemove} variant="text" sx={{color:paletteColors.red.main, display: 'none'}}>x</Button>
		</Item>
	);
}