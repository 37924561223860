import React, {ReactNode, useContext} from 'react';
import moment from 'moment';
import { Meal, newMeal } from '../../models/MealModel';
import { MealItem } from './MealItem';
import { Box, Typography, IconButton } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { mealSlotHeader, dayLabel, dayLabelEm } from './mealPlannerStyles';
import { LaunchMealSelectorFormContext } from './LaunchMealSelectorFormContext';
import { isToday } from './isToday';
import { paletteColors } from '../../theme/theme';

interface Props {
	date: string;
	meals: Meal[]
}

export const ViewDay: React.FC<Props> = ({ date, meals }) => {

	const isCurrentDay: boolean = isToday(date);

	return (
		<>
				<DayMealSlotsHeader date={date} isCurrentDay={isCurrentDay} />

				<MealSlot mealType='LUNCH' date={date}>
					{meals && <MealItem
						date={date}
						mealType='LUNCH'
						meals={meals.filter((meal) => meal.mealType === 'LUNCH')}
					/> }
				</MealSlot>

				<MealSlot mealType='DINNER' date={date}>
					{meals && <MealItem
						date={date}
						mealType='DINNER'
						meals={meals.filter((meal) => meal.mealType === 'DINNER')}
					/> }
				</MealSlot>
		</>
	)
}

const MealSlot: React.FC<{children:ReactNode, mealType:'LUNCH'|'DINNER', date:string}> = ({children, mealType, date}) => {
	
	const { launchMealSelectorForm } = useContext(LaunchMealSelectorFormContext);
	const newMealSlotMeal = {...newMeal, date: date, mealType: mealType};

	const handleMealCreate = (meal: Meal | undefined = newMealSlotMeal) => {
		launchMealSelectorForm(meal);
	};

	return (
		<Box sx={{
			background: 'linear-gradient(180deg, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0) 100%)',
			borderRadius: '4px'
		}}>
			<Box sx={mealSlotHeader}>
				<IconButton aria-label={`Add ${mealType} for ${date}`} onClick={()=>handleMealCreate()}>
					<Add />
				</IconButton>
			</Box>
			{children}
		</Box>
	);
}

const DayMealSlotsHeader: React.FC<{ date: string, isCurrentDay:boolean }> = ({date, isCurrentDay=false}) => {

	return (
		<Box sx={{
			borderRadius: '4px',
			padding: 1,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			minHeight: 'unset',
			position: 'relative',
			cursor: 'default',
			// ...(isPastDay && {}),
			...(isCurrentDay && {
				'&:before': {
					content: '""',
					position: 'absolute',
					bottom: 0,
					left: -1,
					width: '100%',
					height: '6px',
					borderRadius: '5px',
					backgroundColor: paletteColors.red.main
				},
				'& > *': {color: paletteColors.red.main}
			}),
		}}>
			<Typography sx={dayLabel}>
				{moment(date).utcOffset('+00:00').startOf('day').format('MMM D')}
			</Typography>
			<Typography sx={dayLabelEm}>
				{moment(date).utcOffset('+00:00').startOf('day').format('ddd')}
			</Typography>
		</Box>
	)
}