import React from 'react';
import { Box } from '@mui/material';

interface Props {
	children: React.ReactElement | React.ReactElement[];
}

export const FilterBar: React.FC<Props> = ({ children }) => {
	return (
		<Box sx={{
			display: 'flex',
			gap: 3,
			'& > div': {
				marginTop: 0,
				marginBottom: 0,
				width: '30%'
			}
		}}>
			{children}
		</Box>
	)
}