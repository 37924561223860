import React, {useEffect, useState} from 'react';

import { useAddMutation, useUpdateMutation, useDeleteMutation } from '../../slices/providersApi';
import { deletionHandler, inputChangeHandler, submitHandler } from '../../utilities/handleForm';

import { Provider } from '../../models/ProviderModel';

import { getFormVerb } from '../../utilities/getFormVerb';
import { KForm } from '../../components/KForm/KForm';
import { KTextField } from '../../components/KTextField/KTextField';
import { KLinkField } from '../../components/KLinkField/KLinkField';
import { KNotes } from '../../components/KNotes/KNotes';

import { DialogKTitle } from '../../components/DialogKTitle/DialogKTitle';
import { DialogKContent } from '../../components/DialogKContent/DialogKContent';
import { DialogKActions } from '../../components/DialogKActions/DialogKActions';

interface Props {
	initialData: Provider;
	closeDialog: ()=>void;
}

export const ProviderForm: React.FC<Props> = ({ closeDialog, initialData }) => {
	
	/**
	 * 
	 * API Controller
	 * 
	 */

	const [addProvider, { isLoading: _isAdding }] = useAddMutation();
	const [updateProvider, { isLoading: _isUpdating }] = useUpdateMutation();
	const [deleteProvider, { isLoading: _isDeleting }] = useDeleteMutation();

	/**
	 * 
	 * Form Controls
	 * 
	 */

	const [formLocalData, setFormLocalData] = useState<Provider>(initialData); 

	useEffect(() => {
		setFormLocalData(initialData);
	}, [initialData])

	const verb = getFormVerb(formLocalData);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => inputChangeHandler(e, {
		data: formLocalData,
		setData: setFormLocalData
	});

	const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => submitHandler(e, {
		data: formLocalData,
		add: addProvider,
		update: updateProvider,
		close: handleClose
	})

	const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => deletionHandler(e, {
		data: formLocalData,
		delete: deleteProvider,
		close: handleClose
	})

	const handleClose = () => closeDialog();

	/**
	 * 
	 * Render Component
	 * 
	 */

	return (
		<>
		<DialogKTitle>
			{verb+" Provider"}
		</DialogKTitle>
		
		<DialogKContent>

			<KForm>

				<KTextField
					type="name"
					name="name"
					value={formLocalData.name || ''}
					label="Provider Name"
					onChange={handleChange} />

				<KTextField
					type="contact"
					name="contact"
					value={formLocalData.contact || ''}
					label="Contact Name"
					onChange={handleChange} />

				<KTextField
					type="phone"
					name="phone"
					value={formLocalData.phone || ''}
					label="Phone"
					onChange={handleChange} />

				<KTextField
					type="hours"
					name="hours"
					value={formLocalData.hours || ''}
					label="Hours"
					onChange={handleChange} />

				<KLinkField
					service='google'
					label='Google'
					value={formLocalData.linkGoogle || ''}
					onChange={handleChange} />

				<KLinkField
					service='yelp'
					label='Yelp'
					value={formLocalData.linkYelp || ''}
					onChange={handleChange} />

				<KLinkField
					service='website'
					label='Website'
					value={formLocalData.linkWeb || ''}
					onChange={handleChange} />

				<KNotes
					value={formLocalData.notes || ''}
					onChange={handleChange} />
			</KForm>
		</DialogKContent>

		<DialogKActions
			handleSubmit={handleSubmit}
			handleDelete={handleDelete}
			handleClose={handleClose}
		/>

		</>
	);
}