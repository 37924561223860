import React, {useEffect, useState} from 'react';

import { useAddMutation, useUpdateMutation, useDeleteMutation } from '../../slices/recipesApi';
import { deletionHandler, inputChangeHandler, submitHandler } from '../../utilities/handleForm';

import { Recipe } from '../../models/RecipeModel';

import { getFormVerb } from '../../utilities/getFormVerb';
import { KForm } from '../../components/KForm/KForm';
import { KTextField } from '../../components/KTextField/KTextField';
import { KNotes } from '../../components/KNotes/KNotes';
import { KLinkField } from '../../components/KLinkField/KLinkField';

import { DialogKTitle } from '../../components/DialogKTitle/DialogKTitle';
import { DialogKContent } from '../../components/DialogKContent/DialogKContent';
import { DialogKActions } from '../../components/DialogKActions/DialogKActions';

interface Props {
	closeDialog: ()=>void;
	initialData: Recipe;
	refetch?: ()=>void;
};

export const RecipeForm: React.FC<Props> = ({ closeDialog, initialData, refetch }) => {

	/**
	 * 
	 * API Controller
	 * 
	 */

	const [addRecipe] = useAddMutation();
	const [updateRecipe] = useUpdateMutation();
	const [deleteRecipe] = useDeleteMutation();

	/**
	 * 
	 * Form Controls
	 * 
	 */

	const [formLocalData, setFormLocalData] = useState<Recipe>(initialData); 

	useEffect(() => {
		setFormLocalData(initialData);
	}, [initialData])

	const verb = getFormVerb(formLocalData);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => inputChangeHandler(e, {
		data: formLocalData,
		setData: setFormLocalData
	});

	const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => submitHandler(e, {
		data: formLocalData,
		add: addRecipe,
		update: updateRecipe,
		close: handleClose
	})

	const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => deletionHandler(e, {
		data: formLocalData,
		delete: deleteRecipe,
		close: handleClose
	})

	const handleClose = () => {
		if(refetch) refetch();
		closeDialog();
	}

	/**
	 * 
	 * Render Component
	 * 
	 */

	return (
		<>
		<DialogKTitle>
			{verb+" Recipe"}
		</DialogKTitle>
		
		<DialogKContent>
			<KForm>
				<KTextField
					autoFocus
					type="name"
					name="name"
					value={formLocalData.name || ''}
					label="Recipe Name"
					onChange={handleChange} />

				<KLinkField
					service='website'
					name="link"
					value={formLocalData.link || ''}
					label="Link"
					onChange={handleChange} />

				<KNotes
					value={formLocalData.notes || ''}
					onChange={handleChange} />
			</KForm>
		</DialogKContent>

		<DialogKActions
			handleSubmit={handleSubmit}
			handleDelete={handleDelete}
			handleClose={handleClose}
		/>

		</>
	);
}