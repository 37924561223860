import React from 'react';
import { ViewDay } from './ViewDay';
import { Box } from '@mui/material';
import { mealTable } from './mealPlannerStyles';
import { MealSlot } from '../../models/micro/MealSlot';

interface Props {
	mealSlots: MealSlot;
}

export const ViewWeek: React.FC<Props> = ({ mealSlots }) => {

	return (
		<Box sx={mealTable}>
			{Object.keys(mealSlots).map((keyName: string, index: number) => (
				<ViewDay
					key={index}
					date={keyName as string}
					meals={mealSlots[keyName]}
				/>
			))}
		</Box>
	);
}