import { KeyValuePair } from "./KeyValuePair";

export type SubscriptionCategory = 'UNCATEGORIZED' | 'ENTERTAINMENT' | 'UTILITIES' | 'VOCATIONAL' | 'ESSENTIALS' | 'HEALTH' | 'GROOMING';

export const SubscriptionCategoryMappings: KeyValuePair[] = [
	{key:"UNCATEGORIZED", value:"Uncategorized"},
	{key:"ENTERTAINMENT", value:"Entertainment"},
	{key:"UTILITIES", value:"Utilities"},
	{key:"VOCATIONAL", value:"Vocational"},
	{key:"ESSENTIALS", value:"Essentials"},
	{key:"HEALTH", value:"Health"},
	{key:"GROOMING", value:"Grooming"}
];