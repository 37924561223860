import React, { useEffect } from 'react';
import { List } from '@mui/material';
import { SubscriptionItem } from './SubscriptionItem';
import { SubscriptionForm } from './SubscriptionForm';
import { PageContent } from '../../components/PageContent/PageContent';
import { useGetAllQuery } from '../../slices/subscriptionsApi';
import { Subscription, newSubscription } from '../../models/SubscriptionModel';
import { KListSection } from '../../components/KListSection/KListSection';
import { useDialog } from '../../components/DialogProvider/DialogContext';

export const Subscriptions: React.FC = () => {

	/**
	 * 
	 * Initialize Data
	 * 
	 */

	const { data: unfilteredSubscriptions = [], isLoading, refetch } = useGetAllQuery<{data: Subscription[], isLoading: boolean, refetch: ()=>void}>('');

	const uncategorizedSubscriptions = (unfilteredSubscriptions as Subscription[]).filter((item: Subscription) => item.category==='UNCATEGORIZED');
	const entertainmentSubscriptions = (unfilteredSubscriptions as Subscription[]).filter((item: Subscription) => item.category==='ENTERTAINMENT');
	const utilitiesSubscriptions = (unfilteredSubscriptions as Subscription[]).filter((item: Subscription) => item.category==='UTILITIES');
	const vocationalSubscriptions = (unfilteredSubscriptions as Subscription[]).filter((item: Subscription) => item.category==='VOCATIONAL');
	const essentialsSubscriptions = (unfilteredSubscriptions as Subscription[]).filter((item: Subscription) => item.category==='ESSENTIALS');
	const healthSubscriptions = (unfilteredSubscriptions as Subscription[]).filter((item: Subscription) => item.category==='HEALTH');
	const groomingSubscriptions = (unfilteredSubscriptions as Subscription[]).filter((item: Subscription) => item.category==='GROOMING');

	/**
	 * 
	 * Form Interface
	 * 
	 */

	const { openDialog } = useDialog();

	const handleAdd = () =>
		openDialog( SubscriptionForm, { initialData: newSubscription } );

	const handleEdit = (data: Subscription) =>
		openDialog( SubscriptionForm, { initialData: data } );

	// Do we need this?
	useEffect(() => {
		refetch();
	}, [openDialog]);

	const getSubscriptionStats = (items: Subscription[]) => {

		let spendPerMonth = 0;
		let spendPerYear = 0;
		
		// Compute
		items.forEach(item => {
			if(item.billingCycle==='MONTHLY') {
				spendPerMonth += item.billingAmount;
				spendPerYear += (item.billingAmount * 12);
			}
			// else if(item.billingCycle==='WEEKLY') {
			// 	spendPerMonth += (item.billingAmount * 4);
			// 	spendPerYear += (item.billingAmount * 52)
			// }
			else if(item.billingCycle==='YEARLY') {
				spendPerYear += item.billingAmount;
			}
			else if(item.billingCycle==='QUARTERLY') {
				spendPerYear += (item.billingAmount * 4);
			}
			else if(item.billingCycle==='BI-YEARLY') {
				spendPerYear += (item.billingAmount * 2);
			}
			else {
				return;
			}
		});

		return [
			{
				label: 'Per Month',
				value: '$'+spendPerMonth.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
				details: 'Only includes Weekly and Monthly billing cycles.'
			},
			{
				label: 'Per Year',
				value: '$'+spendPerYear.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
				details: 'Includes the total of every bill.'
			}
		];
	}

	/**
	 * 
	 * Render Component
	 * 
	 */

	return (
		<PageContent
			title="Subscriptions"
			onAdd={handleAdd}
			isLoading={isLoading}
		>
			<List>
				<KListSection
					title="Entertainment" collapsible initiallyCollapsed stats={getSubscriptionStats(entertainmentSubscriptions)}>
					{entertainmentSubscriptions.map((item, index) => 
						<SubscriptionItem data={item} primaryAction={handleEdit} key={index} />
					)}
				</KListSection>

				<KListSection title="Utilities" collapsible initiallyCollapsed stats={getSubscriptionStats(utilitiesSubscriptions)}>
					{utilitiesSubscriptions.map((item, index) => 
						<SubscriptionItem data={item} primaryAction={handleEdit} key={index} />
					)}
				</KListSection>
				<KListSection title="Essentials" collapsible initiallyCollapsed stats={getSubscriptionStats(essentialsSubscriptions)}>
					{essentialsSubscriptions.map((item, index) => 
						<SubscriptionItem data={item} primaryAction={handleEdit} key={index} />
					)}
				</KListSection>

				<KListSection title="Vocational" collapsible initiallyCollapsed stats={getSubscriptionStats(vocationalSubscriptions)}>
					{vocationalSubscriptions.map((item, index) => 
						<SubscriptionItem data={item} primaryAction={handleEdit} key={index} />
					)}
				</KListSection>


				<KListSection title="Health & Grooming" collapsible initiallyCollapsed stats={getSubscriptionStats([...healthSubscriptions, ...groomingSubscriptions])}>
					{healthSubscriptions.map((item, index) => 
						<SubscriptionItem data={item} primaryAction={handleEdit} key={index} />
					)}
					{groomingSubscriptions.map((item, index) => 
						<SubscriptionItem data={item} primaryAction={handleEdit} key={index} />
					)}
				</KListSection>

				{(uncategorizedSubscriptions.length > 0) && 
					<KListSection title="Uncategorized" collapsible initiallyCollapsed stats={getSubscriptionStats(uncategorizedSubscriptions)}>
						{uncategorizedSubscriptions.map((item, index) => 
							<SubscriptionItem data={item} primaryAction={handleEdit} key={index} />
						)}
					</KListSection>
				}

			</List>

		</PageContent>
	);
}