import React, { useState, useEffect } from 'react';
import { List } from '@mui/material';
import { RecipeItem } from './RecipeItem';
import { RecipeForm } from './RecipeForm';
import { PageContent } from '../../components/PageContent/PageContent';
import { useGetAllQuery } from '../../slices/recipesApi';
import { newRecipe, Recipe } from '../../models/RecipeModel';
import { RecipeFilters, initialFilters } from './RecipeFilters';
import { useDialog } from '../../components/DialogProvider/DialogContext';
import { EmptyList } from '../../components/EmptyList/EmptyList';

export const Recipes: React.FC = () => {

	/**
	 * 
	 * Initialize Data
	 * 
	 */

	const { data: unfilteredRecipes = [], isLoading, refetch } = useGetAllQuery<{data: Recipe[], isLoading: boolean, refetch: ()=>void}>('');

	/**
	 * 
	 * Filters
	 * 
	 */

	const [filters, setFilters] = useState<typeof initialFilters>(initialFilters);

	const getItemsFiltered = () => {
		let x: Recipe[] = unfilteredRecipes;
		
		if(filters !== undefined) {
			// Filter: By Category
			if(filters.search !== '')
				x = x.filter(
					entity => (
						(entity.name.toLowerCase().indexOf(filters.search.toLowerCase()) != -1) ||
						(entity.notes && entity.notes.toLowerCase().indexOf(filters.search.toLowerCase()) != -1)
					)
				);
		}

		return x;
	};
	
	const recipes: Recipe[] = getItemsFiltered();

	/**
	 * 
	 * Form Interface
	 * 
	 */

	const { openDialog } = useDialog();

	const handleAdd = () =>
		openDialog(RecipeForm, { initialData: newRecipe });

	const handleEdit = (data: Recipe) =>
		openDialog(RecipeForm, { initialData: data });

	useEffect(() => {
		refetch();
	}, [openDialog]);

	/**
	 * 
	 * Render Component
	 * 
	 */

	return (
		<PageContent
			title="Recipes"
			onAdd={handleAdd}
			isLoading={isLoading}
			filters={
				<RecipeFilters filters={filters} setFilters={setFilters} />
			}
		>
		{recipes.length>0 && (
			<List>
				{(recipes as Recipe[]).map((item, index) => (
					<RecipeItem data={item} primaryAction={handleEdit} key={index} />
				))}
			</List>
		)}
		{recipes.length===0 && (
			<EmptyList />
		)}

		</PageContent>
	);
}