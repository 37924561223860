import React, { createContext, useContext, ReactElement, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { useDispatch } from 'react-redux';
import { AppDispatch } from './store';

import { useGetUserProfileByAuth0IdQuery, useAddUserMutation, useUpdateUserProfileMutation } from '../slices/userSlice';
import { UserModel, newUser } from '../models/UserModel';

import { useGetHomeProfileByAuth0IdQuery } from '../slices/homeSlice';
import { UserGroupModel, newUserGroup } from '../models/UserGroupModel';

import { unifyIndividual } from '../utilities/unifyIndividual';

/**
 * 
 * Context
 * 
 */

export const KProfileContext = createContext<
{
    userProfile: UserModel,
    homeProfile: UserGroupModel
}>({
    userProfile: newUser,
    homeProfile: newUserGroup
});

/**
 * 
 * Provider
 * 
 */

export const KProfileProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
    const dispatch = useDispatch<AppDispatch>();

    const { user: userAuth0Profile, isLoading: _userLoadingAuth0, isAuthenticated } = useAuth0();
    const { data: userProfile, loading: _userLoading, refetch: _refetchUserProfile } = useGetUserProfileByAuth0IdQuery<{data: UserModel, loading: boolean, refetch: ()=>void}>(userAuth0Profile?.sub || '');
    const { data: homeProfile, loading: _homeLoading, refetch: _refetchHomeProfile } = useGetHomeProfileByAuth0IdQuery<{data: UserGroupModel, loading: boolean, refetch: ()=>void}>(userAuth0Profile?.sub || '');

    const [updateUserProfile, { isLoading: _isUpdating }] = useUpdateUserProfileMutation();
    const [addUserProfile, { isLoading: _isAdding }] = useAddUserMutation();

    // Fold and Auth0 data into missing EricvilleAPI fields
    useEffect(()=>{
        if( userAuth0Profile && userProfile ) {
            unifyIndividual(dispatch, userAuth0Profile, userProfile, updateUserProfile, addUserProfile);
        }
    },[isAuthenticated, userAuth0Profile, userProfile]);

    return (
        <KProfileContext.Provider value={{ userProfile, homeProfile }}>
            {children}
        </KProfileContext.Provider>
    );
};


export const useKProfile = () => {
    return useContext(KProfileContext);
}