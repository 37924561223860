import React, { useContext } from 'react';
import { Box, Button, Divider, List, ListItemButton, Menu, MenuItem, Typography } from '@mui/material';
import { RecipeItem } from '../recipes/RecipeItem';
import { Recipe } from '../../models/RecipeModel';
import { RestaurantItem } from '../restaurants/RestaurantItem';
import { Restaurant } from '../../models/RestaurantModel';
import { Meal, newMeal } from '../../models/MealModel';
import { LaunchMealSelectorFormContext } from './LaunchMealSelectorFormContext';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { paletteColors } from '../../theme/theme';

import CommentIcon from '@mui/icons-material/Comment';
import { IconRecipe, IconRestaurant } from '../../components/FeatureIcons/FeatureIcons';

/**
 * 
 * MealItem
 * 
 */

type MealName = 'LUNCH' | 'DINNER';

interface Props {
	date: string;
	mealType: MealName;
	meals?: Meal[];
	empty?: 'static' | 'picker' | 'planner';
}

export const MealItem: React.FC<Props> = ({ date, mealType, meals=[], empty='picker' }) => {

	const { launchMealSelectorForm } = useContext(LaunchMealSelectorFormContext);

	const newMealSlotMeal = {...newMeal, date: date, mealType: mealType};

	const handleMealEdit = (meal: Meal | undefined = newMealSlotMeal) => {
		if(!open) // Hacky way to make top clickaway propogation
			launchMealSelectorForm(meal);
	};

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleMealMenuClick = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		setAnchorEl(e.currentTarget);
	};
	const handleMealMenuClose = () => {
		setAnchorEl(null);
	};

	// const handleMealMoveTo = (e: React.MouseEvent<HTMLElement>, day: DayOfWeek, meal: MealName) => {
	// 	e.stopPropagation();
	// 	console.log('Move to ', day, meal);
	// }

	// Render Restaurant or Recipe List Items
	if(meals && meals[0]) {
		return (
			<List sx={{
				borderRadius: '4px',
				padding: 0,
				marginBottom: 0,
				display: 'flex',
				flexDirection: 'column',
			}}>

				{meals?.map((meal: Meal, index: number) => (

					<ListItemButton
						disabled={empty==='static' ? true : false}
						key={index}
						onClick={()=>handleMealEdit(meal)}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'left',
							// backgroundColor: paletteColors.blue.dark,
							bgcolor: meal.selectionType==='RECIPE' ? IconRecipe.color : IconRestaurant.color,
							color: paletteColors.cream,
							borderRadius: '4px',
							padding: '0.25rem',
							minHeight: '100px',
							cursor: 'pointer',
							'& > *': {
								width: '100%'
							},
							'&[aria-disabled=true]': {
								opacity: 1
							},
							'&:hover': {
								// backgroundColor: paletteColors.blue.xdark,
								textDecoration: 'underline',
								bgcolor: meal.selectionType==='RECIPE' ? IconRecipe.color : IconRestaurant.color,
								cursor: 'pointer'
							},
							'&:focus': {
								backgroundColor: paletteColors.blue.xdark
							},
							'&:first-of-type:not(:last-child)': {
								borderBottomLeftRadius: 0,
								borderBottomRightRadius: 0,
							},
							'&:last-child:not(:first-of-type)': {
								borderTopLeftRadius: 0,
								borderTopRightRadius: 0,
								borderTop: '1px dashed #fffa'
							},
							'&:not(:first-of-type):not(:last-child)': {
								borderRadius: 0,
								borderTop: '1px dashed #fffa'
							},
							...(meal.isPlaceholder && {
								backgroundColor: paletteColors.blue.dark+'11',
								color: paletteColors.blue.main,
								border: '1px dashed '+paletteColors.blue.light,
								'&:hover': {
									backgroundColor: paletteColors.blue.dark+'33'
								}
							})
						}}
					>
						
						{meal.selectionType === 'RESTAURANT' &&
							<RestaurantItem
								data={meal.selectionData as Restaurant}
								type="card"
							/>
						}
						
						{meal.selectionType === 'RECIPE' &&
							<RecipeItem
								data={meal.selectionData as Recipe}
								type="card"
							/>
						}

						<MealNote note={meal?.notes || ''} />

						<Box sx={{display: 'none', bgcolor: '#0002'}}>
							<Button
								id='demo-customized-button'
								aria-controls={open ? 'demo-customized-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={open ? 'true' : undefined}
								onClick={handleMealMenuClick}>
								<ExpandCircleDownIcon />
							</Button>
							
							<Menu
								open={open}
								elevation={1}
								onClose={handleMealMenuClose}
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right'
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right'
								}}>
									<MenuItem>Move to...</MenuItem>
									<Divider />
									<MenuItem disabled>Mark as...</MenuItem>
									<MenuItem>Placeholder</MenuItem>
									<MenuItem>Leftovers</MenuItem>
									<Divider />
									<MenuItem>Delete</MenuItem>
							</Menu>
						</Box>

					</ListItemButton>
					
				))}
			</List>
		);
	}

	// Render Placeholder if no Meals
	else {
		if(empty === 'static') return (
			<Box sx={{
				minHeight: '100px',
				marginBottom: 0
			}}>
				<Typography
					sx={{
						width: '100%',
						height: '100%',
						fontSize: '1rem',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						color: '#999',
						border: '2px dotted '+paletteColors.blue.main,
						borderRadius: '4px'
					}}>
					No Meal Selected
				</Typography>
			</Box>
		)
		else {
			return (
				<>
					{/* No placeholder for empty containers outside of the picker */}
				</>
			);
		}
	}
}

const MealNote: React.FC<{note: string}> = ({note}) => {
	return (
		<Box sx={{
			fontStyle: 'italic',
			fontSize: {
				md: '12px'
			},
			margin: 0,
			padding: 0,
			':not(:first-of-type):not(:empty)': {
				marginTop: 1,
			},
			'&:not(:empty)': {
				// borderTop: '1px solid #fff3',
				background: '#fff1',
				color: '#fff',
				borderRadius: '2px',
				flexGrow: 1,
				padding: '0.5rem'
			}
		}}>
			{note && <CommentIcon sx={{fontSize: '12px', position: 'relative', top: '2px', mr: 1, opacity: 0.3}} />}
			{note}
		</Box>
	)
}