import moment from 'moment';

import { LocalDateTime } from './micro/LocalDateTime';
import { Restaurant } from './RestaurantModel';
import { Recipe } from './RecipeModel';
import { UserGroupOwnership } from './micro/UserGroupOwnership';
import { MongoId } from './macro/MongoId';

type MealDetails = {
	id: MongoId;
	notes?: string;
	mealType: 'LUNCH' | 'DINNER';
	date: LocalDateTime;		// Format: "2023-02-28"
	
	selectionId?: string;
	selectionType?: 'RESTAURANT' | 'RECIPE';
	selectionData?: Restaurant | Recipe;

	isPlaceholder: boolean;
	isLeftovers: boolean;
}

export type Meal = UserGroupOwnership & MealDetails

export const newMeal: Meal = {
	id: '',
	mealType: 'LUNCH',
	date: moment().day().toString(),
	isPlaceholder: false,
	isLeftovers: false,
	userGroupOwnershipId: ''
}

// export const testMeals: Meal[] = [
// 	{
// 		"id": "test1",
// 		"userGroupOwnershipId": "ug1",
// 		"dateModified": null,
// 		"dateCreated": "2023-01-01T06:00:00",
// 		"createdBy": null,
// 		"modifiedBy": null,
// 		"date": "2023-03-22",
// 		"mealType": "DINNER",
// 		"notes": "First time trying, awesome new spot",
// 		"selectionId": "641d16166222e24397341f07",
// 		"selectionType": "RESTAURANT",
// 		"selectionData": {
// 			"type": "restaurant",
// 			"id": "641d16166222e24397341f07",
// 			"name": "Peak Thai",
// 			"notes": "",
// 			"type": "restaurant",
// 			"userGroupOwnershipId": "6507d6953ff94f6948c24790",
// 			"isChain": false,
// 			"category": "SE_ASIAN",
// 			"address": null,
// 			"phone": null,
// 			"hours": null,
// 			"linkYelp": "",
// 			"linkUberEats": "",
// 			"linkDoorDash": "",
// 			"linkWeb": "",
// 			"linkGrubHub": ""
// 		},
// 		"isPlaceholder": null,
// 		"isLeftovers": null,
// 		"userGroupMemberAssignments": null
// 	},
// 	{
// 		"id": "test2",
// 		"userGroupOwnershipId": "ug2",
// 		"dateModified": null,
// 		"dateCreated": "2023-01-01T06:00:00",
// 		"createdBy": null,
// 		"modifiedBy": null,
// 		"date": "2023-03-23",
// 		"mealType": "LUNCH",
// 		"notes": "Leftovers",
// 		"selectionId": null,
// 		"selectionType": null,
// 		"selectionData": null,
// 		"isPlaceholder": null,
// 		"isLeftovers": null,
// 		"userGroupMemberAssignments": null
// 	}
// ]