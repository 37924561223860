import { createContext } from 'react';
import { Meal } from '../../models/MealModel';

type launchMealSelectorFormContextType = {
  launchMealSelectorForm: (meal: Meal) => void;
};

export const LaunchMealSelectorFormContext = createContext<launchMealSelectorFormContextType>({
  launchMealSelectorForm: () => {},
});
