import { createTheme } from '@mui/material/styles';
import { FixTypeLater } from 'react-redux';

import '@fontsource/bodoni-moda';

export const paletteColors = {
	cream: '#DADFE3',
	white: '#fff',
	black: '#141314',
	blue: {
		xxlight: '#97a9c733',
		light: '#97a9c7',
		main: '#4c607c',
		dark: '#2a384d',
		xdark: '#202c3f',
		xxdark: '#171f2c'
	},
	green: {
		light: '#3bb357',
		main: '#4E9460',
		dark: '#07701f'
	},
	red: {
		light: '#cc8d8f',
		main: '#aa2b2e',
		dark: '#7c191b'
	}
};

export const theme = createTheme({
	palette: {
		primary: {
			light: paletteColors.blue.light,
			main: paletteColors.blue.main,
			dark: paletteColors.blue.dark
		},
		secondary: {
			light: paletteColors.red.light,
			main: paletteColors.red.main,
			dark: paletteColors.red.dark
		},
		background: {
			default: paletteColors.blue.xdark },
		text: {
			primary: paletteColors.black },
		contrastThreshold: 4.5
	},
	typography: {
		fontFamily: 'PT Serif',
		h1: {
			fontSize: '2rem',
			display: 'none'
		},
		h2: {
			fontSize: '1.2rem'
		},
		h3: {
			fontSize: '1.2rem'
		}
	},
	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					boxShadow: 'none'
				}
			}
		},
		MuiButtonBase: {
			styleOverrides: {
				root: {
					"&:focus": {
						outline: 'none',
						position: 'relative'
					},
					"&:focus::after": {
						content: "''",
						position: "absolute",
						top: '-5px',
						left: '-5px',
						right: '-5px',
						bottom: '-5px',
						border: '3px solid #f0f6',
						borderRadius: '8px',
						boxSizing: 'border-box',
						pointerEvents: 'none' /* Ensure clicks go through to the element below */
					}
				}
			},
			defaultProps: {
				disableRipple: true
			}
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					"&:focus::after": {
						borderRadius: '1000px!important'
					}
				}
			}
		},
		MuiSwitch: {
			defaultProps: {
				disableRipple: false
			}
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					"&:focus-within": {
						// outline: '5px solid #f0f3'
						position: 'relative'
					},
					"&:focus-within::after": {
						content: "''",
						position: "absolute",
						top: '-11px',
						left: '-11px',
						right: '-11px',
						bottom: '-11px',
						border: '5px solid #f0f3',
						borderRadius: '11px',
						boxSizing: 'border-box',
						pointerEvents: 'none'
					}
				}
			},
		},
		MuiBackdrop: {
			styleOverrides: {
				root: {
					backdropFilter: 'blur(3px)'
				}
			}
		}
	}
});

export const pageStyleOpen: FixTypeLater = {
	backgroundColor: 'transparent',
	p: '1rem',
	color: paletteColors.cream
};