import type { Priority } from "./micro/Priority";
import type { ProjectStatus } from "./micro/ProjectStatus";
import type { BaseModel } from "./macro/BaseModel";
import type { Link } from "./micro/Link";
import { UserGroupOwnership } from "./micro/UserGroupOwnership";

type ProjectDetails = {
	status: ProjectStatus,
	priority: Priority,
	costEstimate?: number,
	actualCost?: number,
	linkPinterest?: Link
};

export type Project = BaseModel & UserGroupOwnership & ProjectDetails;

/**
 * 
 * NEW BLANK OBJECT
 * 
 */

export const newProject: Project = {
	id: '',
	name: '',
	userGroupOwnershipId: '',
	status: "NOT_STARTED",
	priority: "LOW",
	costEstimate: 0,
	actualCost: 0
};

// export const testProjects: Project[] = [
// 	{
// 		"id": "1",
// 		"name": "Replace Sump Pump",
// 		"notes": "Actual cost was something along these lines",
// 		"userGroupOwnershipId": "g1",
// 		"dateModified": null,
// 		"dateCreated": "2023-04-19T18:38:17.963",
// 		"createdBy": null,
// 		"modifiedBy": null,
// 		"priority": "HIGH",
// 		"status": "COMPLETE",
// 		"costEstimate": 0,
// 		"actualCost": 1500,
// 		"linkPinterest": null
// 	},
// 	{
// 		"id": "2",
// 		"name": "Basement Crawlspace Entry",
// 		"notes": "Test",
// 		"userGroupOwnershipId": "g2",
// 		"dateModified": "2023-04-04T16:47:22.774",
// 		"dateCreated": "2023-01-01T06:00:00",
// 		"createdBy": null,
// 		"modifiedBy": null,
// 		"priority": "LOW",
// 		"status": "COMPLETE",
// 		"costEstimate": 50,
// 		"actualCost": 20,
// 		"linkPinterest": ""
// 	}
// ]