import React from 'react';

import { useAppSelector } from '../../app/hooks';

import { selectMaintenance } from '../../slices/maintenanceSlice';

import { PageContent } from '../../components/PageContent/PageContent';
import { MaintenanceJob } from '../../models/MaintenanceModel';

export const Maintenance: React.FC = () => {

	const maintenance: MaintenanceJob[] = useAppSelector(selectMaintenance);

	return (
		<PageContent title="Maintenance">
			<ul>
				{maintenance.map((item, index) => (
					<li key={index}>
						{item.name}
						{/* <br /> */}
						{/* {item.link} */}
					</li>
				))}
			</ul>
		</PageContent>
	);
}