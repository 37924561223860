import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GuestBookEntry } from '../models/GuestBookModel';
import { KenilworthBaseApi } from './KenilworthBaseApi';

import { store } from '../app/store';

export const guestBookApi = createApi({
	reducerPath: 'guestBookApi',
	baseQuery: async (request, api, extraOptions) => {
		const { auth } = store.getState();
		const customBaseQuery = fetchBaseQuery({
			baseUrl: process.env.REACT_APP_ERICVILLE_API_BASEURL+'/home/guestbook',
			prepareHeaders: (headers) => {
				if (auth.jwt) {
					headers.set('Authorization', `Bearer ${auth.jwt}`);
				}
				return headers;
			},
		});
	
		return customBaseQuery(request, api, extraOptions);
	},
	endpoints: (builder) => (KenilworthBaseApi<GuestBookEntry>(builder))
});

/**
 * 
 * Interfaces
 * 
 */

export const {
	useGetAllQuery,
	useAddMutation,
	useUpdateMutation,
	useDeleteMutation,
	usePrefetch
} = guestBookApi;