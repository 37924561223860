import React from 'react';
import { Avatar, Box, Card, Divider, Typography } from '@mui/material';
import { PageContent } from '../../components/PageContent/PageContent';
import { KPIBadge, KPIRow } from '../../components/KPIBadge/KPIBadge';

import HomeIcon from '@mui/icons-material/Home';
import { LogoutButton } from '../../components/LogoutButton/LogoutButton';
import { useKProfile } from '../../app/KProfileProvider';
import { HouseholdMembers } from '../../components/HouseholdMembers/HouseholdMembers';

export const UserProfile: React.FC = () => {

	const {userProfile, homeProfile} = useKProfile();

	return (
		<PageContent title='Profile' plain>
			{userProfile && <>
				<Card sx={{ p:5, mt:2, display: 'flex', flexDirection: 'row', gap: 5 }}>
					<Avatar src={userProfile.avatar} sx={{ width: 150, height: 150 }} />
					<Box sx={{flexGrow:1}}>
						<Typography variant='h3'>{userProfile.firstName+' '+userProfile.lastName}</Typography>
						<Divider />
						<Box sx={{display: 'flex', '& > *': {flexGrow: 1}}}>
							<Box>
								<KPIBadge label='Email' value={userProfile.email || 'n/a'} />
								<KPIBadge label='Phone' value={userProfile.phone || 'n/a'} />
							</Box>
							<Box>
								<KPIRow label='Account Type' value='Tester' />
								<KPIRow label='timezone' value={userProfile.timezone || 'n/a'} />
								<KPIRow label='locale' value={userProfile.locale || 'n/a'} />
							</Box>
						</Box>
						<LogoutButton />
					</Box>
				</Card>

				{homeProfile &&
				<Card sx={{p:5, mt:5, display: 'flex', flexDirection: 'row', gap: 5}}>
					<Avatar sx={{ width: 150, height: 150 }}><HomeIcon /></Avatar>
					<Box sx={{width:'100%'}}>
						<Typography variant='h3'>{homeProfile.name}</Typography>
						<Divider />
					
						<Box sx={{display: 'flex', flexDirection: 'column', gap: 5, '& > *': {flexGrow: 1}}}>
							<HouseholdMembers userProfile={userProfile} homeProfile={homeProfile} />
							<Box>
								<KPIRow label="Features" value={homeProfile?.features.toString()} />
							</Box>
						
						</Box>
					</Box>
				</Card>
				}
			</>}
		</PageContent>
	);
}