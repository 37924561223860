import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Restaurant } from '../models/RestaurantModel';
import { KenilworthBaseApi } from './KenilworthBaseApi';

import { store } from '../app/store';

export const restaurantsApi = createApi({
	reducerPath: 'restaurantsApi',
	baseQuery: async (request, api, extraOptions) => {
		const { auth } = store.getState();
		const customBaseQuery = fetchBaseQuery({
		  baseUrl: process.env.REACT_APP_ERICVILLE_API_BASEURL+'/food/restaurants',
		  prepareHeaders: (headers) => {
			if (auth.jwt) {
			  headers.set('Authorization', `Bearer ${auth.jwt}`);
			}
			return headers;
		  },
		});
	
		return customBaseQuery(request, api, extraOptions);
	},
	endpoints: (builder) => (KenilworthBaseApi<Restaurant>(builder))
});

/**
 * 
 * Interfaces
 * 
 */

export const {
	useGetAllQuery,
	useAddMutation,
	useUpdateMutation,
	useDeleteMutation,
	usePrefetch
} = restaurantsApi;