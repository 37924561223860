import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectSwatches } from '../../slices/swatchesSlice';
import { PageContent } from '../../components/PageContent/PageContent';
import Masonry from '@mui/lab/Masonry';
import { useMediaQuery, Theme, Box } from '@mui/material';
import { SwatchItem } from './SwatchItem';
import { Swatch } from '../../models/SwatchModel';
import { paletteColors } from '../../theme/theme';

export const Swatches: React.FC = () => {
	const swatches: Swatch[] = useAppSelector(selectSwatches);

	const isSmallScreen = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('md')
	)

	return (
		<PageContent title="Paint Swatches">
			{!isSmallScreen && (
				<Box sx={{
					pl: 4,
					pt: 3,
					bgcolor: paletteColors.blue.xxlight
				}}>
					<Masonry columns={3} spacing={4}>
						{swatches.map((item, index) => (
							<SwatchItem data={item} key={index} />
						))}
					</Masonry>
				</Box>
			)}

			{isSmallScreen && (
				<Box sx={{
					display:'flex',
					flexDirection: 'column',
					gap: 3,
					pt: 3,
					px: 3,
					bgcolor: paletteColors.blue.xxlight
				}}>
					{swatches.map((item, index) => (
						<SwatchItem data={item} key={index} />
					))}
				</Box>
			)}
		</PageContent>
	);
}