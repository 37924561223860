import React from 'react';
import moment, { Moment } from 'moment';
import { Box, Button, List, ListItem } from '@mui/material';
import { MealPlannerSettings, now } from './settings';
import { paletteColors } from '../../theme/theme';
import { getFirstdayOfWeek } from './getFirstDayOfWeek';

/**
 * 
 * Control Panel
 * – TO DO: Add meal counts to each week cell
 * 
 */

interface Props {
	settings: MealPlannerSettings;
	setSettings: (x: MealPlannerSettings)=>void
}

export const ControlPanel: React.FC<Props> = ({settings, setSettings}) => {

	const currentWeekKey = now.format('YYYY')+'.'+now.format('WW');

	/**
	 * 
	 * Build out the list of weeks, using the settings and current/selected week as a guide.
	 * 
	 */

	const weeks = () => {

		// Current year and week number

		const currentYear = now.year();
		const currentWeek = now.week();

		// Defines the reference point for how many weeks to show in the fullscreen view
		// 1/3 of TOTAL_WEEKS prior to currentWeek

		let pickerStartWeek: number = currentWeek - Math.floor(settings.TOTAL_WEEKS/3);
		let pickerStartYear: number = pickerStartWeek < 0 ? currentYear-1 : currentYear;

		let w: string[] = [];

		let getWeekToAdd = (i: number) => {
			if(pickerStartWeek + i < 0) return (51-(pickerStartWeek+i)) as number;
			else return pickerStartWeek + i as number;
		};

		for(let i=0; i < settings.TOTAL_WEEKS; i++) {
			let weekToAdd: number = getWeekToAdd(i);
			if(weekToAdd === 0 && i !== 0) pickerStartYear++; // Solves bug that increments if the first week of the list is #0
			w.push((pickerStartYear+'.'+weekToAdd) as string);
		}

		return w;
	}
	const weekList = weeks();

	const handleWeekSwitch = (newWeekKey: string) => {
		const [year, week] = newWeekKey.split('.');

		const firstDayOfWeek: Moment = getFirstdayOfWeek(parseInt(year), parseInt(week));

		setSettings({
			...settings,
			SELECTED_DAY: firstDayOfWeek.format('YYYY-MM-DD').toString(),
			SELECTED_WEEK_KEY: newWeekKey
		})
	}

	const handleRewind = () => {
		setSettings({
			...settings,
			SELECTED_DAY: moment(settings.SELECTED_DAY).add(-1, 'day').format('YYYY-MM-DD').toString()
		})
	}
	const handleToToday = () => {
		setSettings({
			...settings,
			SELECTED_DAY: moment().format('YYYY-MM-DD').toString()
		})
	}
	const handleFastForward = () => {
		setSettings({
			...settings,
			SELECTED_DAY: moment(settings.SELECTED_DAY).add(1, 'day').format('YYYY-MM-DD').toString()
		})

	}

	/**
	 * 
	 * RENDER
	 * 
	 */

	if(settings.VIEW_MODE === 'DESKTOP') return (
		<Box sx={{
			bgcolor: paletteColors.blue.xdark,
			padding: '0.5rem 2rem'
		}}>
			<List sx={{
				display: 'flex',
				flexDirection: 'row',
				width: '100%'
			}}>
				{weekList.map((week, index) => (
					<ListItem key={index} sx={{
						margin: 0,
						padding: 0
					}}>
						<Button
							sx={ getWeekStyle(week, currentWeekKey, settings.SELECTED_WEEK_KEY) }
							onClick={()=>handleWeekSwitch(week)}
						>
							{(parseInt(week.split('.')[1]) > 52) ? parseInt(week.split('.')[1])-52 : week.split('.')[1] }
						</Button>
					</ListItem>
				))}
			</List>
		</Box>
	)
	else return (
		<Box sx={{
			bgcolor: paletteColors.blue.xdark,
			padding: '0.5rem 2rem', 
			display: 'flex',
			width: '100%',
			'& *': {flexGrow: 1}
		}}>
			<Button onClick={handleRewind}>Previous</Button>
			<Button onClick={handleToToday}>Today</Button>
			<Button onClick={handleFastForward}>Next</Button>
		</Box>
	)
}

/**
 * 
 * Styles
 * 
 */


/**
 * 
 * Week Item Styles
 * 
 */

const styleCurrentWeek = {
	borderBottom: '5px solid '+paletteColors.red.dark
};

const baseWeekStyle = {
	height: '30px',
	minWidth: 'unset',
	width: '100%',
	padding: 0,
	margin: '1px',
	color: paletteColors.blue.light,
	fontSize: '0.65rem',
	borderRadius: '2px',
	'& > span': {
		display: 'none',
		fontSize: '0.75rem'
	}
};

const styleSelectedWeek = {
	...baseWeekStyle,
	bgcolor: paletteColors.blue.light,
	color: paletteColors.blue.main,
	cursor: 'default',
	'&:hover': {
		backgroundColor: paletteColors.blue.light
	}
};

const styleElse = {
	...baseWeekStyle,
	bgcolor: paletteColors.blue.main,
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: paletteColors.blue.main,
		borderBottom: '5px solid '+paletteColors.blue.light
	}
};

const getWeekStyle = (week: string, currentWeek: string, selectedWeek: string) => {
	let style = (week === selectedWeek) ? styleSelectedWeek : styleElse;

	if(week === currentWeek)
		style = {
			...style,
			...styleCurrentWeek
		}

	return style;
}