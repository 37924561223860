import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { Swatch } from '../models/SwatchModel';

export interface SwatchState {
	status: string;
	entities: Swatch[];
	filters?: object;
}

const initialState: SwatchState = {
	status: 'idle',
	entities: [
		{
			name: "Stairwell",
			baseMix: "Revere Pewter Satin",
			color: {
				name: "???",
				number: "",
				formula: ""
			},
			previewHex: "#CBC6B8"
		},
		{
			name: "Family Room",
			baseMix: "Benjamin Moore Blahblah Eggshell",
			color: {
				name: "",
				number: "",
				formula: ""
			},
			previewHex: ""
		},
		{
			name: "Priya's Office (Black)",
			dateMixed: "03/16/2021",
			baseMix: "Benjamin Moore Coronado Super Kote 5000 (Eggshell) (30-36 Accent Base)",
			store: "JC Licht – Aurora",
			color: {
				name: "Black Satin",
				number: "2131-10",
				formula: "BK 11x7.00, TG 2x16.00, WH 0x8.00",
			},
			previewHex: "#111"
		},
		{
			name: "Front & Back Doors",
			dateMixed: "11/06/2016",
			baseMix: "Glidden Premium Exterior Paint",
			store: "Home Depot",
			color: {
				name: "Rusty Red",
				number: "",
				formula: "",
			},
			previewHex: "#742c1e"
		},
		{
			name: "Front & Back Doors (Newer, more saturated)",
			dateMixed: "",
			baseMix: "Behr Urethane Alkyd Satin Enamel Deep Base No. 7930",
			store: "Home Depot",
			color: {
				name: "",
				number: "",
				formula: "",
			},
			previewHex: "#a4332f"
		},
		{
			name: "Stair Stain",
			dateMixed: "",
			baseMix: "",
			store: "",
			color: {
				name: "",
				number: "",
				formula: ".5 parts Spice, .5 parts Golden, 10 parts Natural",
			},
			previewHex: ""
		},
		{
			name: "Front Deck",
			dateMixed: "03/20/2022",
			baseMix: "Behr Premium Advanced DeckOver",
			store: "Home Depot",
			color: {
				name: "Tugboat",
				number: "",
				formula: "CLRNT 384, BL 2 / 98, CL 2 / 42, FL 0 / 298, KXL 2 / 330",
			},
			previewHex: "#746559"
		},
		{
			name: "Bedroom (Which?)",
			dateMixed: "07/02/2012",
			baseMix: "Benjamin Moore Regal Seelct Matte Finish (Base 1 548)",
			store: "Westmont Painting & Decorating",
			color: {
				name: "Antique White",
				number: "",
				formula: "Y3 0x12.50, B1 0x0.50, R1 0x10.00",
			},
			previewHex: ""
		},
		{
			name: "",
			dateMixed: "03/26/2021",
			baseMix: "Benjamin Moore Coronado Super Kote 5000 (Eggshell) (30-36 Accent Base)",
			store: "JC Licht - Wheaton (For Lexem Group)",
			color: {
				name: "Winter White",
				number: "OC-21",
				formula: "OY 0x2.50, BK 2.25",
			},
			previewHex: ""
		},
		{
			name: "2nd Floor Hallway",
			dateMixed: "12/27/2011",
			baseMix: "Benjamin Moore Ultra Spec 500 Interior Low Sheen Eggshell (N537)",
			store: "Westmont Painting & Decorating",
			color: {
				name: "Soft Chamois",
				number: "OC-13",
				formula: "Y3 0x6.00, S1 0x2.00, S1 0x4.00, R1 0x1.00",
			},
			previewHex: ""
		},
		{
			name: "Kitchen cabinets???",
			dateMixed: "03/25/2021",
			baseMix: "Benjamin Moore Advance Semi-Gloss (793)",
			store: "JC Licht - Wheaton (for Lexem Group)",
			color: {
				name: "Custom",
				number: "",
				formula: "S1 0x0.50, Y2 0x3.00, R3 0x0.3750",
			},
			previewHex: ""
		},
		{
			name: "Fireplace",
			dateMixed: "04/29/2022",
			baseMix: "Behr Dynasty Ultra Pure White (2650)",
			store: "Home Depot",
			color: {
				name: "Ballet White",
				number: "OC-9",
				formula: "CLRNT 384, CL 0 / 248, DL 0 / 16, JL 0 / 40",
			},
			previewHex: ""
		},
		{
			name: "Basement",
			dateMixed: "",
			baseMix: "Behr Ultra Interior Eggshell Enamel (Deep Base 2753)",
			store: "Home Depot",
			color: {
				name: "Something pewter?",
				number: "MQ2-58",
				formula: "CLRNT 384, EL 2 / 188, FL 1 / 324, KXL 8 / 108, YL 1 / 88",
			},
			previewHex: ""
		},
		{
			name: "Eric's Office",
			dateMixed: "",
			baseMix: "Benjamin Moore ...",
			store: "",
			color: {
				name: "Something green",
				number: "",
				formula: "",
			},
			previewHex: ""
		},
	]
};

export const swatchesSlice = createSlice({
  name: 'swatches',
  initialState,
  reducers: {}
});

export default swatchesSlice.reducer;

// Selectors

export const selectSwatches = (state: RootState) => state.swatches.entities;