import React from 'react';
import { Typography } from '@mui/material';
import { AppStatusContent } from '../../components/AppStatusContent/AppStatusContent';

export const Error404: React.FC = () => {
	return (
		<AppStatusContent>
			<Typography variant='h4' color='white'>
				I think that pizza delivery is actually for our neighbors.
			</Typography>
		</AppStatusContent>
	)
}