import React from 'react';

import { ListItem, ListItemButton, ListItemIcon, ListItemText, SxProps } from '@mui/material';

import { paletteColors } from '../../theme/theme';
import { NavigationItem } from './NavChoices';
import { useKAuth } from '../../utilities/useKAuth';
import { NavigateFunction } from 'react-router-dom';

interface Props {
	item: NavigationItem;
	navigate: NavigateFunction;
	location: {pathname: string & any};
	handleMobileDrawerToggle: ()=>void;
	isMini?: boolean;
}

export const NavLinkStyle: SxProps = {
	margin: '4px 14px',
	borderRadius: '4px',
	padding: 0,
	color: paletteColors.cream,
	width: 'auto',
	"&:hover": {
		backgroundColor: paletteColors.blue.main+"33"
	},
	"&:hover:not svg": {
		color: paletteColors.cream,
	},
	"&:active": {
		backgroundColor: paletteColors.blue.xxdark+"33",
		boxShadow: '0 3px 0 inset rgba(0,0,0,0.05)',
	}
};

const NavLinkStyle_Current: SxProps = {
	backgroundColor: paletteColors.blue.xxdark,
	boxShadow: '0 3px 0 inset rgba(0,0,0,0.15)',
	"&:hover": {
		backgroundColor: paletteColors.blue.xxdark,
	},
	"& svg": {
		color: paletteColors.cream
	},
	"&:hover svg": {
		color: paletteColors.cream
	}
}

export const NavLink: React.FC<Props> = ({item, navigate, location, isMini=true, handleMobileDrawerToggle}) => {
	
	const handleNav = () => {
		handleMobileDrawerToggle();
		navigate(item.to!);
	}

	const isCurrent = (location.pathname === item.to);

	const { isTester } = useKAuth();

	return (
		<ListItem sx={{
			...NavLinkStyle,
			"& svg": {
				color: paletteColors.blue.main,
			},
			...(isMini && {
				'& svg': {
					color: paletteColors.blue.light
				}
			}),
			...(isCurrent && NavLinkStyle_Current),
			...(item.underconstruction && {
				opacity: 0.3
			})
		}}>
			<ListItemButton
				onClick={ handleNav }
				sx={{
					display: 'flex',
					flexDirection: 'row',
					borderRadius: '4px',
					gap: 3,
					'&.Mui-disabled:hover': {
						backgroundColor: 'transparent',
					},
					...(isMini && {
						px: 0,
						justifyContent: 'center'
					})
				}}
				disabled={!isTester}
			>
				<ListItemIcon sx={{
					minWidth: 0,
					justifyContent: 'center'
				}}>
					{item.icon}
				</ListItemIcon>
				{!isMini && <ListItemText primary={item.name} />}
			</ListItemButton>
		</ListItem>
	);
}