import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

import { LaunchMealSelectorFormContext } from './LaunchMealSelectorFormContext';

import { useGetByReferenceDateQuery } from '../../slices/mealsApi';

import { Meal, newMeal } from '../../models/MealModel';
import { MealSlot } from '../../models/micro/MealSlot';

import { Drawer, useMediaQuery, Theme, Box, LinearProgress } from '@mui/material';

import { PageContent } from '../../components/PageContent/PageContent';

import { ControlPanel } from './ControlPanel';
import { MealForm } from './MealForm';
import { ViewWeek } from './ViewWeek';
import { ViewDay } from './ViewDay';
import { getFirstdayOfWeek } from './getFirstDayOfWeek';
import {
	addPlaceholder,
	fillMealSlots
} from './mealSlotsHelpers';
import { defaultSettings, MealPlannerSettings } from './settings';


export const MealPlanner: React.FC = () => {

	/**
	 * 
	 * Settings
	 * 
	 */

	const [isLoadingAlt, setIsLoadingAlt] = useState<boolean>(false);
	const [settings, setSettings] = useState<MealPlannerSettings>(defaultSettings);

	/**
	 * 
	 * Initialize View
	 * 
	 */

	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

	useEffect(()=>{
		setSettings({
			...settings,
			VIEW_MODE: isMobile ? 'MOBILE' : 'DESKTOP'
		});
	}, [isMobile])

	/**
	 * 
	 * Initialize Data
	 * 
	 */

	const [mealSlots, setMealSlots] = useState<MealSlot>({});
	const { data: meals = [], isLoading: _isLoading, refetch: _refetch } = useGetByReferenceDateQuery<{data: Meal[], isLoading: boolean, refetch: ()=>void}>(settings.SELECTED_DAY);

	/**
	 * 
	 * Form States & Handlers
	 * 
	*/
	
	const [showForm, setShowForm] = useState(false);
	const [formData, setFormData] = useState(newMeal);
	
	const launchMealSelectorForm = (meal: Meal) => {
		if(meal.id === '') {
			addPlaceholder(mealSlots, meal, setMealSlots);
		}

		setFormData(meal);
		setShowForm(true);
	}

	const handleClose = () => {
		setIsLoadingAlt(true);
		setFormData(newMeal);
		setShowForm(false);
	}

	useEffect(() => {
		if(!showForm) {
			let newSlots: MealSlot;

			const fetchDataAndBuildSlots = async () => {
				
				try {
					
					// Refresh the store (ie after meal is updated) ... shouldn't have to do this manually but it's a bug.
					await _refetch();

					// BUILD MEAL SLOTS

					// Define Keys using SELECTED_WEEK_KEY
					const [year, week] = settings.SELECTED_WEEK_KEY.split('.');
					const firstDay: Moment = getFirstdayOfWeek(parseInt(year), parseInt(week));

					const day1: string = firstDay.toISOString(),
						day2: string = firstDay.clone().add(1, 'day').toISOString(),
						day3: string = firstDay.clone().add(2, 'day').toISOString(),
						day4: string = firstDay.clone().add(3, 'day').toISOString(),
						day5: string = firstDay.clone().add(4, 'day').toISOString(),
						day6: string = firstDay.clone().add(5, 'day').toISOString(),
						day7: string = firstDay.clone().add(6, 'day').toISOString();

					// Add Meals to each Key slot
					newSlots = {
						[day1]: [],
						[day2]: [],
						[day3]: [],
						[day4]: [],
						[day5]: [],
						[day6]: [],
						[day7]: []
					};

					fillMealSlots(newSlots, meals, setMealSlots);

				} catch (error) {
					console.error("Error fetching data: ", error);
				} finally {
					setIsLoadingAlt(false);
				}
			}

			fetchDataAndBuildSlots();
		}
	}, [settings.SELECTED_DAY, settings.SELECTED_WEEK_KEY, showForm, meals]);

	/**
	 * 
	 * App Rendering
	 * 
	 */

	return (
		<LaunchMealSelectorFormContext.Provider value={{launchMealSelectorForm}}>
						
			<PageContent title="Meal Planner" fullscreen={true} isLoading={_isLoading}>
				<ControlPanel settings={settings} setSettings={setSettings} />
				{isLoadingAlt && <LinearProgress sx={{ position: 'absolute', top:0, right:0, zIndex:9999, width: '100%', height: '2px' }} />}

				{settings.VIEW_MODE == 'DESKTOP' && (
					<ViewWeek 
						mealSlots={mealSlots}
					/>
				)}

				{settings.VIEW_MODE == 'MOBILE' && (
					<Box sx={{
						p: {xs: 3},
						md: 0,
						display: 'grid',
						padding: '0.5rem 2rem',
						gap: '0.5rem',
						gridTemplateColumns: 'repeat(1, minmax(0, 1fr))', //'auto',
						gridTemplateRows: '80px auto auto',
						gridAutoFlow: 'column',
						gridAutoColumns: 'minmax(0, 1fr)',
						width: '100%',
						height: '90%'
					}}>
						<ViewDay
							date={settings.SELECTED_DAY}
							meals={
								mealSlots[
									moment(settings.SELECTED_DAY, 'YYYY-MM-DD')
										.utcOffset('-00:00')
										.startOf('day')
										.toISOString()
								]
							}
						/>
					</Box>
				)}

			</PageContent>

			<Drawer
				disableRestoreFocus
				anchor='right'
				open={showForm}
				onClose={handleClose}
				sx={{
					'& .MuiPaper-root': {
						width: '100%',
						maxWidth: '600px',
						overflow: 'auto'
					}
				}}>

				<MealForm
					initialData={formData as Meal}
					closeAction={handleClose}
				/>
				
			</Drawer>

		</LaunchMealSelectorFormContext.Provider>
	);
}