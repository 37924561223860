import React, { ReactNode } from 'react';

import { TextField, InputAdornment, BaseTextFieldProps } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import Phone from '@mui/icons-material/Phone';

interface Props {
	name?: string;
	label: string;
	type?: string;
	value: string | number | undefined;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	autoFocus?: boolean;
	sx?: object;
}

export const KTextField: React.FC<Props> = (
	{
		name='',
		label,
		type='',
		value,
		onChange,
		autoFocus=false,
		sx={}
	}
) => {

	// React inputs don't like null values, so scrub them.
	value = (value===null) ? '' : value;

	let startAdornmentValue: ReactNode = (<InputAdornment position="start"></InputAdornment>);
	let endAdornmentValue: ReactNode = (<InputAdornment position="end"></InputAdornment>);
	let InputOptions: BaseTextFieldProps = {
		rows: 1,
		multiline: false,
		variant: 'outlined',
		fullWidth: true
	};

	switch(type) {
		case "dollars":
			startAdornmentValue = (<InputAdornment position="start">$</InputAdornment>);
			break;
		case "email":
			startAdornmentValue = (<InputAdornment position="start"><EmailIcon /></InputAdornment>);
			break;
		case "phone":
			startAdornmentValue = (<InputAdornment position="start"><Phone /></InputAdornment>);
			break;
		case "name":
		default:
			startAdornmentValue = undefined;
			break;
	}

	return (
		<TextField
			{...InputOptions}
			name={name}
			label={label}
			value={value}
			autoFocus={autoFocus}
			onChange={onChange}
			margin="normal"
			sx={sx}
			InputProps={{
				startAdornment: startAdornmentValue,
				endAdornment: endAdornmentValue
			}}
		/>
	);
}