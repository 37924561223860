import { BaseModel } from "./macro/BaseModel";
import { LocalDateTime } from "./micro/LocalDateTime";
import { UserGroupOwnership } from "./micro/UserGroupOwnership";

export type GuestBookEntryDetails = {
	note: string,
	dateLogged?: LocalDateTime
};

export type GuestBookEntry = BaseModel & UserGroupOwnership & GuestBookEntryDetails;

/**
 * 
 * NEW BLANK OBJECT
 * 
 */

export const newEntry: GuestBookEntry = {
	id: '',
	name: '',
	note: '',
	userGroupOwnershipId: '' // What should this be?
};

// export const testGuestBookEntries = [
// 	{
// 		"id": "1",
// 		"name": "Jacob Wittke",
// 		"notes": null,
// 		"note": "Thank you for your hospitality!",
// 		"dateLogged": "2023-01-14T14:41:59.243+00:00",
// 		"userGroupOwnershipId": "g1"
// 	},
// 	{
// 		"id": "2",
// 		"name": "Troy Wittke",
// 		"notes": null,
// 		"note": "Thank you guys so much for the hospitality (And letting my dog terrorize everyone as usual)! As always, great to see you both and especially Shaan!",
// 		"dateLogged": "2023-01-14T14:41:10.892+00:00",
// 		"userGroupOwnershipId": "g1"
// 	}
// ]