import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Subscription } from '../models/SubscriptionModel';
import { KenilworthBaseApi } from './KenilworthBaseApi';

import { store } from '../app/store';

export const subscriptionsApi = createApi({
	reducerPath: 'subscriptionsApi',
	baseQuery: async (request, api, extraOptions) => {
		const { auth } = store.getState();
		const customBaseQuery = fetchBaseQuery({
		baseUrl: process.env.REACT_APP_ERICVILLE_API_BASEURL+'/home/subscriptions',
		prepareHeaders: (headers) => {
			if (auth.jwt) {
				headers.set('Authorization', `Bearer ${auth.jwt}`);
			}
			return headers;
		  },
		});
	
		return customBaseQuery(request, api, extraOptions);
	},
	endpoints: (builder) => (KenilworthBaseApi<Subscription>(builder))
});

/**
 * 
 * Interfaces
 * 
 */

export const {
	useGetAllQuery,
	useAddMutation,
	useUpdateMutation,
	useDeleteMutation,
	usePrefetch
} = subscriptionsApi;