import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import { paletteColors } from '../../theme/theme';

export type KPI = {
    label: string,
    value: string,
    details?: string
}

interface Props extends KPI {}

export const KPIBadge: React.FC<Props> = ({label, value, details}) => {
    return (
        <Box sx={{
            bgcolor: '#fffa',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '4px',
            minWidth: '120px',
            p: 1
        }}>
            <Typography variant='subtitle2' sx={{opacity: 0.5}}>
                {label}
                {details &&
                    <Tooltip title={details} arrow><InfoIcon sx={{color:paletteColors.blue.light, width:'18px', height: '18px', ml: 0.5, position: 'relative', top: '2px'}} /></Tooltip>
                }
            </Typography>
            <Typography variant='h6'>
                {value}
            </Typography>
        </Box>
    )
}

export const KPIRow: React.FC<Props> = ({label, value, details}) => {
    return (
        <Box sx={{
            bgcolor: '#fffa',
            display: 'flex',
            flexDirection: 'row',
            borderRadius: '4px',
            minWidth: '120px',
            gap: 2,
            p: 1
        }}>
            <Typography variant='body2' sx={{opacity: 0.5}}>
                {label}
                {details &&
                    <Tooltip title={details} arrow><InfoIcon sx={{color:paletteColors.blue.light, width:'18px', height: '18px', ml: 0.5, position: 'relative', top: '2px'}} /></Tooltip>
                }
            </Typography>
            <Typography variant='body2'>
                {value}
            </Typography>
        </Box>
    )
}