import React from 'react';
import {
	Container,
	Box,
	Fab,
	Paper,
	SxProps,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { paletteColors } from '../../theme/theme';
import { Loader } from '../Loader/Loader';
import { PageHeader } from './PageHeader';

export const BORDER_RADIUS = {
	xs: '0',
	xl: '10px'
};

export const PAGE_PADDING = {
	xs: '1rem',
	md: '2rem',
	lg: '4rem',
};

interface Props {
	children?: React.ReactNode;
	title?: string;
	onAdd?: React.MouseEventHandler<HTMLButtonElement>;
	isLoading?: boolean;
	fullscreen?: boolean;
	filters?: React.ReactNode;
	plain?: boolean;
	sx?: SxProps;
};

export const PageContent: React.FC<Props> = ({ children, title, onAdd, isLoading=false, fullscreen=false, filters=null, plain=false, sx={} }) => {

	if(plain) return (
		<Container sx={{
			marginBottom:5,
			width: 'calc(100% - 220px)',
			...sx,
		}}>
			{children}
		</Container>
	)

	else return (
		<Container
			sx={{
				...sx,
				width: {
					sm: '100%',
					md: 'calc(100% - 220px)'
				},
				margin: {
					xs: '0 auto',
					sm: '1rem auto',
					md: '1rem auto',
					lg: '1rem auto',
					xl: '1rem auto'
				},
				padding: 0,
				backgroundColor: 'transparent',
				...(fullscreen && {
					maxWidth: {
						xs: 'unset',
						sm: 'unset',
						md: 'unset',
						lg: 'unset'
					},
					margin: 0,
					width: {
						xs: '100%',
						sm: '100%',
						md: '100%',
						lg: '100%'
					},
					padding: {
						xs: 0,
						sm: 0,
						md: 0,
						lg: 0
					}
				})
			}}
		>
			<Paper sx={{
				mx: {
					xs: 0,
					sm: 1,
					md: 1
				},
				borderRadius: {
					xs: 0,
					sm: '10px',
					md: '10px'
				},
				backgroundColor:'#fff',
				boxShadow: '0 0 30px '+paletteColors.blue.xxdark+', '+'0 0 10px #000c',
				display: 'flex',
				flexDirection: 'column',
				overflow: 'hidden',
				...(fullscreen && {
					borderRadius: 0,
					mx: 0,
					height: '100vh',
					overflowY: 'auto'
				})
			}}>

				{/* Page Header */}

				{title && 
					<PageHeader title={title} filters={filters} fullscreen={fullscreen}
				/>}

				{/* Page Content */}

				<Box sx={{
					padding: 0,
					'& > ul': {
						paddingBottom: 0,
						paddingTop: 0
					},
					...(fullscreen && {
						height: '100vh' // For meal planner
					})
				}}>
					{!isLoading && children}
					{isLoading && <Loader context='page' />}
				</Box>

				{/* Add Icon */}

				{onAdd && (
					<Fab
						onClick={onAdd}
						aria-label="add"
						sx={{
							backgroundColor: paletteColors.red.main,
							color: paletteColors.white,
							position: 'fixed',
							bottom: '2rem',
							right: '2rem',
							'&:hover': {
								color: paletteColors.red.main
							},
							'&:focus': {
								position: 'fixed'
							},
							'&:focus:after': {
								borderRadius: '50px'
							}
						}}
						>
						<AddIcon />
					</Fab>
				)}

			</Paper>
		</Container>
	)
};

