import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';

import { Avatar, Button, Drawer } from '@mui/material';
import { DrawerHeader } from './DrawerHeader';

import { NavLink } from './NavLink';
import { NavChoices } from './NavChoices';

import { paletteColors } from '../../theme/theme';
import { Box, useMediaQuery, Theme } from '@mui/material';
import { LaunchNavigationContext } from './LaunchNavigationContext';

import { useKProfile } from '../../app/KProfileProvider';

export const Navigation: React.FC = () => {

	const navigate = useNavigate();
	const location = useLocation();
	const { userProfile } = useKProfile();

	// Mobile View Configuration
	const isSmallScreen = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm')
	);
	const {mobileOpen, setMobileOpen} = useContext(LaunchNavigationContext);
	const [drawerWidth, setDrawerWidth] = useState('0px');
	const [isCollapsed, setIsCollapsed] = useState(false);
	
	useEffect(()=>{
		// isSmallScreen ? 300 : 220

		if(isCollapsed) {
			setDrawerWidth('80px');
		} else {
			setDrawerWidth('220px');
		}

	},[isCollapsed, drawerWidth])
	
	const handleMobileDrawerToggle = () => isSmallScreen ? setMobileOpen( mobileOpen?false:true ) : null;

	const handleCollapseToggle = () => {
		if(!isSmallScreen) {
			setIsCollapsed(!isCollapsed);
		} else {
			return;
		}
	}

	/**
	 * 
	 * Render Contents
	 * 
	 */

	const NavContents = (
		<List
			sx={{
				height: '100%',
				display: 'flex',
				flexFlow: 'column',
				overflow: 'auto'
			}}>
			{isSmallScreen && userProfile && 
				<NavLink
					item={{
						type: "navitem",
						name: userProfile.firstName,
						to: '/profile',
						icon: (<Avatar alt="Profile Picture" src={userProfile.avatar} sx={{width: '24px', height: '24px'}} />)
					}}
					navigate={navigate}
					handleMobileDrawerToggle={handleMobileDrawerToggle}
					location={location}
				/>
			}

			{NavChoices.map((item, index) => {
				switch(item.type) {
					case 'navitem':
						return <NavLink isMini={isCollapsed} item={item} key={index} navigate={navigate} handleMobileDrawerToggle={handleMobileDrawerToggle} location={location} />;
					case 'divider':
						return <Divider key={index} sx={{border: '1px dotted '+paletteColors.blue.light+'66', m:2}} />;
					case 'header':
						return null;
					default:
						return null;
				}
			})}
			{!isSmallScreen && userProfile && (
				<>
					<Spacer />
					<NavLink
						item={{
							type: "navitem",
							name: "Welcome "+userProfile.firstName,
							to: '/profile',
							icon: (<Avatar alt="Profile Picture" src={userProfile.avatar} sx={{width: '24px', height: '24px'}} />)
						}}
						navigate={navigate}
						handleMobileDrawerToggle={handleMobileDrawerToggle}
						location={location}
						isMini={isCollapsed}
					/>
				</>
			)}
		</List>
	);
	
	/**
	 * 
	 * Render Container
	 * 
	 */

	const drawerPaperStyle = {
		backgroundColor: paletteColors.blue.dark,
		color: paletteColors.cream,
		boxShadow: '5px 0 0px rgba(0,0,0,0.05)',
		boxSizing: 'border-box',
		width: drawerWidth
	};

	return (
		<Box
			component="nav"
			sx={{
				width: { sm: drawerWidth },
				flexShrink: { sm: 0 },
				height: '100vh',
				overflow: 'auto'
			}}
			aria-label="navigation">

			{/* Mobile Drawer */}

			<Drawer
				variant="temporary"
				open={mobileOpen}
				anchor='left'
				onClose={handleMobileDrawerToggle}
				ModalProps={{
					keepMounted: true,
				}}
				sx={{
					display: { xs: 'block', sm: 'none' },
					'& .MuiDrawer-paper': drawerPaperStyle,
				}}
			>
				<DrawerHeader />
				{NavContents}
			</Drawer>

			{/* Desktop Drawer */}

			<Drawer
				variant="permanent"
				sx={{
					display: { xs: 'none', sm: 'block' },
					width: drawerWidth,
					position: 'relative',
					'& .MuiDrawer-paper': {
						...drawerPaperStyle,
						overflow: 'visible' // For the collapse toggle handle
					}
				}}
			>
				<Button
					aria-label="Collapse Sidebar"
					sx={{
						position: 'absolute',
						display: 'block',
						right: '-2px',
						top: 0,
						padding: 0,
						minWidth: 'unset',
						zIndex: 999,
						borderRadius: 0,
						width: '4px',
						height: '100vh',
						backgroundColor: 'transparent',
						'&:hover': {
							backgroundColor: 'lightblue'
						},
						"&:focus": {
							backgroundColor: '#f0f6',
							position: 'absolute'
						},
						"&:focus::after": {
							display: 'none'
						}
					}}
					onClick={handleCollapseToggle}
				/>
				<DrawerHeader isMini={isCollapsed} />
				{NavContents}
			</Drawer>
		</Box>
	);
}

const Spacer = styled('div')(() => ({
	display: 'flex',
	flexGrow: 1
}));
