import React, { useState, useEffect } from 'react';
import { List } from '@mui/material';
import { RestaurantItem } from './RestaurantItem';
import { RestaurantForm } from './RestaurantForm';
import { PageContent } from '../../components/PageContent/PageContent';
import { useGetAllQuery } from '../../slices/restaurantsApi';
import { newRestaurant, Restaurant } from '../../models/RestaurantModel';
import { initialFilters, RestaurantFilters } from './RestaurantFilters'
import { EmptyList } from '../../components/EmptyList/EmptyList';
import { useDialog } from '../../components/DialogProvider/DialogContext';

export const Restaurants: React.FC = () => {

	/**
	 * 
	 * Initialize Data
	 * 
	 */

	const { data: unfilteredRestaurants = [], isLoading, refetch } = useGetAllQuery<{data: Restaurant[], isLoading: boolean, refetch: ()=>void}>('');

	/**
	 * 
	 * Filters
	 * 
	 */

	const [filters, setFilters] = useState(initialFilters);


	const getRestaurantsFiltered = () => {
		let x: Restaurant[] = unfilteredRestaurants;
		
		if(filters !== undefined) {
			// Filter: By Search Term
			if(filters.search !== '')
				x = x.filter(entity =>
					(entity.name.toLowerCase().indexOf(filters.search.toLowerCase()) != -1)
				)
			// Filter: By Category
			if(filters.category !== 'ANY')
				x = x.filter(entity => entity.category === filters.category);
			// Filter: Exclude Chains
			if(filters.excludeChains !== false)
				x = x.filter(entity => (entity.isChain === false || entity.isChain === null));
		}

		return x;
	};
	
	const restaurants: Restaurant[] = getRestaurantsFiltered();

	/**
	 * 
	 * Form Interface
	 * 
	 */

	const { openDialog } = useDialog();

	const handleAdd = () =>
		openDialog(RestaurantForm, { initialData: newRestaurant });

	const handleEdit = (item: Restaurant) =>
		openDialog(RestaurantForm, { initialData: item });

	useEffect(() => {
		refetch();
	}, [openDialog]);

	/**
	 * 
	 * Render Component
	 * 
	 */

	return (
		<PageContent
			title="Restaurants"
			onAdd={handleAdd}
			isLoading={isLoading}
			filters={
				<RestaurantFilters filters={filters} setFilters={setFilters} />
			}
		>


			{restaurants.length>0 && (
				<List>
					{(restaurants as Restaurant[]).map((item, index) => 
						<RestaurantItem
							data={item}
							primaryAction={handleEdit}
							key={index}
						/>
					)}
				</List>
			)}
			{restaurants.length===0 && (
				<EmptyList />
			)}
		</PageContent>
	);
}