import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Box, List } from '@mui/material';
import { KSearchPicklistItem } from './KSearchPicklistItem';
import { KSearch } from '../KSearch/KSearch';
import { Restaurant } from '../../models/RestaurantModel';
import { Recipe } from '../../models/RecipeModel';

type hasIdAndName = {
	id: string,
	name: string
};

type SearchResults = {
	restaurants: Restaurant[],
	recipes: Recipe[]
}

interface Props {
	selected?: (any & hasIdAndName);
	setSelected: any;
	actionNoResults?: React.ReactNode;
	endpoint: string;
}

export const KSearchPicklist: React.FC<Props> = ({ endpoint, selected, setSelected, actionNoResults }) => {

	/**
	 * 
	 * Search Filter
	 * 
	 */

	const [query, setQuery] = useState<string>('');
	const [searchResults, setSearchResults] = useState<SearchResults | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(()=>{
		const fetchResults = async () => {

			setIsLoading(true);

			const response = await axios.get(endpoint+'/search/food/?q='+encodeURIComponent(query))
				.then((res) => setSearchResults(res.data))
				.then(()=>setIsLoading(false));

			return response;
		};

		if(query) fetchResults();
		else setSearchResults({restaurants:[], recipes:[]});
	},[query])

	const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setQuery(e.target.value)
	};

	/**
	 * 
	 * Selection State
	 *
	 */

	const handleSelectionChange = (newSelection: any) => setSelected(newSelection);

	const getIsSelected = (indexId: string) => {
		if(selected)
			return (selected.id === indexId) ? true : false;
		else
			return false;
	};

	/**
	 * 
	 * Render
	 * 
	 */

	return (
		<Box sx={{
			display:'flex',
			flexDirection:'column',
			flexGrow:1,
			overflow:'auto',
			p: 1
		}}>
			<KSearch
				autoFocus
				variant='filled'
				sx={{
					mt: 0,
					mb: 0,
				}}
				value={query}
				onChange={handleFilterChange} />

			<List sx={{
				position: 'relative',
				flexGrow: 1,
				overflow: 'auto',
				padding: 0,
				margin: 0,
				border: '1px solid #ccc',
				borderTop: 0,
				borderRadius: '0 0 4px 4px',
				'& li': {
					margin: 0,
					padding: 0
				}
			}}>

				{/* Loading */}
				{/* Don't show a loader */}
				{/* {isLoading && query && <>Loading...</>} */}

				{/* Search Result Listwow */}
				{!isLoading && searchResults && (searchResults.recipes.length > 0) && searchResults.recipes.map((item: any, index: number) => (
					<KSearchPicklistItem
						data={item}
						selected={getIsSelected(item.id)}
						onSelect={handleSelectionChange}
						key={index} />
				))}
				{!isLoading && searchResults && (searchResults.restaurants.length > 0) && searchResults.restaurants.map((item: any, index: number) => (
					<KSearchPicklistItem
						data={item}
						selected={getIsSelected(item.id)}
						onSelect={handleSelectionChange}
						key={index} />
				))}

				{/* No Search Results */}
				{!isLoading && searchResults && (query && searchResults.recipes.length === 0 && searchResults.restaurants.length === 0) &&
					<Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection:'column', gap:2, alignItems: 'center', justifyContent: 'center'}}>
						<Box>Womp womp! No results. Try searching for another term.</Box>
						{actionNoResults}
					</Box>
				}
				{/* Initial State */}
				{!isLoading && searchResults && (!query && searchResults.recipes.length === 0) &&
					<Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection:'column', gap:2, alignItems: 'center', justifyContent: 'center'}}>
						<Box sx={{opacity: 0.3}}>Search for Recipes or Restaurants</Box>
					</Box>
				}
			</List>
		</Box>
	);
}