import React from 'react';
// import { styled } from '@mui/material/styles';
import { ListItem, ListItemText, Avatar, ListItemIcon, Box } from '@mui/material';
import { paletteColors } from '../../theme/theme';

export const DrawerHeader:React.FC<{isMini?: boolean}> = ({isMini=false}) => {
	return (
	<Box sx={{
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: 1,
		backgroundColor: paletteColors.blue.xxdark,
		color: paletteColors.cream,
		textTransform: 'uppercase',
		letterSpacing: '1px',
		minHeight: '64px',
	}}>
		<ListItem
			sx={{
				cursor: 'default',
				minHeight: 48,
				px: 2
			}}
		>
			
			<ListItemIcon sx={{ minWidth: 0, mr: 2.5 }}>
				<Avatar alt="Kenilworth Logo" sx={{width:32, height:32, borderRadius: 0}} src='/icon_kenilworth180.png' />
			</ListItemIcon>

			{!isMini && <ListItemText primary="Kenilworth" />}
		</ListItem>
	</Box>
	)
};