import React from 'react';
import { Box, Stack } from '@mui/material';
import { GuestBookItem } from './GuestBookItem';
import { GuestBookForm } from './GuestBookForm';
import { GuestWelcomeMat } from './GuestWelcomeMat';
import { PageContent } from '../../components/PageContent/PageContent';
import { useGetAllQuery } from '../../slices/guestBookApi';
import { GuestBookEntry } from '../../models/GuestBookModel';

/**
 * 
 * NEED TO FIX API – not returning same structure as the others ({itemCount: nn, items: []})
 * 
 */

export const GuestBook: React.FC = () => {

	/**
	 * 
	 * Initialize Data
	 * 
	 */

	const { data: entries = [] } = useGetAllQuery('');

	/**
	 * 
	 * Render Component
	 * 
	 */

	return (
		<PageContent plain>

			<Box sx={{
				display: 'flex',
				mt: 4,
				gap: 4,
				flexDirection: {
					xs: 'column',
					md: 'row'
				}
			}}>
				<Box>
					<GuestWelcomeMat />
				</Box>
				<Box>
					<GuestBookForm />
					<Stack>
						{(entries as GuestBookEntry[]).map((item, index) => (
							<GuestBookItem
								data={item}
								key={index}
							/>
						))}
					</Stack>
				</Box>
			</Box>
		</PageContent>
	);
}