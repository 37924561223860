import moment, { Moment } from "moment";

export const getFirstdayOfWeek = (year: number, week: number) => {
	
	let x = moment()
			.year(year)
			.week(week)
			.startOf('week')
			.add(1, 'day')	// startOf(week) assumes Sunday, but add 1 to start on Mondays
			.utcOffset('-00:00')
			.startOf('day');

	return x as Moment;
}