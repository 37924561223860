import { Box, Button, SxProps, Typography } from '@mui/material';
import React, { useState } from 'react';
import { paletteColors } from '../../theme/theme';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface Props {
    title?: string;
    children: React.ReactNode;

    collapsible?: boolean;
    initiallyCollapsed?: boolean;
    horizontal?: boolean;
    plain?: boolean;
    sx?: SxProps;
}

export const KFormSection: React.FC<Props> = ({children, title='', collapsible=true, initiallyCollapsed=true, horizontal=false, plain=false, sx={}}) => {

    const [isCollapsed, setIsCollapsed] = useState(initiallyCollapsed);

    const handleCollapse = () => collapsible ? setIsCollapsed(isCollapsed ? false : true) : undefined;

    if(plain) return (
        <Box sx={{
            ...sx,
            display: 'flex',
            flexDirection: horizontal ? 'row' : 'column',
            gap: horizontal ? 2 : 'inherit'
        }}>
            {children}
        </Box>
    );

    else return (
        <Box sx={{
            ...sx,
            bgcolor: paletteColors.blue.xxlight,
            borderRadius: '4px',
            mt: 1
        }}>
            <Button
                role="heading"
                disabled={collapsible ? false : true}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    textAlign: 'left',
                    textTransform: 'none',
                    gap: 2,
                    alignItems: 'center',
                    padding: '1rem 1rem',
                    transition: 'all 100ms ease-in',
                    cursor: 'pointer'
                }}
                onClick={handleCollapse}
            >
                {collapsible && (isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />)}
                <Typography sx={{flexGrow: 1}}>
                    {title || 'Section Needs a Name'}
                </Typography>
            </Button>
            
            {(!isCollapsed || !collapsible) && <Box sx={{ px:4, pb: 4, display:'flex', flexDirection: horizontal? 'row' : 'column', gap: horizontal ? 2 : 0}}>{children}</Box>}
            
        </Box>

    );
}