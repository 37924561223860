import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { paletteColors } from '../../theme/theme';

// Assuming your hash is passed to ExampleChart
// export const ExampleChart = () => {
//   const dataHash = {
//     "2023-10-14 to 2023-10-20": 12,
//     // ... add all other date ranges and values
//     "2023-08-05 to 2023-08-11": 11
//   };
  
//   // Convert hash to array format that D3 can understand
//   const data = Object.entries(dataHash).map(([key, value]) => {
//     return { dateRange: key, value };
//   });

//   return (<KChart type="bar" data={data} width={300} height={200} />);
// }

const chartColor = paletteColors.red.main;

export const KChart = ({ type, data, width, height, hideY=true }) => {
  if(!data) return <>Loading...</>;

  const svgRef = useRef();

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear svg content before drawing

    // Create scales
    const xScale = d3.scaleBand()
      .domain(data.map(d => d.dateRange))
      .range([0, width])
      .padding(0.6);

    const yScale = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.value)])
      .range([height, 0]);

    // Draw axes
    svg.append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale).tickFormat(d3.timeFormat("%b %d")))
      .selectAll("text")
      .attr("transform", "rotate(-65)")
      .style("text-anchor", "end");

    if (!hideY) {
      svg.append("g").call(d3.axisLeft(yScale));
    }

    // Draw bars for Bar Chart
    if (type === 'bar') {
      svg.selectAll(".bar")
        .data(data)
        .enter()
        .append("rect")
        .classed("bar", true)
        .attr("x", d => xScale(d.dateRange))
        .attr("y", d => yScale(d.value))
        .attr("width", xScale.bandwidth())
        .attr("height", d => height - yScale(d.value))
        .attr("fill", chartColor);
    }
    // Draw line for Line Chart
    else if (type === 'line') {
      const line = d3.line()
        .x(d => xScale(d.dateRange) + xScale.bandwidth() / 2)
        .y(d => yScale(d.value));

      svg.append("path")
        .data([data])
        .attr("d", line)
        .attr("fill", "none")
        .attr("stroke", chartColor)
        .attr("stroke-width", 4);
    }
  }, [data, width, height, type, hideY]);

  return (
    <svg aria-label="Chart" ref={svgRef} width={width} height={height}></svg>
  );
};