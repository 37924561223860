import React, {useEffect, useState} from 'react';

import { useAddMutation, useUpdateMutation, useDeleteMutation } from '../../slices/projectsApi';
import { deletionHandler, inputChangeHandler, submitHandler } from '../../utilities/handleForm';

import { Project } from '../../models/ProjectModel';
import { PriorityMapping } from '../../models/micro/Priority';
import { ProjectStatusMappings } from '../../models/micro/ProjectStatus';

import { getFormVerb } from '../../utilities/getFormVerb';
import { KForm } from '../../components/KForm/KForm';
import { KFormSection } from '../../components/KFormSection/KFormSection';
import { KTextField } from '../../components/KTextField/KTextField';
import { KSelect } from '../../components/KSelect/KSelect';
import { KNotes } from '../../components/KNotes/KNotes';

import { DialogKTitle } from '../../components/DialogKTitle/DialogKTitle';
import { DialogKContent } from '../../components/DialogKContent/DialogKContent';
import { DialogKActions } from '../../components/DialogKActions/DialogKActions';
import { SelectChangeEvent } from '@mui/material';

interface Props {
	initialData: Project;
	closeDialog: ()=>void;
}

export const ProjectForm: React.FC<Props> = ({ closeDialog, initialData }) => {

	/**
	 * 
	 * API Controller
	 * 
	 */

	const [addProject] = useAddMutation();
	const [updateProject] = useUpdateMutation();
	const [deleteProject] = useDeleteMutation();

	/**
	 * 
	 * Form Controls
	 * 
	 */

	const [formLocalData, setFormLocalData] = useState<Project>(initialData); 

	useEffect(() => {
		setFormLocalData(initialData);
	}, [initialData])

	const verb = getFormVerb(formLocalData);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string | string[]>) => inputChangeHandler(e, {
		data: formLocalData,
		setData: setFormLocalData
	});

	const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => submitHandler(e, {
		data: formLocalData,
		add: addProject,
		update: updateProject,
		close: handleClose
	})

	const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => deletionHandler(e, {
		data: formLocalData,
		delete: deleteProject,
		close: handleClose
	})

	const handleClose = () => closeDialog();

	/**
	 * 
	 * Render Component
	 * 
	 */

	return (
		<>
		<DialogKTitle>
			{verb+" Project"}
		</DialogKTitle>
		
		<DialogKContent>

			<KForm>

				<KTextField
					type="name"
					name="name"
					value={formLocalData.name}
					label="Project Name"
					onChange={handleChange} />

				{/* Priority & Status */}

				<KFormSection plain horizontal>
					<KSelect
						name="status"
						value={formLocalData.status}
						options={ProjectStatusMappings}
						label="Status"
						onChange={handleChange} />
					<KSelect
						name="priority"
						value={formLocalData.priority}
						options={PriorityMapping}
						label="Priority"
						onChange={handleChange} />
				</KFormSection>

				<KFormSection title='Costs' horizontal>
					<KTextField
						type="money"
						name="costEstimate"
						value={formLocalData.costEstimate}
						label="Cost Estimate"
						onChange={handleChange} />
					<KTextField
						type="money"
						name="actualCost"
						value={formLocalData.actualCost}
						label="Actual Cost"
						onChange={handleChange} />
				</KFormSection>

				<KFormSection title='Planning' collapsible={false} initiallyCollapsed={false}>
					<KTextField
						type="linkPinterest"
						name="linkPinterest"
						value={formLocalData.linkPinterest}
						label="Pinterest Board"
						onChange={handleChange} />
				</KFormSection>

				<KNotes
					value={formLocalData.notes}
					onChange={handleChange} />

			</KForm>

		</DialogKContent>

		<DialogKActions
			handleSubmit={handleSubmit}
			handleDelete={handleDelete}
			handleClose={handleClose}
		/>

		</>
	);
}