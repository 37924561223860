import React, { useState } from 'react';
import moment from 'moment';

import { useUpdateMutation, useAddMutation, useDeleteMutation } from '../../slices/mealsApi';

import {
	Card,
	CardContent,
	// FormLabel,
	// Chip
} from '@mui/material';

import { Meal } from '../../models/MealModel';
import { Recipe } from '../../models/RecipeModel';
import { Restaurant } from '../../models/RestaurantModel';

import { MealItem } from './MealItem';

import { KFormSection } from '../../components/KFormSection/KFormSection';
import { MealPicklist } from './MealPicklist';
import { KToggle } from '../../components/KToggle/KToggle';
import { KNotes } from '../../components/KNotes/KNotes';

import { DialogKTitle } from '../../components/DialogKTitle/DialogKTitle';
import { DialogKActions } from '../../components/DialogKActions/DialogKActions';
import { paletteColors } from '../../theme/theme';

interface Props {
	initialData: Meal;
	closeAction: () => void;
}

export const MealForm: React.FC<Props> = ({ initialData, closeAction }) => {
	const [addMeal, {isLoading: _isAdding}] = useAddMutation();
	const [updateMeal, {isLoading: _isUpdating}] = useUpdateMutation();
	const [deleteMeal, {isLoading: _isDeleting}] = useDeleteMutation();

	const [formLocalData, setFormLocalData] = useState<typeof initialData>(initialData);

	/**
	 * 
	 * Form Controls
	 * 
	 */

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setFormLocalData({
			...formLocalData,
			[e.target.name]: e.target.value
		});

		const handleToggleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setFormLocalData({
			...formLocalData,
			[e.target.name]: e.target.checked
		});

	const handleMealSelectionChange = (selectionData: Restaurant | Recipe | undefined, selectionType: 'RESTAURANT' | 'RECIPE' | undefined) => {
		try {
			setFormLocalData({
				...formLocalData,
				selectionId: selectionData?.id,
				selectionType: selectionType,
				selectionData: selectionData
			})
		} catch(err) {
			throw(err);
		}
	};

	const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();

		if (formLocalData.id === null || formLocalData.id === '') {
			await addMeal({...formLocalData, selectionData: undefined}).unwrap();
		} else {
			await updateMeal(formLocalData).unwrap();
		}

		closeAction();
	};

	const handleDelete = () => {
		if(formLocalData.id !== undefined) {
			deleteMeal(formLocalData).unwrap();
		}
		closeAction();
	};

	const handleCancel = () => closeAction();

	/*
	 * 
	 * There should be no deleting once meal objects are created.
	 * Instead, run cleanups on the server-side to remove empty objects.
	 * 
	 */

	return (
		<Card
			sx={{
				display: 'flex',
				flexFlow: 'column',
				height: '100vh',
				borderRadius: 0
			}}>
			<DialogKTitle sx={{display: 'flex', flexDirection: 'column', gap: 1, pb:4, bgcolor: paletteColors.blue.xdark, color: paletteColors.cream }}>
				Choose {formLocalData.mealType} for {moment(formLocalData.date).utcOffset('+00:00').startOf('day').format('dddd, MMM Do')}
				<MealItem
					date={formLocalData.date}
					mealType={formLocalData.mealType}
					meals={[formLocalData]}
					empty='static'
				/>
			</DialogKTitle>

			<CardContent sx={{
				display: 'flex',
				flex: 1,
				flexFlow: 'column',
				overflow: 'auto',
				minHeight: '400px',
				gap: 2,
				// m:1,
				// mb:2
			}}>


				{/* Picker form */}
				<MealPicklist
					mealSelectionData={formLocalData.selectionData}
					handleMealSelectionChange={handleMealSelectionChange} />

				<KFormSection horizontal plain>
					<KToggle
						label='Leftovers'
						name='isLeftovers'
						checked={formLocalData.isLeftovers}
						onChange={handleToggleChange} />

					<KToggle
						label='Placeholder'
						name='isPlaceholder'
						checked={formLocalData.isPlaceholder}
						onChange={handleToggleChange} />

					{/* <FormLabel sx={{display:'flex', gap:0.5}}>
						<Chip disabled color='primary' label='Eric' />
						<Chip disabled color='primary' label='Priya' />
						<Chip disabled color='primary' label='Shaan' />
					</FormLabel> */}
				</KFormSection>

				<KNotes
					sx={{
						marginTop: 0,
						marginBottom: 0
					}}
					value={formLocalData.notes || ''}
					onChange={handleChange} />

			</CardContent>

			<DialogKActions
				handleSubmit={handleSubmit}
				handleDelete={handleDelete}
				handleClose={handleCancel}
			/>
		</Card>
	)
}