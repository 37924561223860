import type { BaseModel } from "./macro/BaseModel";
import type { Link } from "./micro/Link";
import { UserGroupOwnership } from "./micro/UserGroupOwnership";

type RecipeDetails = {
	type: string,
	link?: Link
};

export type Recipe = BaseModel & UserGroupOwnership & RecipeDetails;

/**
 * 
 * NEW BLANK OBJECT
 * 
 */

export const newRecipe: Recipe = {
	id: '',
	type: 'recipe',
	name: '',
	link: '',
	userGroupOwnershipId: ''
};

// export const testRecipes: Recipe[] = [
// 	{
// 		"id": "6539b1a8d1ea6f44149eeb1d",
// 		"name": "Snacks",
// 		"notes": null,
// 		"type": "recipe",
// 		"userGroupOwnershipId": "",
// 		"link": ""
// 	},
// 	{
// 		"id": "6539b12ad1ea6f44149eeb1b",
// 		"name": "Mummy's Cooking",
// 		"notes": "",
// 		"type": "recipe",
// 		"userGroupOwnershipId": "",
// 		"link": ""
// 	}
// ]