import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import reportWebVitals from './_reportWebVitals';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import { store } from './app/store';

import { KenilworthApp } from './app/KenilworthApp';

const root = createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<Auth0Provider
			domain="ericville.us.auth0.com"
			clientId="IcV6hziZHW5dDJ7TQAKKl8LCZaKljboo"
			authorizationParams={{
				redirect_uri: window.location.origin
			}}
			cacheLocation="localstorage"
			useRefreshTokens={true}
		>
			<Provider store={store}>
				<KenilworthApp />
			</Provider>
		</Auth0Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
