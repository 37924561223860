import React from 'react';
import { Avatar, ListItemAvatar } from '@mui/material';

export const KListItemThumbnail: React.FC<{thumbnail:any, color:string}> = ({thumbnail, color}) => {
	return (
		<ListItemAvatar>
			<Avatar sx={{
				width: '48px',
				height: '48px',
				color: 'white',
				background: `linear-gradient(45deg, ${color}77, ${color})`,
				border: '1px solid white',
				'& > *': {
					width: '1.5rem',
					height: '1.5rem',
				}
			}}>
				{thumbnail}
			</Avatar>
		</ListItemAvatar>
	)
}