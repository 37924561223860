import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import { AppStatusContent } from '../../components/AppStatusContent/AppStatusContent';

export const Login: React.FC = () => {
	const {
		loginWithRedirect,
	} = useAuth0();

	return (
		<AppStatusContent>
			<Button onClick={() => loginWithRedirect()} variant='contained'>Login</Button>
		</AppStatusContent>
	)
}