import React from 'react';

import {
	Card,
	Typography
} from '@mui/material';
import { KListSection } from '../../components/KListSection/KListSection';

export const GuestWelcomeMat: React.FC = () => {
	return (
		<Card
			elevation={0}
			sx={{
				p: 5
			}}>
			<Typography
				variant="h2"
				sx={{
					fontSize: '36px',
					mb:'2rem'
				}}>
				Welcome!
			</Typography>
			<p>Welcome to this humble abode, where the laughter is plentiful and the craziness is free-flowing. Please make yourselves comfortable and get ready to be entertained, as we're about to embark on a wild ride of fun and frivolity!</p>
			<p>If you're looking for a peaceful, serene getaway, you've come to the wrong place! But if you're ready to let loose, kick off your shoes and party like it's 1999 (or any other year, really), then you've hit the jackpot, my friends!</p>
			<p>So sit back, relax, and let the good times roll! And remember, in this house, we don't do boring!</p>

			<KListSection title='Wifi' showItemCount={false}>
				<Typography variant="body1">
					<strong>Network:</strong> <code>shaan-guest</code><br />
					<strong>Password:</strong> <code>hakunamat@t@</code>
				</Typography>
			</KListSection>

			<KListSection title='Restaurants' showItemCount={false}>
				Ask Priya
			</KListSection>

			<KListSection title='Things To Do' showItemCount={false}>
				<ul>
					<li>Churchill Park</li>
					<li>Glen Ellyn Downtown</li>
					<li>Lake Ellyn</li>
					<li>Bird Sanctuary</li>
					<li>Prairie Path</li>
					<li>Library</li>
					<li>Wheaton Downtown</li>
				</ul>
			</KListSection>
		</Card>
	);
}