import React, { useState } from 'react';

import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { ThemeProvider } from '@mui/material/styles';
	import { CssBaseline } from '@mui/material';
	import { theme } from '../theme/theme';

import { Box } from '@mui/material';
import { Navigation } from '../components/Navigation/Navigation';
import { Loader } from '../components/Loader/Loader';

import { Home } from '../features/home/Home';
import { GuestBook } from '../features/guestBook/GuestBook';
import { Providers } from '../features/providers/Providers';
import { MealPlanner } from '../features/mealplanner/MealPlanner';
import { Recipes } from '../features/recipes/Recipes';
import { Restaurants } from '../features/restaurants/Restaurants';
import { Projects } from '../features/projects/Projects';
import { Maintenance } from '../features/maintenance/Maintenance';
import { Manuals } from '../features/manuals/Manuals';
import { Swatches } from '../features/swatches/Swatches';
import { DrinkMenu } from '../features/drinkmenu/DrinkMenu';
import { HouseRules } from '../features/houserules/HouseRules';
import { Subscriptions } from '../features/subscriptions/Subscriptions';
import { Login } from '../features/login/login';
import { Error404 } from '../features/errorPage/Error404';
import { UserProfile } from '../features/profile/Profile';

import { LaunchNavigationContext } from '../components/Navigation/LaunchNavigationContext';
import { DialogProvider } from '../components/DialogProvider/DialogProvider';
import { KProfileProvider } from './KProfileProvider';

export const KenilworthApp: React.FC = () => {

	const [mobileOpen, setMobileOpen] = useState<boolean>(false);
	
	// Splash Guards

	const { isLoading: userLoadingAuth0, isAuthenticated } = useAuth0();
	
	if(userLoadingAuth0)
		return <Loader context='app'/>;
	
	if(!isAuthenticated)
		return (<Login />);

	// Main App

	else return (
		<KProfileProvider>
			<ThemeProvider theme={theme}>
				<LaunchNavigationContext.Provider value={{mobileOpen, setMobileOpen}}>
					<BrowserRouter>
						<CssBaseline />
						<Box sx={{display:'flex', overflow:'auto'}}>
							<Navigation />
							{/* {isTester &&  */}
							<DialogProvider>
								<Routes>
									<Route path="/" element={<Home />} />
									<Route path="/guestbook" element={<GuestBook />} />
									<Route path="/houserules" element={<HouseRules />} />

									<Route path="/food/meals" element={<MealPlanner />} />
									<Route path="/food/recipes" element={<Recipes />} />
									<Route path="/food/restaurants" element={<Restaurants />} />
									<Route path="/food/drinks" element={<DrinkMenu />} />

									<Route path="/subscriptions" element={<Subscriptions />} />
									<Route path="/upkeep/projects" element={<Projects />} />
									<Route path="/upkeep/maintenance" element={<Maintenance />} />
									<Route path="/upkeep/manuals" element={<Manuals />} />
									<Route path="/upkeep/swatches" element={<Swatches />} />
									<Route path="/help" element={<Providers />} />

									<Route path="/profile" element={<UserProfile />} />

									<Route path="*" element={<Error404 />} />
								</Routes>
							</DialogProvider>
							{/* } */}
							{/* {!isTester && */}
								{/* <ErrorRestricted /> */}
							{/* } */}
						</Box>
					</BrowserRouter>
				</LaunchNavigationContext.Provider>
			</ThemeProvider>
		</KProfileProvider>
	);
}