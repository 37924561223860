import React from 'react';
import { List, ListItem, Typography } from '@mui/material';
import { PageContent } from '../../components/PageContent/PageContent';
import { paletteColors } from '../../theme/theme';

const ruleStyle = {
	padding: '3rem 5rem',
	gap: '3rem',
	background: paletteColors.green.main+'33',
	// color: paletteColors.cream,
	borderRadius: '20px',
	marginBottom: '2rem',
	cursor: 'default',
	'& > h3': {}
}

const rules: string[] = [
	'Keep Calm',
	'Don\'t Lie',
	'Leave Egos At The Door',
	'Do No Harm'
];

export const HouseRules: React.FC = () => {
	return (
		<PageContent title='House Rules'>
			<List>
				{rules.map((rule, index) => (
					<ListItem key={index} sx={ruleStyle}>
						<Typography variant='h2'>Rule {index+1}</Typography>
						<Typography variant='h3'>{rule}</Typography>
					</ListItem>
				))}
			</List>
		</PageContent>
	);
}