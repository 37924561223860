import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

export const KForm:React.FC<Props> = ({children}) => {
    return (
        <Box sx={{
            display:'flex',
            flexDirection: 'column',
            gap: 1,
            // minWidth: '400px'
        }}>
            {children}
        </Box>
    )
}