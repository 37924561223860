import React, {useContext} from 'react';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { LaunchNavigationContext } from './LaunchNavigationContext';

export const ToggleDrawerButton: React.FC = () => {

	const { setMobileOpen } = useContext(LaunchNavigationContext);

	return (
		<IconButton
			aria-label="open drawer"
			edge="start"
			onClick={()=>setMobileOpen(true)}
			sx={{
				display: {
					sm: 'none'
				}
			}}
		>
			<MenuIcon />
		</IconButton>
	)
};