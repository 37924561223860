import { User as Auth0UserModel } from '@auth0/auth0-react';
import { AppDispatch } from '../app/store';
import { thunkRequestJWT } from '../slices/authSlice';
import { UserModel } from '../models/UserModel';

// import { useAddHomeMutation, useUpdateHomeProfileMutation } from '../slices/homeSlice';

export const unifyIndividual = (
    dispatch: AppDispatch,
    auth0profile: Auth0UserModel,
    userProfile: UserModel,
    updateUserProfile: (x: UserModel)=>void,
    addUserProfile: (x: UserModel)=>void
) => {
    

    
    // const updateHomeProfile = useUpdateHomeProfileMutation();
    // const addHomeProfile = useAddHomeMutation();


    if(auth0profile && userProfile) {

        let updatedUserProfile: UserModel = {...userProfile};

        // If the db has fields that are empty,
        // and which Auth0 has, update them.
            
        // Set idAuth0
        if(!userProfile.idAuth0 && auth0profile.sub)
            updatedUserProfile.idAuth0 = auth0profile.sub;
        else if(!userProfile.idAuth0 && auth0profile.user_id)
            updatedUserProfile.idAuth0 = auth0profile.user_id;

        // Set firstName
        if(!userProfile.firstName && auth0profile.given_name)
            updatedUserProfile.firstName = auth0profile.given_name;

        // Set lastName
        if(!userProfile.lastName && auth0profile.family_name)
            updatedUserProfile.lastName = auth0profile.family_name;

        // Set nickname
        if(!userProfile.nickname && auth0profile.nickname)
            updatedUserProfile.nickname = auth0profile.nickname;

        // Set email
        if(!userProfile.email && auth0profile.email)
            updatedUserProfile.email = auth0profile.email;

        // Set avatar
        if(!userProfile.avatar && auth0profile.picture)
            updatedUserProfile.avatar = auth0profile.picture;

        // Set locale
        if(!userProfile.locale && auth0profile.locale)
            updatedUserProfile.locale = auth0profile.locale;

        // Dispatch the updates
        updateUserProfile(updatedUserProfile);
    }
    else if(!userProfile || !userProfile.id) {
        
        // Create a blank UserModel
        let newUserProfile: UserModel = {
            id: '',
            idAuth0: '',
            firstName: '',
            lastName: ''
        }

        // Fill it with Auth0 values
        if(auth0profile.sub)
            newUserProfile.idAuth0 = auth0profile.sub;
        else if(auth0profile.user_id)
            newUserProfile.idAuth0 = auth0profile.user_id;

        // Set firstName
        if(auth0profile.given_name)
            newUserProfile.firstName = auth0profile.given_name;

        // Set lastName
        if(auth0profile.family_name)
            newUserProfile.lastName = auth0profile.family_name;

        // Set nickname
        if(auth0profile.nickname)
            newUserProfile.nickname = auth0profile.nickname;

        // Set email
        if(auth0profile.email)
            newUserProfile.email = auth0profile.email;

        // Set avatar
        if(auth0profile.picture)
            newUserProfile.avatar = auth0profile.picture;

        // Set locale
        if(auth0profile.locale)
            newUserProfile.locale = auth0profile.locale;

        // Dispatch the new user creation
        addUserProfile(newUserProfile);
    }

    // Request a new JWT

    if(userProfile) dispatch(thunkRequestJWT(userProfile.id || ''));
}