import React from 'react';
import { Paper } from '@mui/material';

interface Props {
	hex: string;
	formula: string;
}

export const Droplet: React.FC<Props> = ({hex, formula}) => (
	<Paper
		sx={{
			height: '100px',
			width: '100%',
			padding: '20px',
			color: '#333',
			borderRadius: '10px',
			backgroundColor: hex||'#eee',
		}}
		elevation={0}
	>
		<code>{formula}</code>
	</Paper>
);