import axios from 'axios';

const request = process.env.REACT_APP_ERICVILLE_API_BASEURL+'/auth/'

export const requestJWT = async (userId: string) => {
	try {
		
		const response = await axios.post(request, { "userId": userId, "email": "n/a" });
		return response.data;

	} catch (error) {

		console.error('Error requesting JWT', error);
		throw error;

	}
};