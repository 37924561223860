import { KeyValuePair } from "./KeyValuePair";

export type RestaurantCategory = 'AMERICAN' | 'S_ASIAN' | 'SE_ASIAN' | 'E_ASIAN' | 'LATIN' | 'MIDEASTERN' | 'AFRICAN' | 'CAJUN' | 'EUROPEAN';

export const RestaurantCategoryMappings: KeyValuePair[] = [
	{key:"AMERICAN", value:"American"},
	{key:"S_ASIAN", value:"South Asian"},
	{key:"SE_ASIAN", value:"Southeast Asian"},
	{key:"E_ASIAN", value:"East Asian"},
	{key:"LATIN", value:"Latin"},
	{key:"MIDEASTERN", value:"Middle Eastern"},
	{key:"AFRICAN", value:"African"},
	{key:"CAJUN", value:"Cajun"},
	{key:"EUROPEAN", value:"European"}
];