import React from 'react';
import { isPresent } from '../../utilities/validation';
import { KListItem } from '../../components/KListItem/KListItem';
import { Provider } from '../../models/ProviderModel';
import { KServiceIcon } from '../../components/KServiceIcon/KServiceIcon';
import { IconProvider } from '../../components/FeatureIcons/FeatureIcons';

interface Props {
	data: Provider;
	primaryAction: (data: Provider) => void
}

export const ProviderItem: React.FC<Props> = ({ data, primaryAction }) => {

	if(!data) return (<>Error</>);

	const handleEdit = () => primaryAction(data);

	const secondary = () => {
		let tPhone = isPresent(data.phone) ? data.phone : '';
		let tNotes = data.notes;

		return [
			tPhone,
			tNotes,
		].join(' – ');
	}

	return (
		<KListItem
			primary={data.name}
			iconSymbol={IconProvider.icon}
			iconColor={IconProvider.color}
			secondary={secondary()}
			primaryAction={handleEdit}
			actionItems={[
				<KServiceIcon service="google" url={data.linkGoogle as string} />,
				<KServiceIcon service="yelp" url={data.linkYelp as string} />,
				<KServiceIcon service="website" url={data.linkWeb as string} />
			]}
		/>
	)
}