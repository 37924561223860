import { SelectChangeEvent } from "@mui/material";
import React from "react";

/**
 * 
 * Input Update
 * 
 */

export const inputChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string | string[]>,
    payload: {
        setData: (data: any) => void,
        data: any
    }
) => {
    let targetValue;

    if ('type' in e.target && e.target.type === "checkbox") {
        targetValue = e.target.checked;
    } else {
        targetValue = e.target.value;
    }

    payload.setData({
        ...payload.data,
        [e.target.name]: targetValue
    });
};

/**
 * 
 * Add/Update Form Submission
 * 
 */

export const submitHandler = async (
	_e: React.MouseEvent<HTMLButtonElement>,
	payload: {
		data: any,
		add: (data:any)=>any,
		update: (data:any)=>any,
		close: ()=>any
	}
) => {
	try {
		if (payload.data.id === null || payload.data.id === '') {
			await payload.add(payload.data).unwrap();
		}
		else {
			await payload.update(payload.data).unwrap();
		}
		payload.close();
	}
	catch (err) {
		console.error(err);
	}
	finally {
		payload.close();
	}
};

/**
 * 
 * Deletion
 * 
 */

export const deletionHandler = (
	_e: React.MouseEvent<HTMLButtonElement>, 
	payload: {
		data: any,
		delete: (data:any)=>any,
		close: ()=>any
	}
) => {
	payload.delete(payload.data).unwrap();
	payload.close();
};