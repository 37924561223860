import React, { useEffect } from 'react';
import { List } from '@mui/material';
import { ProviderItem } from './ProviderItem';
import { ProviderForm } from './ProviderForm';
import { PageContent } from '../../components/PageContent/PageContent';
import { useGetAllQuery } from '../../slices/providersApi';
import { newProvider, Provider } from '../../models/ProviderModel';
import { useDialog } from '../../components/DialogProvider/DialogContext';
import { EmptyList } from '../../components/EmptyList/EmptyList';

export const Providers: React.FC = () => {

	/**
	 * 
	 * Initialize Data
	 * 
	 */

	const { data: providers = [], isLoading, refetch } = useGetAllQuery<{ data: Provider[], isLoading: boolean, refetch: ()=>void }>('');

	/**
	 * 
	 * Form Interface
	 * 
	 */

	const { openDialog } = useDialog();

	const handleAdd = () => 
		openDialog( ProviderForm, { initialData: newProvider } );

	const handleEdit = (data: Provider) =>
		openDialog( ProviderForm, { initialData: data } )

	useEffect(() => {
		refetch();
	}, [openDialog]);

	/**
	 * 
	 * Render Component
	 * 
	 */

	return (
		<PageContent
			title="Address Book"
			onAdd={handleAdd}
			isLoading={isLoading}
		>
			{providers.length > 0 && (
				<List>
					{(providers as Provider[]).map((item, index) => (
						<ProviderItem
							data={item}
							primaryAction={handleEdit}
							key={index}
						/>
					))}
				</List>
			)}
			{providers.length === 0 && (
				<EmptyList />
			)}
		</PageContent>
	);
}