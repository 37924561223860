import React from 'react';

import KitchenIcon from '@mui/icons-material/Kitchen';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import ContactsIcon from '@mui/icons-material/Contacts';
import BuildIcon from '@mui/icons-material/Build';

type KFeatureIcon = {
    icon: React.ReactNode,
    color: string
}

export const IconRecipe: KFeatureIcon = {
    icon: <KitchenIcon />,
    color: '#da6900'
}

export const IconRestaurant: KFeatureIcon = {
    icon: <LocalDiningIcon />,
    color: '#da3700'
}

export const IconProvider: KFeatureIcon = {
    icon: <ContactsIcon />,
    color: '#a741e6'
}

export const IconProject: KFeatureIcon = {
    icon: <BuildIcon />,
    color: '#644040'
}