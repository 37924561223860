import React from 'react';

import { Card, CardContent, CardHeader } from '@mui/material';
import { Droplet } from './DropletItem';
import { Swatch } from '../../models/SwatchModel';

interface Props { 
	data: Swatch & any; // Fix later, added any for the new fields
}

export const SwatchItem: React.FC<Props> = ({data}) => (
	<Card elevation={0} sx={{bgcolor:'#f6f6f6'}}>
		<CardHeader
			title={data.name}
			subheader={(data.color.name || "Name N/A") + " (" + (data.color.number || "Number N/A") + ")"}
		/>
		<CardContent>
			{/* <div><Avatar sx={{backgroundColor: data.previewHex, width:100, height:100}}>&nbsp;</Avatar></div> */}
			<Droplet hex={data.previewHex} formula={data.color.formula} />
			<p>Most recently mixed at {data.store} on {data.dateMixed} using base mix {data.baseMix}</p>
		</CardContent>
	</Card>
);