import moment, { Moment } from "moment";

export const isToday = (date: string | Moment) => {

    const myTimeZone = '-05:00'; // TODO: Move this to settings
    const formattedDate = moment(date).utcOffset('+00:00').startOf('day')
    const formattedToday = moment().utcOffset(myTimeZone).startOf('day')

    return formattedDate.isSame(formattedToday, 'day');
    
}