import React from 'react';
import { KListItem } from '../../components/KListItem/KListItem';
import { Restaurant } from '../../models/RestaurantModel';
import { KListItemType } from '../../components/KListItem/KListItemType';
import { KServiceIcon } from '../../components/KServiceIcon/KServiceIcon';
import { IconRestaurant } from '../../components/FeatureIcons/FeatureIcons';

interface Props {
	data: Restaurant;
	primaryAction?: (data: Restaurant) => void;
	type?: KListItemType;
}

export const RestaurantItem: React.FC<Props> = ({ data, primaryAction, type="list" }) => {

	if(!data) return (<>Error</>);

	const handleEdit = () => primaryAction ? primaryAction(data) : (null);

	const secondary = () => {
		return data.notes || '';
	}	

	if(type==='card') {
		return (
			<KListItem
				type="card"
				iconSymbol={IconRestaurant.icon}
				iconColor={IconRestaurant.color}
				primary={data.name}
				secondary={secondary()}
				primaryAction={handleEdit}
			/>
		);	
	}

	return (
		<KListItem
			iconSymbol={IconRestaurant.icon}
			iconColor={IconRestaurant.color}
			primary={data.name}
			secondary={secondary()}
			primaryAction={handleEdit}
			actionItems={[
				<KServiceIcon service="ubereats" url={data.linkUberEats as string} />,
				<KServiceIcon service="doordash" url={data.linkDoorDash as string} />,
				<KServiceIcon service="yelp" url={data.linkYelp as string} />,
				<KServiceIcon service="grubhub" url={data.linkGrubHub as string} />,
				<KServiceIcon service="website" url={data.linkWeb as string} />
			]}
		/>
	);
}