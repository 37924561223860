import { useAuth0 } from '@auth0/auth0-react';
import { UserModel } from '../models/UserModel';

export type kAuthType = {
	isAuthenticated: boolean;
	user: UserModel | undefined;
	isTester: boolean;
	isAdmin: boolean;
}

export const useKAuth = () => {

	const {
		isAuthenticated,
		user
	} = useAuth0();

	let isTester: boolean = false;
	if(isAuthenticated) {

		// Change to user IDs eventually, then to Auth0 roles and permissions
		switch(user?.sub) {
			case "google-oauth2|103090821191508782167": // Eric
			case "facebook|10201312085581342":	// Priya
				isTester = true;
				break;
			default:
				isTester = false;
		}
	}

	// Return Values
	return {
		isAuthenticated,
		user,
		isTester
	} as kAuthType;
}