import React, { useEffect } from 'react';
import { useGetAllQuery } from '../../slices/projectsApi';
import { PageContent } from '../../components/PageContent/PageContent';
import { ProjectItem } from './ProjectItem';
import { ProjectForm } from './ProjectForm';
import { newProject, Project } from '../../models/ProjectModel';
import { List } from '@mui/material';
import { KListSection } from '../../components/KListSection/KListSection';
import { useDialog } from '../../components/DialogProvider/DialogContext';

export const Projects: React.FC = () => {

	/**
	 * 
	 * Initialize Data
	 * 
	 */

	const { data: projects = [], isLoading, refetch } = useGetAllQuery<{ data:Project[], isLoading: boolean, refetch: ()=>void }>('');

	const completeProjects = (projects as Project[]).filter((item: Project) => item.status==='COMPLETE');
	const notStartedProjects = (projects as Project[]).filter((item: Project) => item.status==='NOT_STARTED');
	const inProgressProjects = (projects as Project[]).filter((item: Project) => item.status==='IN_PROGRESS');

	/**
	 * 
	 * Form Interface
	 * 
	 */

	const { openDialog } = useDialog();

	const handleAdd = () => 
		openDialog(ProjectForm, { initialData: newProject });

	const handleEdit = (data: Project) =>
		openDialog(ProjectForm, { initialData: data });

	useEffect(() => {
		refetch();
	}, [openDialog]);

	/**
	 * 
	 * Render Component
	 * 
	 */

	return (
		<PageContent title="Projects" onAdd={handleAdd} isLoading={isLoading}>
			<List>

				<KListSection title="In Progress" collapsible initiallyCollapsed={false}>
					{inProgressProjects.map((item, index) => 
						<ProjectItem data={item} primaryAction={handleEdit} key={index} />
					)}
				</KListSection>

				<KListSection title="Not Started" collapsible initiallyCollapsed>
					{notStartedProjects.map((item, index) => 
						<ProjectItem data={item} primaryAction={handleEdit} key={index} />
					)}
				</KListSection>

				<KListSection title="Complete" collapsible initiallyCollapsed>
					{completeProjects.map((item, index) => 
						<ProjectItem data={item} primaryAction={handleEdit} key={index} />
					)}
				</KListSection>

			</List>
		</PageContent>
	);
}