import React from 'react';
import { ToggleDrawerButton } from '../Navigation/DrawerToggleButton';
import { Box, Typography } from '@mui/material';
import { paletteColors } from '../../theme/theme';

interface PageHeaderProps {
    title?: string;
    filters?: React.ReactNode;
    fullscreen?: boolean; // lol why
};

export const PageHeader: React.FC<PageHeaderProps> = ({ title, filters, fullscreen }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            background: {
                xs: paletteColors.blue.xdark,
                sm: paletteColors.blue.xxlight,
                md: paletteColors.blue.xxlight
            },
            color: {
                xs: paletteColors.cream,
                sm: 'unset'
            },
            '& > *': {
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 3,
                paddingRight: 3
            },
            '& > *:first-of-type': {
                display: {
                    xs: 'flex',
                    sm: fullscreen ? 'none' : 'flex'
                },
                paddingTop: {
                    xs: '0.5rem',
                    sm: '2rem'
                },
                paddingBottom: {
                    xs: '0.5rem',
                    sm: '2rem'
                },
                'h2': {
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: {
                        xs: '1.25rem',
                        sm: '2rem'
                    }
                },
                'button': {
                    display: {
                        xs: 'flex',
                        sm: 'none'
                    },
                    marginRight: 2,
                    alignItems: 'center',
                    color: paletteColors.cream
                }
            },
            '& > *:last-child:not(:first-of-type)': {
                display: {
                    xs: 'none',
                    sm: 'flex',
                    md: 'flex'
                },
                color: paletteColors.blue.xxdark,
                paddingTop: {
                    xs: '1rem',
                    sm: '0rem'
                },
                paddingBottom: {
                    xs: '1rem',
                    sm: '2rem'
                }
            }
        }}>
            <Box>
                <ToggleDrawerButton />
                <Typography
                    variant="h2"
                    sx={{
                        m: 0,
                        mr: 4, // To make up for the hamburger menu
                        flexGrow: '1',
                        fontSize: {
                            xs: '1.75rem',
                            sm: '2.125rem',
                            md: '2.5rem',
                        },
                        justifyContent: {
                            xs: 'center',
                            sm: 'left',
                            md: 'left'
                        }
                    }}
                >
                    {title}
                </Typography>
            </Box>

            {filters && filters}
        </Box>
    );
}