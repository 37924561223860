import React, {useEffect, useState} from 'react';

import { useAddMutation, useUpdateMutation, useDeleteMutation } from '../../slices/restaurantsApi';
import { deletionHandler, inputChangeHandler, submitHandler } from '../../utilities/handleForm';

import { Restaurant } from '../../models/RestaurantModel';
import { RestaurantCategoryMappings } from '../../models/micro/RestaurantCategory';

import { Box, SelectChangeEvent } from '@mui/material';

import { getFormVerb } from '../../utilities/getFormVerb';
import { KForm } from '../../components/KForm/KForm';
import { KFormSection } from '../../components/KFormSection/KFormSection';
import { KTextField } from '../../components/KTextField/KTextField';
import { KLinkField } from '../../components/KLinkField/KLinkField';
import { KToggle } from '../../components/KToggle/KToggle';
import { KSelect } from '../../components/KSelect/KSelect';
import { KNotes } from '../../components/KNotes/KNotes';

import { DialogKTitle } from '../../components/DialogKTitle/DialogKTitle';
import { DialogKContent } from '../../components/DialogKContent/DialogKContent';
import { DialogKActions } from '../../components/DialogKActions/DialogKActions';

interface Props {
	closeDialog: ()=>void;
	initialData: Restaurant;
	refetch?: ()=>void;
}

export const RestaurantForm: React.FC<Props> = ({ closeDialog, initialData, refetch }) => {

	/**
	 * 
	 * API Controller
	 * 
	 */

	const [addRestaurant] = useAddMutation();
	const [updateRestaurant] = useUpdateMutation();
	const [deleteRestaurant] = useDeleteMutation();

	/**
	 * 
	 * Form Controls
	 * 
	 */

	const [formLocalData, setFormLocalData] = useState<Restaurant>(initialData); 

	useEffect(() => {
		setFormLocalData(initialData);
	}, [initialData])

	const verb = getFormVerb(formLocalData);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string | string[]>) => inputChangeHandler(e, {
		data: formLocalData,
		setData: setFormLocalData
	});

	const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => submitHandler(e, {
		data: formLocalData,
		add: addRestaurant,
		update: updateRestaurant,
		close: handleClose
	});

	const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => deletionHandler(e, {
		data: formLocalData,
		delete: deleteRestaurant,
		close: handleClose
	});

	const handleClose = () => {
		if(refetch) refetch();
		closeDialog();
	}

	/**
	 * 
	 * Render Component
	 * 
	 */

	return (
		<>
		<DialogKTitle>
			{verb+" Restaurant"}
		</DialogKTitle>
		
		<DialogKContent>

			<KForm>

				<KTextField
					type="name"
					name="name"
					value={formLocalData.name || ''}
					label="Restaurant Name"
					onChange={handleChange}
					autoFocus={true}
					sx={{
						flexGrow: '1',
						width: 'auto'
					}} />
				<Box sx={{display:'flex', flexDirection: 'row'}}>
					<KSelect
						name="category"
						label="Category"
						value={formLocalData.category || ''}
						options={RestaurantCategoryMappings}
						onChange={handleChange} />					
					<KToggle
						label='Is it a chain?'
						name='isChain'
						checked={formLocalData.isChain}
						onChange={handleChange} />
				</Box>

				<KFormSection title='Delivery Services' collapsible>
					<KLinkField
						service='yelp'
						label='Yelp'
						name='linkYelp'
						value={formLocalData.linkYelp || ''}
						onChange={handleChange} />
					<KLinkField
						service='ubereats'
						label='UberEats'
						name='linkUberEats'
						value={formLocalData.linkUberEats || ''}
						onChange={handleChange} />
					<KLinkField
						service='doordash'
						label='DoorDash'
						name='linkDoorDash'
						value={formLocalData.linkDoorDash || ''}
						onChange={handleChange} />
					<KLinkField
						service='grubhub'
						label='GrubHub'
						name='linkGrubHub'
						value={formLocalData.linkGrubHub || ''}
						onChange={handleChange} />
					<KLinkField
						service='website'
						label='Website'
						name='linkWeb'
						value={formLocalData.linkWeb || ''}
						onChange={handleChange} />
				</KFormSection>

				<KFormSection title='Business Contact' collapsible>
					<KFormSection plain horizontal>
						<KTextField
							label='Hours'
							value=''
							onChange={handleChange} />
						<KTextField
							label='Phone'
							value=''
							onChange={handleChange} />
					</KFormSection>
					<KFormSection plain horizontal>
						<KTextField
							label='Street Address'
							value=''
							onChange={handleChange} />
						<KTextField
							label='City'
							value=''
							onChange={handleChange} />
						<KTextField
							label='State'
							value=''
							onChange={handleChange} />
					</KFormSection>
				</KFormSection>

				<KNotes
					value={formLocalData.notes || ''}
					onChange={handleChange} />

			</KForm>

		</DialogKContent>

		<DialogKActions
			handleSubmit={handleSubmit}
			handleDelete={handleDelete}
			handleClose={handleClose}
		/>

		</>
	);
}